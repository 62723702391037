<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="() => (isModal = false)" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus class ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <Modal v-if="isModal2" @close="() => (isModal2 = false)" width="40%">
      <h2 class="text-center">Edit Kategori Class</h2>
      <div class="content-form mt-10">
        <p>Kategori Class</p>
        <v-text-field
          outlined
          v-model="form.name"
          class="form-input"
          placeholder="Masukkan Nama Kategori Class"
          dense
          clearable
          :rules="[!!form.name || 'Wajib diisi!']"
        ></v-text-field>
      </div>
      <div class="content-form">
        <p>Deskripsi</p>
        <v-textarea
          placeholder="Tulis deskripsi ...."
          outlined
          rows="3"
          v-model="form.description"
          :rules="[!!form.description || 'Wajib diisi!']"
          clearable
          class="form-input"
        ></v-textarea>
      </div>
      <div style="display: flex; justify-content: center">
        <Button
          :disabled="!form.description || !form.name"
          name="SIMPAN PERUBAHAN"
          width="230px"
          @click="updateCategory"
        />
      </div>
    </Modal>
    <!-- <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <p class="modal-title">Konfirmasi</p>
      <p>Apakah kamu yakin ingin menghapus class ini?</p>
      <div style="display: flex; gap: 20px; margin-top: 30px">
        <Button name="YA, HAPUS DATA" width="100%" @click="onDelete" />
        <Button
          name="TIDAK, KEMBALI"
          width="100%"
          outline
          @click="() => (isModal = false)"
        />
      </div>
    </Modal> -->
    <div class="menus-top">
      <span class="blue-text" @click="$router.push('/class-category')"
        >Kategori Class</span
      >
      <Arrow
        :width="20"
        :height="20"
        direct="left"
        strokeColor="#7B7E8C"
        class="icon"
      />
      <span style="color: #7b7e8c">{{ category.name }}</span>
    </div>

    <div class="head-info">
      <div class="head-info-ttl">
        <div style="display: flex; gap: 10px; align-items: center">
          <h1>{{ category.name }}</h1>
          <div style="cursor: pointer" @click="editCategory">
            <pencil :height="30" />
          </div>
        </div>
        <p>{{ category.description }}</p>
      </div>
      <Button
        icon="+"
        outline
        name="Create Class"
        style="background-color: #3b54d9; color: #ffffff; width: 180px"
        @click="() => $router.push('class/new')"
      />
    </div>
    <v-row>
      <!-- Search  -->
      <v-col>
        <div class="action-head">
          <div class="f-search">
            <!-- Input -->
            <div>
              <v-text-field
                v-model="filter.name"
                type="text"
                class="f-input"
                placeholder="Search..."
                clearable
                dense
                flat
                solo
                style="
                  padding-top: 5px;
                  padding-left: 5px;
                  width: 448px;
                  border-radius: 14px;
                "
                @input="applyFilters"
              ></v-text-field>
            </div>
            <!-- Icon -->
            <div class="f-search-ic">
              <img
                src="/img/icons/search.png"
                style="width: 100%"
                alt="Search Ic"
              />
            </div>
          </div>
        </div>
      </v-col>
      <!-- Filter -->
      <v-col style="position: relative">
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 8px;
            color: #7b7e8c;
            font-weight: 500;
            justify-content: center;
            align-items: center;
            background-color: white;
            padding: 10px;
            border: 1px solid #d3d6e1;
            border-radius: 10px;
            width: 100px;
            margin-top: 7px;
            cursor: pointer;
          "
          @click="toggleFilterModal"
        >
          <FilterIcon width="24" height="24" />
          <div>Filter</div>
        </div>
        <!-- Modal -->
        <FilterModal
          :show="showFilterModal"
          @apply="applyFilters"
          :filterDatas="filterModal"
          :filters="filter"
          @updateFilter="updateFilters"
        />
      </v-col>
      <v-col style="display: flex; justify-content: flex-end; gap: 10px">
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="classes"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" @click="multipleAttendance(item)" > mdi-clock </v-icon>
        <v-icon class="mr-2" @click="multipleChoice(item)" > mdi-apps </v-icon>
        <button @click="watchItem(item)" class="me-2">
          <WatchTable height="28" width="28" />
        </button>
        <button @click="editItem(item)" class="me-2">
          <EditTable height="28" width="28" />
        </button>
        <button @click="deleteItem(item)">
          <TrashTable height="28" width="28" />
        </button>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="totalData" />

    <v-row justify="center" v-if="total >= 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getClasses"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
      <b
        >{{ Math.floor((currentPage - 1) * 10 + classes.length) }} dari
        {{ totalData }}</b
      >
      class yang tertampil
    </p>
  </div>
</template>

<script>
import moment from "moment";
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import Pencil from "@/components/icons/Pencil.vue";
import Arrow from "@/components/icons/Arrow.vue";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import TrashTable from "@/components/icons/TrashTable.vue";
import EditTable from "../../../components/icons/EditTable.vue";
import WatchTable from "../../../components/icons/WatchTable.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import NewModal from "@/components/NewModal.vue";
import FilterIcon from "@/components/icons/Filter.vue";
import FilterModal from "@/components/FilterModal.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  components: {
    Loading,
    Button,
    Modal,
    Pencil,
    Arrow,
    TrashTable,
    EditTable,
    WatchTable,
    NoDataAlert,
    NewModal,
    FilterIcon,
    FilterModal,
    Popup
  },
  data() {
    return {
      isLoading: false,
      isModal: false,
      isModal2: false,
      currentPage: 1,
      total: 0,
      search: "",
      categoryId: this.$route.params.id_category,
      id: 0,
      message: "",
      isPopup: false,
      form: {
        name: "",
        description: "",
      },
      category: {
        name: "",
        description: "",
      }, // important
      totalData: 0,
      isFilterActive: false,
      filter: {
        sort_by: "",
        name: "",
      },
      headers: [
        {
          text: "ID",
          value: "id",
          width: "1",
        },
        { text: "NAMA", value: "name", width: "250"},
        { text: "LEVEL", value: "level", sortable: false },
        // {text: "BATAS PESERTA", value: "total_audience", sortable: false},
        { text: "KATEGORI", value: "class_category.name", sortable: false,},
        {
          text: "SUB KATEGORI",
          value: "class_sub_category.name",
          sortable: false,
        },
        { text: "AKSI", value: "actions", sortable: false, width: "230" },
      ],
      classes: [],
      showFilterModal: false,
      filterModal: [
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
    };
  },
  methods: {
    applyFilters() {
      this.getClasses();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    watchItem(item) {
      this.$router.push(
        `/class-category/${this.categoryId}/class/watch/${item.id}`
      );
    },
    editItem(item) {
      this.$router.push(
        `/class-category/${this.categoryId}/class/edit/${item.id}`
      );
    },
    multipleChoice(item) {
      this.$router.push(`/choice/${this.categoryId}/class/${item.id}`);
    },
    multipleAttendance(item) {
      this.$router.push(`/attendance/${this.categoryId}/class/${item.id}`);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    editCategory() {
      this.form = { ...this.category };
      this.isModal2 = true;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$web_http.delete("/v1/class/" + this.id);
        this.isModal = false;
        this.getClasses();
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async getCategory() {
      try {
        const resp = await this.$crm_http.get(
          `/v1/class-category/${this.categoryId}`
        );
        this.category = resp.data.data;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getClasses() {
      // this.spin_load.val = true
      await this.$crm_http
        .get(
          `/v1/class/category/${this.categoryId}?sort_by=${
            this.filter.sort_by
          }&name=${this.filter.name || ""}&page=${this.currentPage}&per_page=10`
        )
        .then((response) => {
          this.classes = response.data.data.classes;
          const t = parseInt(response.data.data.pagination.total_data);
          this.total = Math.ceil(t / 10);
          this.totalData = response.data.data.pagination.total_data;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
      // .finally(() => this.spin_load.val = false)
    },
    // async createClass() {
    //     // this.spin_load.val = true
    //     await this.$http
    //     .post("/v1/class", {
    //         "name": this.editedItem.name,
    //         "image_url": this.editedItem.image_url,
    //         "description": this.editedItem.description,
    //         "level": this.editedItem.level,
    //         "slug_name": this.editedItem.slug_name,
    //         "class_category_id":this.editedItem.class_category_id,
    //         "class_sub_category_id": this.editedItem.class_sub_category_id,
    //         "thumbnail_url": this.editedItem.thumbnail_url,
    //         "small_image_url": this.editedItem.small_image_url
    //     })
    //     .then((response) => {
    //         this.classes = response.data.data.classes
    //     })
    //     .catch((err) => console.error(err.response))
    //     // .finally(() => this.spin_load.val = false)
    // },

    // async updateClass(id) {
    //     // this.spin_load.val = true
    //     await this.$http
    //     .put("/v1/class/"+id, {
    //         "title": this.editedItem.title,
    //         "date_release": String(this.editedItem.date_release),
    //         "image_url": this.editedItem.image_url,
    //         "body": this.editedItem.body,
    //         "slug_name": this.editedItem.slug_name,
    //         "thumbnail_url": this.editedItem.thumbnail_url,
    //     })
    //     .then((response) => {
    //         this.classes = response.data.data.classes
    //     })
    //     .catch((err) => console.error(err.response))
    //     // .finally(() => this.spin_load.val = false)
    // },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    async updateCategory() {
      try {
        await this.$crm_http.put(
          "/v1/class-category/" + this.categoryId,
          this.form
        );
        this.isModal2 = false;
        this.getCategory();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getClasses();
    this.getCategory();
  },
};
</script>
<style scoped>
.action-head {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}
.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}

.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}
.blue-text {
  font-weight: bold;
  color: #162ca2;
  cursor: pointer;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  text-align: left;
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.head-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.head-info-ttl {
  width: 80%;
  margin-bottom: 30px;
}
</style>
