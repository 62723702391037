<template>
  <div class="ma-10">
    <!-- modal showing detail -->
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="50%"
    >
      <div class="container-modal">
        <div class="container-title-modal">
          <h2 class="title-modal">Detail User Aktif</h2>
          <InfoIcon :width="20" :height="20" />
        </div>
        <div class="modal-body">
          <div class="data-list">
            <div class="detail-data" style="flex: 1">
              <p class="detail-label">Image</p>
              <div style="height: 40px; width: 40px; overflow: hidden">
                <img
                  style="width: 100%; height: 100%"
                  :src="
                    userDetail.image
                      ? userDetail.image
                      : 'https://t9003038375.p.clickup-attachments.com/t9003038375/afef200c-37cb-4e5e-aead-822ec4c0c7e7/rb_4707.png?view=open'
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="detail-data" style="flex: 1">
              <p class="detail-label">Title</p>
              <p class="detail-value">{{ userDetail.gender }}</p>
            </div>
            <div class="detail-data" style="flex: 3">
              <p class="detail-label">Nama Lengkap</p>
              <p class="detail-value">{{ userDetail.full_name }}</p>
            </div>
          </div>

          <div class="data-list">
            <div class="detail-data">
              <p class="detail-label">No.Handphone</p>
              <p class="detail-value">(+62) {{ userDetail.phone_number }}</p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Email</p>
              <p class="detail-value">{{ userDetail.email }}</p>
            </div>
          </div>

          <div class="data-list">
            <div class="detail-data">
              <p class="detail-label">Tanggal Lahir</p>
              <p class="detail-value">
                {{ formattedBirthdate(userDetail.birthdate) }}
              </p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Roles</p>
              <p class="detail-value">{{ userDetail.role }}</p>
            </div>
          </div>
          <div class="data-list">
            <div class="detail-data">
              <p class="detail-label">Alasan</p>
              <p class="detail-value">
                {{ userDetail.note }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- user on active -->
    <Loading v-if="isLoading" />
    <div class="container-box" style="margin-bottom: 20px">
      <h1>User Tidak Aktif</h1>
    </div>
    <!-- Filter -->
    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1 mt-10 pa-5"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-5">
          <v-col class="d-flex align-center">
            <div class="ctn">
              <div class="f-search">
                <v-text-field
                  v-model="filter.search"
                  placeholder="Cari akun..."
                  clearable
                  dense
                  flat
                  solo
                  prepend-inner-icon="mdi-magnify"
                  style="
                    border-radius: 14px;
                    max-width: 248px;
                    margin-right: 20px;
                  "
                ></v-text-field>
              </div>

              <date-picker
                class="my-datepicker"
                placeholder="Date time"
                valueType="format"
                v-model="filter.date"
                range
              ></date-picker>

              <div class="btn-filter-horizontal">
                <Button name="TERAPKAN" @click="applyFilters" />
              </div>

              <div v-if="isFilterActive" class="btn-filter-horizontal">
                <Button
                  class="reset-filter"
                  @click="clearFilter()"
                  name="RESET"
                />
              </div>
            </div>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.birthdate="{ item }">
        <p>{{ $helpers.getDate(item.birthdate) }}</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="actions" style="display: flex">
          <button small @click="editItem(item)" class="me-2 small-button">
            EDIT
          </button>
          <button
            small
            @click="watchItem(item)"
            class="me-2 small-button"
            style="color: blue"
          >
            LIHAT
          </button>
        </div>
      </template>
    </v-data-table>
    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getUserNonActive"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import Loading from "@/components/ilustration/Loading.vue";
import moment from "moment";
import NoDataAlert from "@/components/NoDataAlert.vue";
import Modal from "@/components/Modal.vue";
import DatePicker from "vue2-datepicker";
export default {
  data() {
    return {
      watchIndex: 0,
      isModal: false,
      isModalDetail: false,
      isFilterActive: false,
      currentPage: 1,
      total: 0,
      isLoading: false,
      id: 0,
      filter: {
        search: "",
        date: "",
        start_date: "",
        end_date: "",
      },
      headers: [
        {
          text: "NO",
          value: "id",
          width: "55",
        },
        { text: "TITLE", value: "gender", width: "20" },
        { text: "NAMA", value: "full_name", width: "100" },
        { text: "NO.TELPON", value: "phone_number", width: "80" },
        { text: "EMAIL", value: "email", width: "80" },
        { text: "TGL LAHIR", value: "birthdate", width: "100" },
        { text: "ROLES", value: "role", width: "80" },
        { text: "ACTION", value: "actions", sortable: false, width: "100" },
      ],
      users: [],
      userDetail: {},
    };
  },

  components: {
    Button,
    Loading,
    NoDataAlert,
    Modal,
    DatePicker,
  },
  methods: {
    async getUserNonActive() {

      this.filter.start_date = this.filter.date[0] || "";
      this.filter.end_date = this.filter.date[1] || "";
      try {
        const resp = await this.$crm_http.get(`/v1/user?is_active=false&per_page=10&page=${this.currentPage}&keyword=${this.filter.search}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`);
        this.users = resp.data.data.users;
        this.total = parseInt(resp.data.data.pagination.total_page);
      } catch (err) {
        console.error(err);
      }
    },

    watchItem(item) {
      this.watchIndex = this.users.indexOf(item);
      console.log(this.users.indexOf(item));
      this.userDetail = Object.assign({}, item);
      this.isModalDetail = true;
    },

    editItem(item) {
      this.$router.push("/user-non-active/form/" + item.id);
    },

    applyFilters() {
      this.currentPage = 1;
      this.getUserNonActive();
      this.isFilterActive = true;
    },

    // checkFilter() {
    //   if (this.filter.search) {
    //     this.isFilterActive = true;
    //   }
    // },

    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        search: "",
        date: "",
        start_date: "",
        end_date: "",
      };
      this.getUserNonActive();
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },

    formattedBirthdate(inputDate) {
      const date = new Date(inputDate);
      return `${String(date.getDate()).padStart(2, "0")}/${String(
        date.getMonth() + 1
      ).padStart(2, "0")}/${date.getFullYear()}`;
    },
  },
  mounted() {
    this.getUserNonActive();
    // this.applyFilters()
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.detail-data-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-left: 10px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border-radius: 8px; /* Membuat sudut membulat */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Menambahkan sedikit bayangan */
}
.detail-label {
  font-weight: normal; /* Mengubah dari bold menjadi normal */
  color: gray;
  margin-bottom: 5px;
}
.detail-value {
  color: black;
  margin-bottom: 10px;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.detail-data-box {
  border: 1px solid #ccc;
  padding: 10px;
  margin-left: 10px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.detail-label {
  font-weight: normal;
  color: gray;
  margin-bottom: 5px;
}
.detail-value {
  color: black;
  margin-bottom: 10px;
}
.my-datepicker {
  width: auto;
}
.my-datepicker ::v-deep .mx-input {
  height: 40px !important;
  border-radius: 10px;
}

.container-modal {
  padding: 20px 30px;
}

.container-title-modal {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
}

.title-modal {
  font-weight: 700;
  color: #36373f;
  font-size: 32px;
  line-height: 48px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  row-gap: 25px;
}

.data-list {
  display: flex;
  gap: 20px;
  width: 100%;
}

.detail-data {
  flex: 1;
  text-align: left;
  border: 1px solid #d6d6d6;
  padding: 16px 22px;
  border-radius: 10px;
}

.detail-label {
  color: #7b7e8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.detail-value {
  color: #36373f;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.ctn {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.f-search {
  border: 1px solid #d3d6e1;
  height: 40px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.f-date {
  border: 1px solid #d3d6e1;
  height: 42px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.actions {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  font-weight: bold;
}

.text {
  color: blue;
}
.reset-filter {
  cursor: pointer;
  color: #acafbf !important;
  background-color: white !important;
  border: 1px solid #acafbf;
}
</style>
