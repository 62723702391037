<template>
  <div>
    <v-dialog v-model="dialog" max-width="963">
      <v-img :src="src"></v-img>
    </v-dialog>
    <v-img
      :src="src"
      :width="width"
      :height="height"
      :style="{ borderRadius: `${radius}px` }"
      @click="dialog = true"
      contain
    ></v-img>
  </div>
</template>

<script>
export default {
  name: "ImageModal",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "500",
    },
    height: {
      type: String,
      default: "500",
    },
    radius: {
      type: String,
      default: "0",
    },
  },
};
</script>
