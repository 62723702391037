<template>
    <div class="body-form">
      <Loading v-if="isLoading" />
      <Popup
        :message="message"
        v-if="isPopup"
        @close="() => (isPopup = false)"
      />
      <div class="cart-form">
        <h1>{{ name }}</h1><br>
        <div>
          <div class="content-form">
            <p>Email pengirim</p>
            <v-text-field
              outlined
              v-model="form.sender_email"
              class="form-input"
              dense
              readonly
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Email penerima</p>
            <v-text-field
              outlined
              v-model="form.recipient_email"
              class="form-input"
              dense
              readonly
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Nama penerima</p>
            <v-text-field
              outlined
              v-model="form.recipient_name"
              class="form-input"
              dense
              readonly
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Subject email</p>
            <v-text-field
              outlined
              v-model="form.subject"
              class="form-input"
              dense
              readonly
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Status kirim</p>
            <v-text-field
              outlined
              v-model="form.status"
              class="form-input"
              dense
              readonly
              clearable
            ></v-text-field>
          </div>
          <div class="content-form">
            <p>Dikirim pada</p>
            <v-text-field
              outlined
              v-model="form.sent_at"
              class="form-input"
              dense
              readonly
              clearable
            ></v-text-field>
          </div>
          <div class="content-form mt-5">
            <p class="text-center">Isi email</p>
            <div class="watch-ctn mt-2">
          <div v-html="form.body"></div>
        </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import moment from "moment";
  import Popup from "@/components/others/Popup.vue";
  
  
  export default {
    name: "FormBlog",
    data() {
      return {
        id: null,
        modal: false,
        startPicker: false,
        endPicker: false,
        name: "",
        message: "",
        isPopup: false,
        isLoading: false,
        isWatch: false,
        isEdit: false,
        tags: [],
        types: [
          { name: "Awareness", value: "awareness" },
          { name: "Sales", value: "sales" },
        ],
        statuses: [
          { name: "Aktif", value: true },
          { name: "Non Aktif", value: false },
        ],
        form: {
          id: "",
          recipient_email: "",
          recipient_name: "",
          email_subject: "",
          status: false,
          sent_at: "",
        },
        img_web: null,
        img_mobile: null,
        img_web_name: "",
        img_mobile_name: "",
        breadcrumbs: [
          { text: "Master Data", disabled: false, href: "/main-banner" },
          { text: "Main Banner", disabled: false, href: "/main-banner" },
        ],
      };
    },
    components: {
      Loading,
      Popup
    },
    computed: {
      validData() {
        const validTime = () => {
          if (this.form.is_schedule) {
            return this.form.start_datetime && this.form.end_datetime;
          }
          return true;
        };
        if (this.isEdit) {
          return this.form.name && this.form.link_url && validTime();
        }
        return (
          this.form.name &&
          this.form.link_url &&
          this.img_web &&
          this.img_mobile &&
          validTime()
        );
      },
      timeDifference: function () {
        const lastUpdate = moment(this.form.updated_at);
  
        const minutesDifference = moment().diff(lastUpdate, "minutes");
        const dayDifference = moment().diff(lastUpdate, "days");
        const hourDifference = moment().diff(lastUpdate, "hours");
  
        if (minutesDifference < 1)
          return "Terakhir diperbarui beberapa detik yang lalu";
        else if (hourDifference < 1) {
          return "Terakhir diperbarui " + minutesDifference + " menit yang lalu";
        } else if (hourDifference < 24) {
          return "Terakhir diperbarui " + hourDifference + " jam yang lalu";
        } else {
          return "Terakhir diperbarui " + dayDifference + " hari yang lalu";
        }
      },
  
      dueDate: function () {
        console.log(this.form.end_datetime);
        return moment(this.form.end_datetime).format("MMM D, yyyy");
      },
    },
    methods: {
      checkingType() {
        this.name = this.$route.meta.name;
        this.breadcrumbs.push({ text: this.$route.meta.name, disabled: false });
        this.id = this.$route.params.id;
        if (!this.id) return;
        this.getBirthdayById();
      },
      async getBirthdayById() {
        this.isLoading = true;
        try {
          const resp = await this.$web_http.get("/v1/email-blasting/" + this.id);
          this.form = resp.data.data.email_blasting;
          this.form.status = this.form.status ? 'Terikirim' : 'Tidak Terkirim'
          this.form.sent_at = this.$helpers.getDateFull(this.form.sent_at)
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
        } finally {
          this.isLoading = false;
        }
      },
      copyLink() {
        const input = document.createElement("input");
        input.style.position = "fixed";
        input.style.opacity = 0;
        input.value = this.form.link_url;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        alert("Link copied to clipboard");
      },
    },
    mounted() {
      this.checkingType();
    },
  };
  </script>
  
  <style scoped>
  .body-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f9fd;
    padding: 50px 0;
    min-height: 100vh;
    width: 100vw;
  }
  .cart-form {
    position: relative;
    width: 50%;
    border-radius: 30px;
    padding: 30px 40px;
    border: 1px solid #d6d6d6;
    background-color: #ffffff;
  }
  .sub-form {
    margin-top: 20px;
    background-color: #f5f6fa;
    padding: 26px 32px;
    border-radius: 16px;
  }
  .back-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    color: rgb(198, 198, 198);
  }
  .form-input {
    border-radius: 10px !important;
  }
  
  ::v-deep .form-input .v-input__slot {
    height: 62px !important;
    margin-bottom: 20px;
    background-color: #ffffff !important;
  }
  
  ::v-deep .form-input .v-text-field__details {
    display: none;
  }
  
  ::v-deep .form-upload .v-input__slot {
    height: 62px !important;
    background-color: #ffffff !important;
  }
  
  ::v-deep .form-upload .v-text-field__details {
    display: none;
  }
  
  ::v-deep .form-select .v-input__slot {
    height: 56 !important;
    margin-bottom: 20px;
    background-color: #ffffff;
  }
  
  ::v-deep .form-select .v-text-field__details {
    display: none;
  }
  
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
    font-size: 14px;
    margin: 3px 0;
    font-weight: 400;
  }
  
  .set-time {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .banner-berkas {
    font-size: 18px;
    margin: 20px 0;
    font-weight: bold;
  }
  .watch-ctn {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid gray;
  }
  .submit-box {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
  }
  .alert-info {
    font-size: 14px;
    color: #7b7e8c;
  }
  @media screen and (max-width: 1100px) {
    .cart-form {
      width: 70%;
    }
  }
  .note-file {
    font-weight: 400;
    font-size: 12px;
    color: #b80f0a;
    margin-bottom: 20px !important;
  }
  
  .breadcrumbs {
    padding: 0px;
    margin-left: 0px;
    margin-bottom: 35px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  }
  
  ::v-deep .v-breadcrumbs__divider {
    color: #162ca2 !important;
  }
  
  ::v-deep .v-breadcrumbs__item {
    color: #162ca2;
  }
  
  ::v-deep div.v-breadcrumbs__item {
    color: #7b7e8c !important;
  }
  
  .small-switch {
    transform: scale(0.75);
  }
  
  .pill {
    background-color: #f9f9f9;
    border-color: #d6d6d6;
    border: 1px solid;
    border-radius: 100px;
    padding: 4px 12px 4px 12px;
    color: #7b7e8c;
    text-transform: capitalize;
  }
  
  .pill-alert {
    background-color: #f7e7e6;
    border: 0px;
    color: #b80f0a;
    text-transform: none;
  }
  
  .watch-content {
    background-color: #ffffff;
    border: 0.5px solid #d6d6d6;
    border-radius: 10px;
  }
  
  .watch-content a {
    text-decoration: none !important;
    color: #2d46cf;
    font-weight: 400;
  }
  </style>
  