<template>
    <div class="ma-10">
        <h1>Class Detail</h1>
        <v-data-table
            :headers="headers"
            :items="classdetails"
            sort-by="calories"
            class="elevation-1 mt-10"
            hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            >
                            Create New Blog
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                            <v-container>
                                <v-text-field
                                    v-model="editedItem.title"
                                    label="Title"
                                ></v-text-field>

                                 <v-dialog
                                    ref="dialog"
                                    v-model="modal"
                                    :return-value.sync="editedItem.date_release"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editedItem.date_release"
                                        :rules="[() => !!editedItem.date_release || 'This field is required']"
                                        label="Date Release"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editedItem.date_release"
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="modal = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(editedItem.date_release)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                                
                                <v-text-field
                                    v-model="editedItem.image_url"
                                    label="Image"
                                ></v-text-field>

                                <!-- <v-file-input
                                    v-model="editedItem.image"
                                    accept="image/*"
                                    label="Image"
                                    @change="uploadImage"
                                ></v-file-input> -->
                            
                                <v-textarea
                                    v-model="editedItem.body"
                                    label="Body"
                                ></v-textarea>

                                <v-text-field
                                    v-model="editedItem.slug_name"
                                    label="Slug name"
                                ></v-text-field>

                                <v-text-field
                                    v-model="editedItem.thumbnail_url"
                                    label="Thumbnail"
                                ></v-text-field>
                                <!-- <v-file-input
                                    v-model="editedItem.thumbnail_url"
                                    accept="image/*"
                                    label="Thumbnail"
                                ></v-file-input> -->
                            </v-container>
                            </v-card-text>

                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="grey darken-1"
                                text
                                @click="close"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                            >
                                Save
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="300px">
                        <v-card>
                            <v-card-title >Are you sure you want to delete this item?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="grey darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="red darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>

            <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
            
        </v-data-table>

        <v-row justify="center">
            <v-col cols="8">
            <v-container class="max-width">
                <v-pagination v-model="currentPage" class="my-4" :length="total" @input="getBlogs"></v-pagination>
            </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import moment from 'moment'
import getAuthentification from '@/utils/badrequest.js'

export default {
    data() {
        return{
            modal: false,
            currentPage: 1,
            total: 0,
            dialog: false,
            dialogDelete: false,
            headers: [
                {
                text: 'ID',
                value: 'id',
                },
                { text: 'Title', value: 'title' },
                { text: 'Date Release', value: 'date_release' },
                { text: 'Image', value: 'image_url', sortable: false},
                { text: 'Slug Name', value: 'slug_name', sortable: false },
                { text: 'Thumbnail', value: 'thumbnail_url', sortable: false },
                { text: 'Body', value: 'body', sortable: false },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            classdetails: [],
            editedIndex: -1,
            editedItem: {
                title: '',
                date_release: '',
                image_url: '',
                body: '',
                slug_name: '',
            },
            defaultItem: {
                title: '',
                date_release: '',
                image_url: '',
                body: '',
                slug_name: '',
            },
        }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Create New Blog' : 'Edit Blog'
      },
    },

    methods: {
        async uploadImage() {
            // this.spin_load.val = true
            // Initialize the form data
            let formData = new FormData();
            // Add the form data we need to submit
            formData.append("file", this.file);

            await this.$http
            .post("sgp1.digitaloceanspaces.com", {
                'image': event,
            })
            .then((response) => {
                console.log("aaa : ", response)
            })
            .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },
        async getBlogs() {
            // this.spin_load.val = true
            // await this.$http
            // .get("/v1/blog?page="+this.currentPage+"&per_page=10")
            // .then((response) => {
            //     this.blogs = []
            //     response.data.data.blogs.forEach(element => {
            //         this.blogs.push({ 
            //             "id": element.id,
            //             "title": element.title,
            //             "date_release": this.format_date(element.date_release),
            //             "image_url": element.image_url,
            //             "body": element.body,
            //             "slug_name": element.slug_name,
            //             "thumbnail_url": element.thumbnail_url,
            //         })
            //     });
            //     this.total = parseInt(response.data.data.total / 10) + 1
            // })
            // .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },
        editItem (item) {
            this.editedIndex = this.blogs.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem (item) {
            this.editedIndex = this.blogs.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            this.blogs.splice(this.editedIndex, 1)
            this.closeDelete()
        },
        close () {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save () {
            if (this.editedIndex > -1) {
                //update blog
                console.log(this.editedItem.id)
                this.updateBlog(this.editedItem.id)
                Object.assign(this.blogs[this.editedIndex], this.editedItem)
            } else {
                //create blog
                this.createBlog()
                this.blogs.push(this.editedItem)
            }
            this.close()
        },

        async createBlog() {
            // this.spin_load.val = true
            await this.$http
            .post("/v1/blog", {
                "title": this.editedItem.title,
                "date_release": String(this.editedItem.date_release),
                "image_url": this.editedItem.image_url,
                "body": this.editedItem.body,
                "slug_name": this.editedItem.slug_name,
                "thumbnail_url": this.editedItem.thumbnail_url,
            })
            .then((response) => {
                this.blogs = response.data.data.blogs
                this.total = parseInt(response.data.data.total / 20) + 1
                console.log("aaa : ", response.data.data.blogs)
            })
            .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },

        async updateBlog(id) {
            // this.spin_load.val = true
            await this.$http
            .put("/v1/blog?id="+id, {
                "title": this.editedItem.title,
                "date_release": String(this.editedItem.date_release),
                "image_url": this.editedItem.image_url,
                "body": this.editedItem.body,
                "slug_name": this.editedItem.slug_name,
                "thumbnail_url": this.editedItem.thumbnail_url,
            })
            .then((response) => {
                this.blogs = response.data.data.blogs
                this.total = parseInt(response.data.data.total / 20) + 1
                console.log("aaa : ", response.data.data.blogs)
            })
            .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },
        format_date(value){
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
    },
    beforeCreate() {
        getAuthentification()
    },
    mounted() {
        this.getBlogs()
    }
}
</script>