import moment from "moment-timezone";

const dataStatusTrx = [
  {
    name: "Draft",
    value: "DRAFT",
  },
  {
    name: "Menunggu Pembayaran",
    value: "WAITING_FOR_PAYMENT",
  },
  {
    name: "Terbayar",
    value: "PAID",
  },
  {
    name: "Dibatalkan",
    value: "CANCEL",
  },
  {
    name: "Kadaluarsa",
    value: "EXPIRED",
  },
]

const dataStatusCRMb2b = [
  {
    name: "Data Entry",
    value: "DATA_ENTRY",
  },
  {
    name: "Qualified Data",
    value: "QUALIFIED_DATA",
  },
  {
    name: "Not Qualified Data",
    value: "NOT_QUALIFIED_DATA",
  },
  {
    name: "Meeting",
    value: "MEETING",
  },
  {
    name: "Proposal",
    value: "PROPOSAL",
  },
  {
    name: "Negotiation",
    value: "NEGOTIATION",
  },
  {
    name: "Closing Won",
    value: "CLOSING_WON",
  },
  {
    name: "Closing Pending",
    value: "CLOSING_PENDING",
  },
  {
    name: "Closing Negotiation",
    value: "CLOSING_NEGOTIATION",
  },
  {
    name: "Closing Loss",
    value: "CLOSING_LOSS",
  },
]

const dataStatusApplicantList = [
  {
    name: "Data Entry",
    value: "DATA_ENTRY"
  },
  {
    name: "Qualified Data",
    value: "QUALIFIED_DATA"
  },
  {
    name: "Not Qualified Data",
    value: "NOT_QUALIFIED_DATA"
  },
  {
    name: "Interview Scheduled",
    value: "INTERVIEW_SCHEDULED"
  },
  {
    name: "Interview Conducted",
    value: "INTERVIEW_CONDUCTED"
  },
  {
    name: "Pass Interview",
    value: "PASS_INTERVIEW"
  },
  {
    name: "Failed Interview",
    value: "FAILED_INTERVIEW"
  },
  {
    name: "Skill Test Scheduled",
    value: "SKILL_TEST_SCHEDULED"
  },
  {
    name: "Skill Test Conducted",
    value: "SKILL_TEST_CONDUCTED"
  },
  {
    name: "Pass Skill Test",
    value: "PASS_SKILL_TEST"
  },
  {
    name: "Failed Skill Test",
    value: "FAILED_SKILL_TEST"
  },
  {
    name: "Negotiation",
    value: "NEGOTIATION"
  },
  {
    name: "Deal Recommendation",
    value: "DEAL_RECOMMENDATION"
  },
]

export default {
  getDate(date) {
    return moment.utc(date).format("DD MMM YYYY");
  },
  getDateFull(date) {
    return moment.utc(date).format("DD MMM YYYY HH:mm:ss");
  },
  formatDate(date) {
    return moment.utc(date).format("YYYY-MM-DD");
  },
  getYear(date) {
    return moment.utc(date).format("YYYY");
  },
  getTime(date) {
    return moment.utc(date).format("HH:mm");
  },
  getUniqDate(date, format) {
    return moment.utc(date).format(format);
  },
  applyFormatFile() {
    return ".jpg,.jpeg,.png";
  },
  format_date(value, format) {
    if (!value) return ""
    return moment(String(value)).format(format || 'YYYY-MM-DD');
  },
  phoneFormat(str) {
    try {
      return str.match(/.{1,4}/g).join('-');
    } catch {
      return str
    }
  },
  toMethode(value) {
    if (!value) return
    if (value.toLowerCase() === "online") {
      return "Daring (via Zoom)"
    } else if (value.toLowerCase() === "offline") {
      return "Luring"
    } else {
      return "Online / Offline"
    }
  },
  nameStatusList(value) {
    try {
      return dataStatusTrx.find(item => item.value === value).name 
    } catch (error) {
      return "Data Entry"
    }
  },
  nameApplicantList(value) {
    try {
      return dataStatusApplicantList.find(item => item.value === value).name 
    } catch (error) {
      return "Data Entry"
    }
  },
  statusApplicantList() {
    return dataStatusApplicantList
  },
  nameCRMb2bList(value) {
    try {
      return dataStatusCRMb2b.find(item => item.value === value).name 
    } catch (error) {
      return "Data Entry"
    }
  },
  statusCRMb2bList() {
    return dataStatusCRMb2b
  },
  newTab(link) {
    window.open(link)
  },
  formatCurrency(amount, isDiscount) {
    if (typeof amount !== 'number') {
      return '';
    }
    if (isDiscount){
      return `-IDR ${amount.toLocaleString('id-ID')}`;
    }
    return `IDR ${amount.toLocaleString('id-ID')}`;
  },
  formatDateWithTimeAndTimeZone(date) {
    const formattedDate = moment(date).locale('id').format('DD MMMM YYYY');
    const formattedTime = moment(date).format('HH.mm');
    const timezone = moment(date).format('z');
    return `${formattedDate}, ${formattedTime} ${timezone}`;
},
  multipleChoices() {
    return [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];
  },
  categoryClass(idx) {
    const category = [
      "technology",
      "bahasa",
      "soft skill",
      "prakerja",
      "public bootcamp",
    ];
    return category[idx - 1];
  },
  customToolBar() {
    return [
      [{ font: ['serif', 'monospace', 'roboto'] }],
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ header: 1 }, { header: 2 }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "formula", "image"],
      [{ direction: "rtl" }],
      ["clean"],
    ];
  },
};
