<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <Modal v-if="isModalStatus" @close="() => (isModalStatus = false)" width="40%">
      <h2>Kamu yakin ingin merubah status Portofolio ini?</h2>
      <p>Anda akan mengubah status data portofolio ini. Perubahan status akan memengaruhi tampilan & akses konten.</p>
      <div style="display: flex">
        <button @click="isModalStatus = false" class="btn-cancel">BATALKAN</button>
        <button @click="onChangeStatus()" class="btn-save">
          YA, UBAH
        </button>
      </div>
    </Modal>
    <div style="width: 90%; margin: 30px 0;">
      <div class="menus-top">
        <span class="blue-text">Content Management System</span>
        <Arrow :width="20" :height="20" direct="left" strokeColor="#7B7E8C" class="icon" />
        <span class="blue-text" @click="$router.push('/project-portfolio')">Portfolio</span>
        <Arrow :width="20" :height="20" direct="left" strokeColor="#7B7E8C" class="icon" />
        <span style="color: #7b7e8c">{{ name }}</span>
      </div>
      <div class="cart-form mt-5">
        <div class="card-form-head">
          <h1>
            Informasi Umum Portofolio
          </h1>
          <div class="card-form-head-bdy" @click="openConfirm" v-if="!isWatch">
            <p>
              Status {{ form.is_active ? "aktif" : " tidak aktif" }}
            </p>
            <label class="switch">
              <input v-model="form.is_active" type="checkbox" :disabled="isEdit" />
              <span class="slider"></span>
            </label>
          </div>
        </div>
        <div class="mt-5">
          <div class="content-form">
            <p>Nama Project Portofolio</p>
            <v-text-field outlined v-model="form.program" class="form-input"
              placeholder="Masukan Nama Project Portofolio" dense :disabled="isWatch" clearable></v-text-field>
          </div>

          <div class="content-form">
            <p>Nama Klien</p>
            <v-text-field outlined v-model="form.title" class="form-input" placeholder="Masukan Nama Klien" dense
              :disabled="isWatch" clearable></v-text-field>
          </div>

          <div class="content-form">
            <p>Waktu Project Dibuat</p>
            <v-menu ref="startPicker" v-model="startPicker" :close-on-content-click="false"
              :return-value.sync="form.start_date" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="form.start_date" placeholder="Tetapkan waktu Project Dibuat" class="form-input"
                  readonly v-bind="attrs" v-on="on" outlined dense clearable :disabled="isWatch">
                  <template v-slot:append>
                    <Calendar style="cursor: pointer"></Calendar>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker v-model="form.start_date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startPicker = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.startPicker.save(form.start_date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div class="content-form">
            <p>Kategori Portofolio</p>
            <v-select class="form-select" :items="categories" :item-text="'name'" :item-value="'value'"
              v-model="form.category" :disabled="isWatch" outlined>
            </v-select>
          </div>
          <div class="content-form">
            <p>Tampilan Inti</p>
            <div class="spacing-switch">
              <v-switch v-model="form.is_main" color="primary" hide-details :disabled="isWatch"
                style="margin-top: -5px"></v-switch>
              <div v-if="form.is_main">
                <Chip :active="form.is_main" />
              </div>
            </div>
          </div>
          <div class="content-form">
            <p>Link Project</p>
            <v-text-field outlined v-model="form.link_project" class="form-input" placeholder="Masukan Link Project"
              dense clearable :disabled="isWatch"></v-text-field>
          </div>
          <div class="content-form">
            <p>Pilih Device</p>
            <v-select :disabled="isWatch" v-model="form.device" :items="deviceList" multiple chips persistent-hint
              outlined item-text="name" return-object class="form-select"></v-select>
          </div>
          <div class="content-form">
            <p>Teknologi</p>
            <v-select :disabled="isWatch" v-model="form.technology" :items="technologyList" multiple chips
              persistent-hint outlined item-text="name" return-object class="form-select"></v-select>
          </div>
          <div class="content-form">
            <p>Lingkup Project</p>
            <v-select :disabled="isWatch" v-model="form.scope_project" :items="scopeProjectList" multiple chips
              persistent-hint outlined item-text="name" return-object class="form-select"></v-select>
          </div>
          <div class="content-form">
            <p>Deskripsi Singkat</p>
            <v-textarea
                  outlined
                  :disabled="isWatch"
                  v-model="form.description"
                  class="mt-2 rounded-sm"
                  hide-details=""
                  placeholder="Tulis di sini..."
                  rows="4"
                ></v-textarea>
          </div>
          <div class="content-form mt-4">
            <p>Detail Project</p>
            <vue-editor v-model="form.body" :editorToolbar="$helpers.customToolBar()" class="form-input"
              v-if="!isWatch"></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="form.body"></div>
            </div>
          </div>
          <div class="content-form mt-4">
            <p>Deskripsi Tanggung Jawab Kami</p>
            <vue-editor v-model="form.responsibility" :editorToolbar="$helpers.customToolBar()" class="form-input"
              v-if="!isWatch"></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="form.responsibility"></div>
            </div>
          </div>
          <div class="content-form mt-4">
            <p>Berkas Gambar Thumbnail</p>
            <v-file-input v-model="img_portfolio" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="img_portfolio == null">
                <ImageModal :src="form.image_url" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
            <p class="note-file">
              Ungah file dalam format SVG dengan dimensi 56x56, maksimal 1 MB
            </p>
          </div>
        </div>
      </div>
      <div class="cart-form mt-5">
        <v-card-title class="card-form-head" style="border: none">
          <h1>
            Dokumentasi Project
          </h1>
        </v-card-title>
        <div class="content-form mt-5">
          <div class="list-event mt-2" v-for="(item, idx) in form.key_feature" :key="idx">
            <p>Gambar Pendukung</p>
            <div class="icon-plus-bc" @click="form.key_feature.splice(idx, 1)">
              <trash-icon :width="25" :height="25" />
            </div>
            <v-file-input v-model="form.key_feature[idx].img_mo" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" :disabled="isWatch" outlined>
              <template v-slot:prepend-inner v-if="form.key_feature[idx].img_mo == null">
                <ImageModal :src="form.key_feature[idx].img" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
            <p class="note-file">
              Ungah file dalam format SVG dengan dimensi 56x56, maksimal 1 MB
            </p>
          </div>
          <div class="add-button" @click="addKeyFeature" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Gambar</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button name="Batalkan" outline class="me-3" @click="$router.push('/project-portfolio')" />
        <Button :name="isEdit ? 'Simpan' : 'Simpan & Publish'" :width="isEdit ? '156px' : '200px'" @click="onSubmit"
          :disabled="!validData" style="background-color: #3b54d9; color: #ffffff" />
      </div>
    </div>

  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Button from "@/components/Button.vue";
import ImageModal from "@/components/ImageModal.vue";
import Popup from "@/components/others/Popup.vue";
import { VueEditor } from "vue2-editor";
import TrashIcon from "@/components/icons/Trash.vue";
import Chip from "@/components/Chip.vue";
import Arrow from '@/components/icons/Arrow.vue';
import Modal from "@/components/Modal.vue";
import Calendar from "@/components/icons/Calendar.vue";

export default {
  name: "FormProjectPortfolio",
  components: {
    Button,
    Loading,
    ImageModal,
    TrashIcon,
    Chip,
    Popup,
    VueEditor,
    Arrow,
    Modal,
    Calendar
  },
  computed: {
    validData() {
      return (
        this.form.title &&
        this.form.program &&
        this.form.category
      );
    },
  },
  data() {
    return {
      id: "",
      startPicker: false,
      modal: false,
      isModalStatus: false,
      endPicker: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      tags: [],
      message: "",
      isPopup: false,
      scopeProjectList: [{ name: "IT Consulting", value: "/img/portfolio/it_consulting.png" }, { name: "IT Sourcing", value: "/img/portfolio/it_sourcing.png" }, { name: "IT Implementation", value: "/img/portfolio/it_implementation.png" }, { name: "IT Support", value: "/img/portfolio/it_support.png" },],
      technologyList: [
        { name: "HTML5", value: "/img/portfolio/html.png" }, { name: "CSS3", value: "/img/portfolio/css.png" }, { name: "JS", value: "/img/portfolio/js.png" }, { name: "React", value: "/img/portfolio/react.png" }, { name: "Laravel", value: "/img/portfolio/laravel.png" }, { name: "MySql", value: "/img/portfolio/mysql.png" },
      ],
      deviceList: [{ name: "Mobile" }, { name: "Website" }, { name: "Desktop" }],
      categories: [
        { name: "Collaboration", value: "COLLABORATION" },
        { name: "Training", value: "TRAINING" },
        { name: "Talent Service", value: "TALENT_SERVICE" },
        { name: "Services", value: "SERVICES" },
      ],
      form: {
        title: "",
        label: "",
        is_main: false,
        is_active: true,
        program: "",
        description: "",
        category: "",
        image_url: "",
        start_date: "",
        responsibility: "",
        link_project: "",
        technology: [],
        device: [],
        key_feature: [],
        scope_project: [],
        body: ""
      },
      img_portfolio: null,
      img_web_name: "",

    };
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataProjectPortfolio();
    },
    addKeyFeature() {
      this.form.key_feature.push({
        img_mo: null,
        img: ""
      })
    },
    async getDataProjectPortfolio() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get(
          "/v1/project-portfolio/" + this.id
        );
        this.form = resp.data.data.project_portfolio;
        this.img_web_name = this.form.image_url
          .split("/")
          .pop()
          .replace("?view=open", "")
          .replaceAll("%20", " ");
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    onChangeStatus() {
      this.isModalStatus = false
      this.form.is_active = !this.form.is_active
    },
    async onSubmit() {
      // this.isLoading = true;
      if (this.img_portfolio) {
        const imageForm = new FormData();
        imageForm.append("attachment", this.img_portfolio);
        const webRes = await this.$crm_http.post(
          "/v1/upload-file",
          imageForm
        );
        this.form.image_url =
          webRes.data.data.upload_image_response.url_w_query
          imageForm.delete("attachment");
      }
      for (const project of this.form.key_feature) {
        if (project.img_mo) {
          try {
            const formData = new FormData();
            formData.append("attachment", project.img_mo);
            const { data } = await this.$crm_http.post(
              "/v1/upload-file",
              formData
            );
            project.img = data.data.upload_image_response.url_w_query; // Simpan URL ke properti img
            delete project.img_mo;
            formData.delete("attachment");
          } catch (err) {
            console.log("Error uploading file:", err);
          }
        }
      }
      try {
        if (this.isEdit) {
          await this.$crm_http.put("/v1/project-portfolio/" + this.form.id, this.form);
        } else {
          await this.$crm_http.post("/v1/project-portfolio", this.form);
        }
        this.$router.push("/project-portfolio");
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    copyLink() {
      const input = document.createElement("input");
      input.style.position = "fixed";
      input.style.opacity = 0;
      input.value = this.form.link_url;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      alert("Link copied to clipboard");
    },
    openConfirm() {
      if (this.isEdit) this.isModalStatus = true
    }
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}

.card-form-head {
  display: flex;
  margin-left: -35px;
  margin-right: -20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.card-form-head h1 {
  font-weight: 700;
  font-size: 18px;
  color: #36373f;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.card-form-head-bdy {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.card-form-head-bdy p {
  color: #575966;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.blue-text {
  font-weight: bold;
  color: #162ca2;
  cursor: pointer;
}

.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-form {
  position: relative;

  border-radius: 30px;
  padding: 30px 40px;
  background-color: #ffffff;
}

.sub-form {
  margin-top: 20px;
  background-color: #f5f6fa;
  padding: 26px 32px;
  border-radius: 16px;
}

.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 10px !important;
}

::v-deep .form-input .v-input__slot {
  margin-bottom: 20px;
  background-color: #ffffff !important;
}

::v-deep .form-input .v-text-field__details {
  display: none;
}

::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
  display: none;
}

::v-deep .form-select .v-input__slot {
  height: 54px !important;
  margin-bottom: 20px;
  background-color: #ffffff;
}

::v-deep .form-select .v-text-field__details {
  display: none;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 14px;
  margin: 3px 0;
  font-weight: 400;
}

.set-time {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.submit-box {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}

.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}

.note-file {
  font-weight: 400;
  font-size: 12px;
  color: #b80f0a;
  margin-bottom: 20px !important;
}

.small-switch {
  transform: scale(0.75);
}

.pill {
  background-color: #f9f9f9;
  border-color: #d6d6d6;
  border: 1px solid;
  border-radius: 100px;
  padding: 4px 12px 4px 12px;
  color: #7b7e8c;
  text-transform: capitalize;
}

.pill-alert {
  background-color: #f7e7e6;
  border: 0px;
  color: #b80f0a;
  text-transform: none;
}

.watch-content {
  background-color: #ffffff;
  border: 0.5px solid #d6d6d6;
  border-radius: 10px;
}

.watch-content a {
  text-decoration: none !important;
  color: #2d46cf;
  font-weight: 400;
}


.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}

.icon-plus {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
}

.icon-plus-bc {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -56px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f6fa;
}

.parent-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}

.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #162ca2;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.btn-cancel {
  width: 50%;
  border-radius: 10px;
  border: 1px solid #acafbf;
  padding: 10px 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #acafbf;
}

.btn-save {
  width: 50%;
  border-radius: 10px;
  background-color: #2d46cf;
  border: 1px solid #2d46cf;
  padding: 10px 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
</style>