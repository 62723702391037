<template>
  <div class="ma-10">
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <h1 class="modal-title">Konfirmasi Reactive Data</h1>
      <p>Apakah kamu yakin ingin mengaktifkan ulang data ini?</p>
      <div style="display: flex">
        <button @click="cancelActive()" class="btn-cancel">Batal</button>
        <button @click="activeUser()" class="btn-save">
          YA, AKTIFKAN DATA
        </button>
      </div>
    </Modal>
    <!-- form on active -->
    <div class="container-box" style="margin-bottom: 20px">
      <v-card-text>
        <Loading v-if="isLoading" />
        <v-card-title
          style="
            display: flex;
            margin-left: -35px;
            margin-right: -20px;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h1
            style="
              font-weight: 700;
              font-size: 32px;
              color: #36373f;
              line-height: 48px;
              font-family: 'Poppins', sans-serif;
              margin: 0;
            "
          >
            Edit Data User
          </h1>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
            "
          >
            <p
              style="
                color: #575966;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                font-family: 'Poppins', sans-serif;
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 0px;
              "
            >
              {{ isModal ? "User aktif" : " User tidak aktif" }}
            </p>
            <label class="switch">
              <input v-model="isModal" type="checkbox" />
              <span class="slider"></span>
            </label>
          </div>
        </v-card-title>
        <!-- <p>{{ toggleValue }}</p> -->

        <div class="form-ctn" style="margin-bottom: 20px">
          <div class="content-form" style="margin-bottom: 20px">
            <p>Title</p>
            <v-radio-group v-model="formData.gender" row>
              <v-radio
                label="Tuan"
                value="Tuan"
                class="form-radio"
                color="blue"
              ></v-radio>
              <v-radio
                label="Nyonya"
                value="Nyonya"
                class="form-radio"
                color="blue"
              ></v-radio>
              <v-radio
                label="Nona"
                value="Nona"
                class="form-radio"
                color="blue"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="content-form">
            <p>Nama</p>
            <input
              v-model="formData.full_name"
              class="form-input"
              type="text"
              name="full_name"
              id="full_name"
            />
          </div>

          <div class="content-form">
            <p>No. Handphone</p>
            <v-row>
              <v-col cols="2" class="phone">
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #e7e8f0;
                    border-radius: 10px;
                    padding: 12px 20px;
                    gap: 10px;
                    width: 100%;
                  "
                >
                  <img
                    src="https://t9003038375.p.clickup-attachments.com/t9003038375/47d99b5a-2a4e-4e5b-b5ef-bf4163e4d488/indonesia.png?view=open"
                    :width="27"
                    :height="18"
                    alt="Indonesia Flag"
                  />
                  <p
                    style="
                      font-weight: 600;
                      margin: 0;
                      font-size: 14px;
                      line-height: 32px;
                      color: #575966;
                    "
                  >
                    +62
                  </p>
                </div>
              </v-col>
              <v-col>
                <input
                  v-model="formData.phone_number"
                  class="form-input"
                  type="text"
                  name="phone_number"
                  id="phone_number"
                />
              </v-col>
            </v-row>
          </div>

          <div class="content-form">
            <p>Email</p>
            <input
              v-model="formData.email"
              class="form-input"
              type="email"
              name="email"
              id="email"
            />
          </div>

          <div class="content-form">
            <p>Tanggal Lahir</p>
            <input
              v-model="formData.birthdate"
              class="form-input"
              type="date"
              name="birthdate"
              id="birthdate"
            />
          </div>
          <div class="content-form">
          <p>Profile</p>
          <v-file-input v-model="img_web" accept="image/*" placeholder="Unggah Berkas Gambar" class="form-upload" prepend-icon="" outlined>
            <template v-slot:prepend-inner v-if="img_web == null">
              <ImageModal :src="formData.image" width="76.31" height="37" radius="3" />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">*Rekomendasi dimensi 327 x 462 px. ukuran file maks 1 MB</p>
        </div>
          <div class="content-form">
            <p>Roles</p>
            <select
              v-model="formData.role"
              class="form-input"
              placeholder="Pilih Role"
            >
              <option
                v-for="category in categories"
                :key="category.value"
                :value="category.value"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <hr style="color: #acafbf; margin: 36px 0" />
          <div class="">
            <p>Alasan</p>
            <textarea
              v-model="formData.note"
              class="form-area"
              type="text"
              name="birthdate"
              id="birthdate"
            />
          </div>
          <div
            class="submit-box"
            style="display: flex; justify-content: flex-end; gap: 15px"
          >
            <button @click="onCancel" class="btn-cancel">
              BATALKAN
            </button>
            <button
              @click="onSubmit"
              :disabled="!validData"
              class="btn-save"
            >
              SIMPAN DATA
            </button>
          </div>
        </div>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Modal from "@/components/Modal.vue";
import moment from "moment";
import Popup from "@/components/others/Popup.vue";
import ImageModal from '@/components/ImageModal.vue';

export default {
  name: "FormUserNonActive",
  data() {
    return {
      name: "Edit Data User",
      role: 2,
      id: null,
      img_web: null,
      isLoading: false,
      formData: {
        image:""
      },
      users: [],
      // validData: true,
      toggleValue: false,
      isModal: false,
      menu: false,
      message: "",
      isPopup: false,
      watchIndex: 0,
      filter: {
        category: "MONTH",
      },
      categories: [
        {
          name: "Admin",
          value: "ADMIN",
          user_type: 1,
        },
        {
          name: "User",
          value: "USER",
          user_type: 2,
        },
        {
          name: "Pengunjung",
          value: "Pengunjung",
          user_type: 3,
        },
        {
          name: "Keuangan",
          value: "Keuangan",
          user_type: 4,
        },
        {
          name: "Manajemen",
          value: "Manajemen",
          user_type: 5,
        },
        {
          name: "Menejemen Konten",
          value: "Menejemen Konten",
          user_type: 6,
        },
      ],
    };
  },
  components: {
    Loading,
    Modal,
    Popup,
    ImageModal
  },
  computed: {
    validData() {
      return (
        this.formData.id &&
        this.formData.gender &&
        this.formData.full_name &&
        this.formData.phone_number &&
        this.formData.email &&
        this.formData.birthdate &&
        this.formData.role
      );
    },
  },
  methods: {
    async getUserNonActive() {
      await this.$web_http
        .get(`/v1/user/${this.$route.params.id}`)
        .then((response) => {
          const data = response.data.data.user;
          this.formData = {
            ...data,
            birthdate: moment(data.birthdate).format("YYYY-MM-DD"),
            role: data.user_type.id,
          };
        })
        .catch((err) => {
          this.message = err.response.data.data;
          this.isPopup = true;
        });

    },

    async onSubmit() {
      this.isLoading = true;
      const payload = {
        full_name: this.formData.full_name,
        email: this.formData.email,
        phone_number: this.formData.phone_number,
        birthdate: this.formData.birthdate,
        gender: this.formData.gender,
        role: Number(this.categories.filter((e) => e.value == this.formData.role)[0]
        .user_type) || 2,
        is_reset_password: false,
        password: "",
        image: this.formData.image,
        note: this.formData.note,
      };
      try {
        if (this.img_web) {
            const imageForm = new FormData();
            imageForm.append('attachment', this.img_web);
            const webRes = await this.$crm_http.post('/v1/upload-file', imageForm);
            payload.image = webRes.data.data.upload_image_response.url_w_query;
            imageForm.delete('attachment');
          }
        await this.$crm_http.put(`/v1/user/${this.$route.params.id}`,payload);
        this.isLoading = false;
        this.$router.push("/user-tidak-aktif");
      } catch (error) {
        this.isLoading = false;
      }
      // try {
      //   const birthdateWithTime = this.formData.birthdate
      //     ? `${this.formData.birthdate}T00:00:00Z`
      //     : null;
      //   this.formData.role =
      //     this.formData.role === "ADMIN"
      //       ? 1
      //       : this.formData.role === "USER"
      //       ? 2
      //       : this.formData.role;
      //   await this.$crm_http.put("/v1/user", {
      //     ...this.formData,
      //     id: this.formData.id,
      //     full_name: this.formData.full_name,
      //     phone_number: this.formData.phone_number,
      //     email: this.formData.email,
      //     birthdate: birthdateWithTime,
      //     gender: this.formData.gender,
      //     role: this.formData.role,
      //   });
      //   setTimeout(() => {
      //     this.$router.push("/user-tidak-aktif");
      //   }, 2000);
      // } catch (err) {
      //   this.message = err.response.data.data;
      //   this.isPopup = true;
      //   this.isLoading = false;
      // }
    },

    onCancel() {
      this.$router.push("/user-tidak-aktif");
    },

    toggleColor() {
      if (this.toggleValue) {
        this.isModal = true;
      }
    },

    cancelActive() {
      this.isModal = false;
    },

    async activeUser() {
      this.isLoading = true;
      const payload = {
        is_active: this.isModal,
      };
      try {
        await this.$crm_http.put(`/v1/user/${this.$route.params.id}/activate`,payload);
        this.$router.push("/user-tidak-aktif");
      } catch (err) {
        this.message = err.response.data.data;
        this.isPopup = true;
        this.isLoading = false;
      }
      // try {
      //   await this.$crm_http.put("/v1/user/active/" + this.formData.id);
      //   setTimeout(() => {
      //     this.$router.push("/user-tidak-aktif");
      //   }, 2000);
      // } catch (err) {
      //   this.message = err.response.data.data;
      //   this.isPopup = true;
      //   this.isLoading = false;
      // }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getUserNonActive();
  },
  // toggleColor() {
  //   // Ketika toggle diaktifkan, tampilkan modal
  //   if (this.toggleValue) {
  //     this.modalVisible = true;
  //   }
  // },
  // cancelActivation() {
  //   this.toggleValue = false;
  //   this.modalVisible = false;
  // },
  // confirmActivation() {
  //   this.modalVisible = false;
  // },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #162ca2;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.satu {
  font-size: small; /* Ukuran font kecil */
  color: blue; /* Warna tulisan biru */
}

.outer-container {
  padding: 50px 70px 100px 70px;
  background-color: #f7f9fd;
}

.container-box {
  padding: 15px 40px; /* Atas dan bawah 15px, Kanan dan kiri 40px */
  border: 1px solid #ffffff;
  border-radius: 30px;
}

.btn-cancel {
  border-radius: 10px;
  border: 1px solid #acafbf;
  padding: 10px 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #acafbf;
}

.btn-save {
  border-radius: 10px;
  background-color: #2d46cf;
  border: 1px solid #2d46cf;
  padding: 10px 36px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.submit-box {
  display: flex;
  justify-content: flex-end; /* Untuk membuat tombol-tombol berada di sebelah kanan */
}

.submit-box v-btn {
  margin-right: 10px; /* Jarak margin kanan antar tombol */
}

.submit-box v-btn:nth-child(1) {
  background-color: blue; /* Warna biru untuk tombol pertama */
}

.submit-box v-btn:nth-child(2) {
  background-color: grey; /* Warna abu-abu untuk tombol kedua */
}

.cart-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

h1 {
  text-align: center;
  margin-bottom: 40px;
  padding: 20px;
  font-size: 24px;
  color: #333;
}

.form-ctn {
  display: flex;
  flex-direction: column;
}

.content-form {
  margin-top: 24px;
}

.content-form p {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  font-family: "Poppins", sans-serif;
  color: #36373f;
}

.form-area {
  width: 100%;
  height: 140px;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  padding: 15px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #575966;
}

.form-input {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  padding: 15px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #575966;
}

.form-radio {
  margin-right: 22px;
  color: #36373f;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
}

.submit-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[color="blue"] {
  background-color: #007bff;
  color: white;
}

button[color="grey"] {
  background-color: #6c757d;
  color: white;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.content-form {
  margin-bottom: 16px;
}

.phone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-input {
  width: 100%;
}
</style>
