<template>
    <div>
        <Popup :message="message" :isError="isError" v-if="isPopup" @close="() => (isPopup = false)" />
        <Loading v-if="isLoading" />
        <Modal v-if="isModalInfo" @close="() => (isModalInfo = false)" width="60%">
            <div class="inner-container-modal-detail">
                <div class="container-title-modal-detail">
                    <h1>Panduan Variabel Template Email</h1>
                </div>
                <div style="text-align: left; margin-top: 10px;">
                    <div class="section">
                        <h2>1️⃣ Apa Itu Variabel Template?</h2>
                        <p>Variabel ini berfungsi untuk menggantikan placeholder dengan data spesifik saat email
                            dikirim. Misalnya, <code v-html="'{{ .1 }}'"></code> akan diisi dengan nama penerima.</p>
                    </div>

                    <div class="section">
                        <h2>2️⃣ Cara Menggunakan:</h2>
                        <ul>
                            <li>Ketikkan teks email seperti biasa.</li>
                            <li>Gunakan format <code v-html="'{{ .n }}'"></code> (contoh: <span
                                    v-html="'{{ .1 }}'"></span>, <span v-html="'{{ .2 }}'"></span>) pada bagian yang
                                ingin diisi secara dinamis.</li>
                            <li>Setiap angka mewakili data yang akan Anda masukkan.</li>
                        </ul>
                    </div>

                    <div class="section">
                        <h2>3️⃣ Contoh:</h2>
                        <p><strong>Template:</strong></p>
                        <div class="code-block">
                            Hello <span v-html="'{{ .1 }}'"></span>,<br>
                            Selamat kamu telah memenangkan <span v-html="'{{ .2 }}'"></span>.
                        </div>
                        <p><strong>Hasil:</strong></p>
                        <div class="code-block">
                            Hello John Doe,<br>
                            Selamat kamu telah memenangkan Hadiah Utama.
                        </div>
                    </div>

                    <div class="section">
                        <h2>4️⃣ Tips:</h2>
                        <div class="tips">
                            <p>✅ Pastikan nomor variabel sesuai dengan data yang akan Anda isi.</p>
                            <p>✅ Variabel tidak boleh kosong untuk memastikan email terlihat profesional.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <Modal v-if="isModalConfirm" @close="() => (isModalConfirm = false)" width="40%">
      <h1 class="modal-title">Alert!</h1>
      <p>Are you sure you want to continue?</p>
      <div style="display: flex; justify-content: center; gap: 15px;">
        <button @click="isModalConfirm = false" class="btn-cancel">Cancel</button>
        <button @click="sendBlast" class="btn-save">Confirm</button>
      </div>
        </Modal>
        <Modal v-if="isModalDelete" @close="() => (isModalDelete = false)" width="40%">
      <h1 class="modal-title">Alert!</h1>
      <p>Are you sure to delete this repository?</p>
      <div style="display: flex; justify-content: center; gap: 15px;">
        <button @click="isModalDelete = false" class="btn-cancel">Cancel</button>
        <button @click="onDeleteData" class="btn-save" style="background-color: red;">Confirm</button>
      </div>
        </Modal>
        <Modal width="40%" v-if="isModalTest" @close="() => (isModalTest = false)">
            <div class="inner-container-modal-detail">
                <div class="container-title-modal-detail">
                    <h1>Create A/B Test</h1>
                </div>
                <div class="container-detail-modal-detail">
                    <div class="form-group">
                        <label for="fromName">To email *</label>
                        <input type="text" id="fromName" v-model="formTest.recipient_email" class="input-field">
                    </div>
                    <div v-if="formTemplate.category !== 'IMAGE'"
                        style="border-top: 1px solid gray; padding-top: 30px;">
                        <div class="form-group" v-for="(data, idx) in formTest.params" :key="idx"
                            style="margin-top: -10px;">
                            <label>Variabel {{ idx + 1 }} *</label>
                            <input type="text" v-model="formTest.params[idx]" class="input-field">
                        </div>
                    </div>
                    <div style="display: flex; justify-content: center; gap: 8px;">
                        <button @click="isModalTest = false" class="btn-cancel">Cancel</button>
                        <button @click="sendTestAb()" class="btn-save">
                            Save and Send
                        </button>

                    </div>
                </div>
            </div>
        </Modal>
        <nav class="navbar">
            <div class="navbar-container">
                <div class="navbar-actions">
                    <button class="btn-preview" @click="$router.push('/blast-template')">Exit</button>
                </div>

                <div class="ctn-head-edit">
                    <div v-if="!isEditing" style="flex-grow: 1;">
                        <span class="navbar-brand">{{ formTemplate.name }}</span>
                    </div>
                    <div v-if="isEditing" style="flex-grow: 1;">
                        <input type="text" v-model="formTemplate.name"
                            style="width: 100%; padding: 5px; color: white;" />
                    </div>
                    <div style="cursor: pointer; margin-left: 10px;" @click="toggleEdit">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24"
                            aria-hidden="true" title="Edit Title">
                            <path
                                d="M14.06 3.02c.16-.16.37-.24.59-.24.22 0 .43.08.59.24l5.94 5.94c.32.32.32.86 0 1.18l-9.17 9.17c-.15.15-.35.25-.59.25H6.25c-.46 0-.83-.37-.83-.83v-4.58c0-.23.09-.43.25-.59l9.19-9.18Zm-3.93 13.16h-.96v.96h.96v-.96Zm7.7-7.7-1.1-1.1-8.2 8.2v1.1h1.1l8.2-8.2Zm-2.18-2.18-1.1-1.1-8.2 8.2v1.1h1.1l8.2-8.2Z">
                            </path>
                        </svg>
                    </div>
                </div>
                <div class="navbar-actions">
                    <button class="btn-test" @click="openAbTest">A/B test</button>
                    <button class="btn-save" @click="isModalConfirm = true">Save and blast</button>
                </div>
            </div>
        </nav>

        <div class="tabs">
            <button v-for="(data, idx) in menus" :key="idx" :class="`tab ${data === select ? 'active' : ''}`"
                @click="select = data">{{ data }}</button>
        </div>
        <div class="container" v-if="select === 'Edit'">

            <h2 class="title">Edit Template</h2>
            <div class="form-group">
                <label for="fromName">Category Body</label>

                <v-select class="form-select" :items="menuCategory" :item-text="'name'" :item-value="'value'"
                    v-model="formTemplate.category" outlined>
                </v-select>
            </div>
            <div class="form-group" v-if="formTemplate.category === 'TEXT'">
                <div style="display: flex; justify-content: space-between;">
                    <label>Body email</label>
                    <span class="note-file cursor-pointer" @click="isModalInfo = true">Click for guide</span>
                </div>
                <vue-editor v-model="formTemplate.content" :editorToolbar="$helpers.customToolBar()"
                    class="form-input"></vue-editor>

            </div>
            <div v-else>
                <div class="form-group">
                    <label>Body email</label>
                    <v-file-input v-model="img_web_second" accept=".xls,.xlsx" placeholder="Unggah Berkas Gambar"
                        class="form-upload" prepend-icon="" outlined>
                        <template v-slot:prepend-inner>
                            <ImageModal :src="formTemplate.content" width="76.31" height="37" radius="3" />
                        </template>
                        <template v-slot:append>
                            <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
                        </template>
                    </v-file-input>
                    <p class="note-file">
                        *Ukuran file maks 1 MB
                    </p>
                </div>
                <div class="form-group">
                    <label for="redirectUrl">Redirect Url</label>
                    <input type="text" id="redirectUrl" v-model="formTemplate.redirect_url"
                        class="input-field cursor-block">
                </div>
            </div>
        </div>
        <div class="container" v-if="select === 'Settings'">
            <h2 class="title">Email settings</h2>
            <!-- From Name -->
            <div class="form-group">
                <label for="fromName">From name *</label>
                <input type="text" id="fromName" v-model="formBlast.sender_name" disabled
                    class="input-field cursor-block">
            </div>
            <!-- From Address -->
            <div class="form-group">
                <label for="fromAddress">From address *</label>
                <input type="email" id="fromAddress" v-model="formBlast.sender_email" disabled
                    class="input-field cursor-block">
                <p class="helper-text">
                    To improve deliverability, this address will be <b>modified and sent from a HiColleagues domain.</b>
                </p>
            </div>
            <div class="form-group">
                <label for="subjectLine">Subject line *</label>
                <textarea name="subjectLine" id="subjectLine" v-model="formTemplate.subject"
                    style="width: 100%; border: 1px solid gray;" rows="3"></textarea>
            </div>
            <div class="form-group">
                <label for="previewText">Preview Text</label>
                <vue-editor name="previewText" id="previewText" v-model="formTemplate.description"
                    :editorToolbar="$helpers.customToolBar()" class="form-input"></vue-editor>
            </div>
            <!-- Language -->
            <div class="form-group">
                <label for="language">Language</label>
                <select id="language" name="language">
                    <option value="english" selected>Bahasa</option>
                </select>
                <div class="info-text" style="margin-top: -5px;">
                    Only default HiColleagues modules and text will be translated for supported.
                </div>
            </div>

            <!-- Subscription Type -->
            <div class="form-group">
                <label for="subscription">Blast type *</label>
                <select id="subscription" name="subscription">
                    <option value="marketing" selected>Email</option>
                </select>
            </div>

            <!-- Office Location -->
            <div class="form-group">
                <label for="office">Office location</label>
                <div class="info-text">Footer module will be updated in email</div>
                <button type="button">Coming Soon</button>
            </div>
            <div class="repository-section">
  <h4>Delete this repository</h4>
  <p>Once you delete a repository, there is no going back. Please be certain.</p>
  <button class="delete-button" @click="isModalDelete = true">Delete this repository</button>
</div>
        </div>
        <div class="container-send" v-if="select === 'Send or Schedule'">
            <div class="column" style="flex: 2">
                <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
                    <h2 style="margin: 0;">Recipients</h2>
                    <button class="btn-save" style="width: 250px;" @click="downloadExcel">Download Template</button>
                </div>
                <div class="section">
                    <label>Send to</label>
                    <v-file-input v-model="file" @change="handleFileUpload" accept="xls,xlsx/*" placeholder="Unggah Berkas Leads"
                        class="form-upload" prepend-icon="" outlined>
                        <template v-slot:append>
                            <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
                        </template>
                    </v-file-input>

                    <label for="exclude">Don't send to</label>
                    <v-select
                    :disabled="!isIgnored"
    v-model="blackListData"
    :items="blastData"
    multiple
    chips
    persistent-hint
    item-text="field_1"
    item-value="filed_1"
  ></v-select>

                    <div class="checkbox-container">
                        <input type="checkbox" id="unengaged" v-model="isIgnored">
                        <label for="unengaged">Don't send to unengaged contacts</label>
                    </div>
                </div>
            </div>
            <div class="column"
                style="box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); padding: 15px 24px; border-radius: 16px;">
                <h2>Sending Options</h2>

                <div class="section">
                    <div style="display: flex; align-items: center; gap: 50px;">
                        <div class="checkbox-container">
                            <input type="radio" name="send-option" v-model="sendNow" id="send-now" :value="true">
                            <label for="send-now">Send now</label>
                        </div>
                        <div class="checkbox-container">
                            <input type="radio" name="send-option" v-model="sendNow" id="schedule-later" :value="false">
                            <label for="schedule-later">Schedule for later</label>
                        </div>
                    </div>
                    <div v-if="!sendNow">
                        <label for="date">Date</label>
                        <input type="date" id="date" v-model="dateSend">

                        <label for="time">Time</label>
                        <input type="time" id="time" v-model="timeSend">
                        <p class="helper-text" style="margin-top: -5px">This will be {{ timeSend }} in your current time
                            zone.
                        </p>
                    </div>
                    <div class="time-zone-options">
                        <label>What time zone do you want to use?</label>
                        <div class="checkbox-container">
                            <input type="radio" name="time-zone" id="default-zone" checked>
                            <label for="default-zone">Jakarta (UTC+07:00)</label>
                        </div>
                    </div>
                    <hr>
                    <div class="estimate">
                        <h2>Estimated recipients</h2>
                        <div class="estimated-value">{{ pagination.current_blast }} <span
                                style="font-size: 18px; font-weight: normal;">out of</span> {{ pagination.totalData }}
                        </div>
                        <div class="subtext"><span style="float: right;">0-100 sent/perhour</span></div>
                        <div class="progress-bar">
                            <div class="progress"
                                :style="`width: ${calculateProgress(pagination.current_blast, pagination.totalData)}%`">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-send" style="display: block;" v-if="select === 'History'">
            <h2>History email</h2>

            <v-tabs bg-color="deep-purple-darken-4" center-active v-if="batchs.length" v-model="activeBatch">
                <v-tab v-for="(tab, idx) in batchs" :key="idx"  @click="changeTab(tab)">{{ tab ? 'Batch ' + tab : 'A/B Test'}}</v-tab></v-tabs>

            <v-data-table :headers="headers" :items="emailBlasts" class="elevation-1 mt-10 table" hide-default-footer>
                <template v-slot:item.status="{ item }">
                    <v-chip :color="item.status ? 'green' : '#FFBF00'" dark>
                        {{ item.status ? "Success" : "Scheduled" }}
                    </v-chip>
                </template>
                <template v-slot:item.sent_at="{ item }">
                    {{ $helpers.getDateFull(item.sent_at) }}
                </template>
            </v-data-table>
            <div class="data-count" v-if="pagination.totalData">
                <span><b>{{ pagination.page }} dari {{ pagination.total }} </b> page data yang tertampil</span>
            </div>
            <div style="max-width: 700px; margin: auto;" v-if="pagination.totalData">
                <v-pagination v-model="pagination.page" class="my-4" :length="pagination.total"
                    @input="getBlastEmailByTemplateId"></v-pagination>
            </div>
            <NoDataAlert :totalData="pagination.total" color="" />
        </div>
    </div>
</template>

<script>
import NoDataAlert from "@/components/NoDataAlert.vue";
import moment from "moment";
import { VueEditor } from "vue2-editor";
import ImageModal from "@/components/ImageModal.vue";
import Popup from "@/components/others/Popup.vue";
import Loading from "@/components/ilustration/Loading.vue";
import Modal from "@/components/Modal.vue";
import XLSX from 'xlsx'

export default {
    name: "BlastTemplateForm",
    components: {
        VueEditor, ImageModal, NoDataAlert, Loading, Popup, Modal
    },
    data() {
        return {
            menus: ["Edit", "Settings", "Send or Schedule", "History"],
            select: "Edit",
            isError: true,
            isPopup: false,
            message: "",
            isLoading: false,
            isModalConfirm: false,
            isModalTest: false,
            isModalDelete: false,
            isEditing: false,
            isModalInfo: false,
            id: this.$route.params.id,
            img_web_second: null,
            activeBatch: 0,
            file: null,
            batchs: [],
            headers: [
                { text: "ID", value: "id", width: "80" },
                { text: "Recipient Email", value: "recipient_email", width: "280" },
                { text: "Recipient Name", value: "recipient_name", width: "250" },
                { text: "Status", value: "status", width: "150" },
                { text: "Sent At", value: "sent_at", width: "250" },
            ],
            historyData: [],
            menuCategory: [
                {
                    name: "Text Plant",
                    value: "TEXT"
                },
                {
                    name: "Only Image",
                    value: "IMAGE"
                }
            ],
            isIgnored: false,
            sendNow: false,
            dateSend: moment(new Date()).format("YYYY-MM-DD"),
            timeSend: moment(new Date()).format("HH:mm"),
            blackListData: [],
            emailBlasts: [],
            blastData:[],
            formTest: {
                recipient_email: "",
                recipient_name: "Test AB",
                subject: "",
                params: [],
                sender_email: "",
                email_template_id: 0,
            },
            formTemplate: {
                name: "",
                subject: "",
                description: "",
                content: "",
                category: "TEXT",
                redirect_url: ""
            },
            formBlast: {
                sender_email: "hello@hicolleagues.com",
                sender_name: "Hello Hicolleagues"
            },
            pagination: {
                page: 1,
                per_page: 10,
                total: 0,
                totalData: 0,
                current_blast: 0
            }
        }
    },
    methods: {
        async getBlastTemplateById() {
            this.isLoading = true
            await this.$crm_http.get(`/v1/email-template/${this.id}`)
                .then((response) => {
                    this.formTemplate = response.data.data.email_template
                    this.isLoading = false
                })
                .catch((err) => {
                    this.isError = true
                    this.isPopup = true
                    this.isLoading = false
                    this.message = err.response.data.data
                });
        },
        async onDeleteData() {
            this.isLoading = true
            await this.$crm_http.delete(`/v1/email-template/${this.id}`)
                .then(() => {
                    this.$router.push('/blast-template')
                    this.isLoading = false
                })
                .catch((err) => {
                    this.isError = true
                    this.isLoading = false
                    this.isPopup = true
                    this.message = err.response.data.data
                });
        },
        async getBlastEmailByTemplateId() {
            this.isLoading = true
            await this.$crm_http.get(`/v1/email-blasting?email_template_id=${this.id}&batch=${this.activeBatch}&page=${this.pagination.page}&per_page=${this.pagination.per_page}`)
                .then((response) => {
                    this.emailBlasts = response.data.data.email_blastings ?? []
                    const t = parseInt(response.data.data.total);
                    this.pagination.total = Math.ceil(t / this.pagination.per_page);
                    this.pagination.totalData = response.data.data.total;
                    this.pagination.current_blast = response.data.data.current_blast;
                    this.isLoading = false
                })
                .catch((err) => {
                    this.isError = true
                    this.isLoading = false
                    this.isPopup = true
                    this.message = err.response.data.data
                });
        },
        async getBatchListByTemplateId() {
            this.isLoading = true
            await this.$crm_http.get(`/v1/email-blasting/batch-list/${this.id}`)
                .then((response) => {
                    this.batchs = response.data.data ?? []
                    this.isLoading = false
                    if (!this.batchs.length)  return
                    this.activeBatch = this.batchs[this.batchs.length-1]
                })
                .catch((err) => {
                    this.isError = true
                    this.isLoading = false
                    this.isPopup = true
                    this.message = err.response.data.data
                });
        },
        async updateTemplate() {
            this.isLoading = true
            try {
                if (this.formTemplate.category !== 'IMAGE') this.formTemplate.redirect_url = ""
                await this.$crm_http.put(`/v1/email-template/${this.id}`, this.formTemplate)
                this.isLoading = false
            } catch (err) {
                this.isError = true
                this.isLoading = false
                this.isPopup = true
                this.message = err.response.data.data
            }
            this.isModalTest = false
        },
        async sendTestAb() {
            await this.updateTemplate()
            this.isLoading = true
            this.formTest.subject = this.formTemplate.subject
            this.formTest.sender_email = this.formBlast.sender_email
            this.formTest.email_template_id = Number(this.id)
            try {
                await this.$crm_http.post(`/v1/email-blasting`, this.formTest)
                this.isLoading = false
                this.isError = false
                this.isPopup = true
                this.message = "A/B test successfully sent"
                await this.getBatchListByTemplateId()
                this.select = "History"
                this.activeBatch = 0
                this.pagination.page = 1
                this.getBlastEmailByTemplateId()
            } catch (err) {
                this.isLoading = false
                this.isError = true
                this.isPopup = true
                this.message = err.response.data.data
            }
            this.isModalTest = false
        },
        async sendBlast() {
            if (!this.blastData.length) {
                this.isError = true
                this.isPopup = true
                this.message = "fill in the recipient first!"
                return
            }
            if (this.sendNow) {
                this.dateSend = moment(new Date()).format("YYYY-MM-DD")
                this.timeSend = moment(new Date()).format("HH:mm")
            }
            this.blastData = this.blastData.filter(item => !this.blackListData.includes(item.field_1));
            if (!this.blastData.length) {
                this.isError = true
                this.isPopup = true
                this.message = "recipient is empty"
                return
            }
            const request = this.blastData.map((item,idx) => {
                return {
                    recipient_email: item.field_1,
                    recipient_name: item.field_2,
                    subject: this.formTemplate.subject,
                    sender_email: this.formBlast.sender_email,
                    email_template_id: Number(this.id),
                    sent_at: this.dateSend + " " + this.timeSend,
                    params: this.blastData.map(item => Object.values(item).slice(2))[idx]
                }
            })
            await this.updateTemplate()
            this.isLoading = true
            try {
                await this.$crm_http.post(`/v1/email-blasting/bulk`, request)
                this.isLoading = false
                this.isError = false
                this.isPopup = true
                this.message = "Blasting successfully sent"
                this.blastData = []
                this.blackListData = []
                this.isIgnored = false
                this.file = null
                this.select = "History"
                this.pagination.page = 1
                this.isModalConfirm = false
                await this.getBatchListByTemplateId()
                await this.getBlastEmailByTemplateId()
            } catch (err) {
                this.isLoading = false
                this.isError = true
                this.isPopup = true
                this.isModalConfirm = false
                this.message = err.response.data.data
            }
        },
        calculateProgress(current, total) {
            if (total === 0) return 0; // Menghindari pembagian dengan nol
            return (current / total) * 100;
        },
        openAbTest() {
            this.isModalTest = true
            this.formTest.sender_email = ''
            if (this.formTemplate.category !== 'IMAGE') {
                this.findParamsInput()
            }
        },
        
        toggleEdit() {
            this.isEditing = !this.isEditing;
        },
        changeTab(batch) {
            this.activeBatch = batch
            this.getBlastEmailByTemplateId()
        },
        downloadExcel() {
            this.findParamsInput()
            const columns = [
                { "Recipent Email": "", "Recipent Name": "" },
            ];
            this.formTest.params.forEach((item, idx) => {
                columns[0]["Variable " + (idx + 1)] = ""
            });
            const ws = XLSX.utils.json_to_sheet(columns);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'Blasting Email.xlsx');

        },

        findParamsInput() {
            const matches = this.formTemplate.content.match(/{{ \.\d+ }}/g);
            const totalSymbols = matches ? matches.length : 0;
            const resultArray = Array(totalSymbols).fill("");
            this.formTest.params = resultArray
        },
        handleFileUpload() {
            if (!this.file) return;
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                if (!jsonData.length) return
                 const fileUpload = jsonData.slice(1);
                 this.blastData = fileUpload.map(row => {
                    return row.reduce((obj, value, index) => {
                        obj[`field_${index + 1}`] = value; // Membuat key dinamis seperti field_1, field_2, dll.
                        return obj;
                    }, {});
                });
                this.pagination.totalData+=this.blastData.length
            };
            reader.readAsArrayBuffer(this.file);
        },
        async setupCallData() {
            this.getBlastTemplateById()
            await this.getBatchListByTemplateId()
            this.getBlastEmailByTemplateId()
        }
    },

    mounted() {
        this.setupCallData()
    }
}
</script>

<style scoped>
.navbar {
    background-color: #222;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.repository-section {
  padding: 16px;
  border: 1px solid red; /* Border color */
  border-radius: 8px; /* Rounded corners */
  margin: 16px 0;
  width: 100%;
}

.repository-section h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.repository-section p {
  font-size: 14px;
  color: #8b949e; /* Subtle text color */
  margin-bottom: 16px;
}

.delete-button {
  background-color: #da3633; /* Red button color */
  color: white;
  border: none;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #c62c2a; /* Darker red on hover */
}

.delete-button:active {
  background-color: #a62321; /* Even darker red on click */
}

.tabs-table {
    display: flex;
    margin-bottom: 10px;
}

.tab-button-table {
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.tab-button-table.active {
    background-color: #007bff;
    color: white;
}

.data-count {
    margin-top: 20px;
    text-align: right;
}

.btn-cancel {
    border-radius: 10px;
    border: 1px solid #acafbf;
    padding: 10px 36px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #acafbf;
}

.btn-save {
    border-radius: 10px;
    background-color: #2d46cf;
    border: 1px solid #2d46cf;
    padding: 10px 36px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}

.form-input {
    border-radius: 14px !important;
}

.cursor-block {
    cursor: not-allowed;
}

.ctn-head-edit {
    display: flex;
    align-items: center;
    padding: 10px;
    color: white;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-brand {
    font-size: 18px;
    font-weight: bold;
}

.navbar-actions .btn-preview,
.navbar-actions .btn-save {
    padding: 8px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-preview {
    background-color: #444;
    color: #fff;
}

.btn-save {
    background-color: #f77c00;
    color: #fff;
}

.btn-test {
    padding: 8px 15px;
    margin-left: 10px;
    border: none;
    border-radius: 5px;

    cursor: pointer;
    border: 1px solid #f77c00;
}

.btn-preview:hover {
    background-color: #555;
}

.btn-save:hover {
    background-color: #e06c00;
}

/* Tabs */
.tabs {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ddd;
    margin: 20px 0;
    background-color: #fff;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 14px;
    border-bottom: 3px solid transparent;
    transition: 0.3s;
}

.tab:hover {
    background-color: #f9f9f9;
}

.tab.active {
    font-weight: bold;
    border-bottom: 3px solid #007bff;
    color: #007bff;
}

/* Form Container */
.container {
    background-color: #ffffff;
    width: 600px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: auto;
}

/* Form Group */
.form-group {
    margin-bottom: 20px;
}

label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.input-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.input-field:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 3px rgba(0, 123, 255, 0.25);
}

.helper-text {
    font-size: 12px;
    color: #6c757d;
    margin-top: 5px;
}

.link {
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
}

.checkbox-label {
    font-size: 14px;
    margin-left: 5px;
}

.input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.icon-container {
    position: absolute;
    right: 10px;
    display: flex;
    gap: 5px;
}

.icon {
    font-size: 18px;
    cursor: pointer;
}

.icon:hover {
    color: #007bff;
}


/* Form Container */
.container-send {
    max-width: 1300px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.column {
    flex: 1;
}

h2 {
    margin-bottom: 50px;
    color: #333;
}

.section {
    margin-bottom: 30px;
    gap: 50px;
}


.section label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.section select,
.section input[type="text"],
.section input[type="date"],
.section input[type="time"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.form-group select,
.form-group input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

input[type="radio"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
}


.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.checkbox-container input {
    margin-right: 10px;
}

.checkbox-container label {
    font-size: 14px;
}

.time-zone-options {
    margin-top: 15px;
}

.time-zone-options input {
    margin-right: 10px;
}


.container-send button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
}

.container-send button:hover {
    background-color: #0056b3;
}

.estimate {
    background: #fff;
    border-radius: 8px;
    max-width: 400px;
    margin-top: 50px;
    width: 100%;
}

.estimate h2 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.estimated-value {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.subtext {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
}

.progress-bar {
    height: 10px;
    width: 100%;
    background: #eaeaea;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.progress-bar .progress {
    height: 100%;
    width: 0%;
    background: #007bff;
}

.form-group button:hover {
    background-color: #0056b3;
}

.info-text {
    font-size: 12px;
    color: #666;
    margin-top: 5px;
}

.info-text a {
    color: #007bff;
    text-decoration: none;
}

.info-text a:hover {
    text-decoration: underline;
}

.filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.filter-bar select,
.filter-bar input[type="text"] {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.filter-bar .advanced-filters {
    color: #007bff;
    font-size: 14px;
    cursor: pointer;
}

.table-container {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th,
td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f9f9f9;
    font-size: 14px;
}

td {
    font-size: 14px;
}

td .email-name {
    color: #007bff;
    cursor: pointer
}


.inner-container-modal-detail {
    padding: 16px;
}

.inner-container-modal-detail p {
    margin: 0;
    text-align: left;
}

.container-title-modal-detail {
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ACAFBF;
}

.container-title-modal-detail h1 {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    color: #36373F;
}

.container-detail-modal-detail {
    margin-top: 24px;
    text-align: left;
}

.note-file {
    font-weight: 400;
    font-size: 12px;
    color: #b80f0a;
    margin-bottom: 20px !important;
}

::v-deep .form-upload .v-input__slot {
    height: 62px !important;
    background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
    display: none;
}

.section {
    margin-bottom: 20px;
}

.section h2 {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
}

.section p {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
}

.code-block {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    font-family: monospace;
    font-size: 14px;
    color: #333;
    overflow-x: auto;
}

.tips {
    background-color: #eef9f4;
    border-left: 4px solid #4caf50;
    padding: 10px;
    border-radius: 4px;
}

.tips p {
    font-size: 14px;
    color: #333;
    margin: 0;
}

ul {
    padding-left: 20px;
    margin: 0;
}

ul li {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}
</style>