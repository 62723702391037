<template>
    <div class="ma-10">
      <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div style="display: flex; justify-content: space-between">
        <div>
          <div class="head-ctn">
            <h1>{{ dataClass.name }}</h1>
          </div>
          <div class="d-flex mt-3" style="gap: 20px">
            <span>Hari: {{ dataClass.days }}</span>
            <span>Jam: {{ dataClass.hours }}</span>
            <span><b>Sesi: {{ sequence }}</b></span>
          </div>
        </div>
        <h3 style="cursor: pointer" @click="$router.go(-1)">Kembali</h3>
      </div>
  
      <v-data-table
        :headers="headers"
        :items="attandence"
        class="elevation-1 mt-10 table"
        hide-default-footer
        disable-pagination
      >
      </v-data-table>
  
      <div class="data-count">
        <span>data yang tertampil <b> {{ totalData }}</b></span>
      </div>      
    </div>
  </template>
  
  <script>
  import Popup from "@/components/others/Popup.vue";

  export default {
    components: {Popup},
    data() {
      return {
        email: "",
        modal: false,
        dataClass: {},
        classId: Number(this.$route.params.id_class),
        sequence: Number(this.$route.params.sequence),
        currentPage: 1,
        perPage: 10,
        totalData: 0,
        value: "email",
        message: "",
        isPopup: false,
        total: 0,
        dialog: false,
        headers: [
          {
            text: "No",
            value: "no",
          },
          {text: "Nama User", value: "user_name"},
          {text: "Jam Masuk", value: "start_date"},
          {text: "Jam Keluar", value: "end_date"},
        ],
        attandence: [],
      };
    },
    methods: {
      async getAttandence() {
        await this.$lms_http
          .get(`/v1/attendance/${this.classId}/list?sequence=${this.sequence}`)
          .then(async (response) => {
            this.attandence = [];
            const list_data = response.data.data.attendances
            for (let i = 0; i < list_data.length; i++) {
              const data = list_data[i];
              const user = await this.getUser(data.user_id)
              this.attandence.push({
                no: i+1,
                user_name: user,
                ...data
              }); 
            }
            this.totalData = response.data.data.total;
        
          })
          .catch((err) => {
            this.message = err.response.data.data
            this.isPopup = true
          });
      },
      async getUser(id) {
        try {
          const response = await this.$crm_http.get(`/v1/user/${id}`);
          return response.data.data.user.full_name;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
          return ""
        }
      },
      async getClassId() {
        try {
          const resp = await this.$lms_http.get("/v1/class/" + this.classId);
          this.dataClass = resp.data.data.class;
        } catch (err) {
          this.message = err.response.data.data
          this.isPopup = true
        }
      },
      pageNo(item) {
        if (item) {
          return item.no;
        }
        return;
      }
    },
    mounted() {
      this.getAttandence();
      this.getClassId()
    },
  };
  </script>
  
  <style scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filter-box {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .filter-box.input * {
    width: 100%;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .input-group .label {
    font-size: larger;
    color: #000000;
  }
  
  .input-group.btn {
    margin-left: auto;
    justify-content: flex-end;
  }
  
  .dropdown {
    width: 70%;
    height: 7%;
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  
  .action-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .data-count {
    height: 0;
    position: relative;
    overflow-y: visible;
    display: flex;
  }
  
  .data-count span {
    position: absolute;
    font-size: medium;
    top: 35px;
  }
  </style>
  