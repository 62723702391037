<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <div style="width: 90%; margin: 30px 0;">
      <div class="menus-top">
        <span class="blue-text" @click="$router.go(-1)">Event</span>
        <Arrow :width="20" :height="20" direct="left" strokeColor="#7B7E8C" class="icon" />
        <span style="color: #7b7e8c">{{ name }}</span>
      </div>

      <div class="card-form-head">
        <h1>
          Form {{ name }}
        </h1>
        <div class="card-form-head-bdy">
          <p>
            Status {{ form.is_active ? "aktif" : " tidak aktif" }}
          </p>
          <label class="switch">
            <input v-model="form.is_active" type="checkbox" />
            <span class="slider"></span>
          </label>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2">
          <h1>
            Informasi Umum Program
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Nama Event</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.name" class="form-input"
              placeholder="Masukan Nama Event" dense clearable :rules="[!!form.name || 'Wajib diisi!']"></v-text-field>
          </div>
          <div class="content-form">
            <p>Slug Name</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.slug_name" class="form-input"
              placeholder="Masukan Nama Event" dense clearable :rules="[!!form.slug_name || 'Wajib diisi!']"></v-text-field>
          </div>
          <div class="content-form">
            <p>Batch Series</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.batch" class="form-input" type="number"
              placeholder="Batch Series" dense clearable></v-text-field>
          </div>
          <div class="content-form">
            <p>Level Pelatihan</p>
            <v-select :disabled="isWatch" class="form-input" :items="levelList" v-model="form.level" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form">
            <p>DiLengkapi LMS</p>
            <v-select :disabled="isWatch" class="form-input" :items="lmsConfirmList" v-model="form.is_lms_compatible" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form">
            <p>Metode Pelaksanaan</p>
            <v-select :disabled="isWatch" class="form-input" :items="methods" v-model="form.method" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form" v-if="form.method === 'Online'">
            <p>Link Pelaksanaan Event</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.learning_url" class="form-input"
              placeholder="Gmeet, zoom, or other" dense clearable></v-text-field>
          </div>
          <div v-else>
            <div class="content-form">
              <p>Nama Lokasi Pelaksanaan</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.location_name" class="form-input"
                placeholder="Masukkan Nama Lokasi Pelaksanaan" dense clearable></v-text-field>
            </div>
            <div class="content-form">
              <p>Alamat Lokasi Pelaksanaan</p>
              <v-textarea placeholder="Masukkan Alamat Lokasi Pelaksanaan" :disabled="isWatch" outlined
                v-model="form.location_address" rows="3" clearable class="form-input"></v-textarea>
            </div>
            <div class="content-form">
              <p>URL Link Maps Lokasi Pelaksanaan</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.location_map" class="form-input"
                placeholder="Masukkan URL Link Maps" dense clearable></v-text-field>
            </div>
          </div>
          <div class="content-form">
            <p>Narasumber</p>
            <div v-for="(item, idx) in form.instructors" :key="idx" class="session-nb-3">
              <div @click="addNewInsturctor" style="cursor: pointer" v-if="form.instructors.length - 1 === idx">
                <add-icon />
              </div>
              <v-autocomplete :label="`Pilih narasumber ${idx + 1}`" outlined v-model="form.instructors[idx]"
                :items="instructors" class="form-input mt-2 no-space" dense :item-text="'name'"
                :item-value="'id'"></v-autocomplete>
              <div @click="form.instructors.splice(idx, 1)" v-if="idx != 0" style="cursor: pointer">
                <trash :width="38" :height="38" />
              </div>
            </div>
          </div>
          <v-row class="mt-5">
            <v-col cols="6">
              <div class="content-form ">
                <p>Kuota Peserta</p>
                <v-select :disabled="isWatch" class="form-input" :items="typeLimitEvent" v-model="limitOfEvent" solo
                  :item-text="'name'" :item-value="'value'">
                </v-select>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="content-form">
                <p>Batas Kuota</p>
                <v-text-field :disabled="!limitOfEvent" outlined v-model="form.participant_quota" class="form-input"
                  type="number" min="1" dense clearable></v-text-field>
              </div>
            </v-col>
          </v-row>
          <div class="content-form mt-5">
            <p>Deskripsi Singkat Thumbnail</p>
            <v-textarea placeholder="Tulis caption ...." :disabled="isWatch" outlined rows="3" v-model="form.caption"
              clearable class="form-input"></v-textarea>
          </div>
          <div class="content-form ">
            <p>Deskripsi Event</p>
            <vue-editor v-model="form.description" :editorToolbar="$helpers.customToolBar()" class="form-input"
              placeholder="Tulis Deskripsi ...." v-if="!isWatch"></vue-editor>
            <div v-else class="watch-ctn">
              <div v-html="form.description"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Berkas Gambar (Website Desktop)
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Banner Thumbnail</p>
            <span class="alert-info">Rekomendasi dimensi file 400 x 200 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.desktop.thumbnail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.desktop.thumbnail == null">
                <ImageModal :src="form.img_urls.desktop.thumbnail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Detail Produk</p>
            <span class="alert-info">Rekomendasi dimensi file 1.600 x 420 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.desktop.product_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.desktop.product_detail == null">
                <ImageModal :src="form.img_urls.desktop.product_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Rincian Pembelian</p>
            <span class="alert-info">Rekomendasi dimensi file 200 x 200 px, kapasitas maksimal 1
              MB</span>
            <v-file-input v-model="imgModel.desktop.purchase_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.desktop.purchase_detail == null">
                <ImageModal :src="form.img_urls.desktop.purchase_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Berkas Gambar (Website Mobile)
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Banner Thumbnail</p>
            <span class="alert-info">Rekomendasi dimensi file 400 x 200 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.mobile.thumbnail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.mobile.thumbnail == null">
                <ImageModal :src="form.img_urls.mobile.thumbnail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Detail Produk</p>
            <span class="alert-info">Rekomendasi dimensi file 1.600 x 420 px, kapasitas maksimal 1
              MB</span>

            <v-file-input v-model="imgModel.mobile.product_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.mobile.product_detail == null">
                <ImageModal :src="form.img_urls.mobile.product_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
          <div class="content-form mt-3">
            <p>Banner Rincian Pembelian</p>
            <span class="alert-info">Rekomendasi dimensi file 200 x 200 px, kapasitas maksimal 1
              MB</span>
            <v-file-input v-model="imgModel.mobile.purchase_detail" accept="image/*" placeholder="Unggah Berkas Gambar"
              class="form-upload" prepend-icon="" outlined :disabled="isWatch">
              <template v-slot:prepend-inner v-if="imgModel.mobile.purchase_detail == null">
                <ImageModal :src="form.img_urls.mobile.purchase_detail" width="76.31" height="37" radius="3" />
              </template>
              <template v-slot:append>
                <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
              </template>
            </v-file-input>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Jadwal Pelaksanaan
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Sesi Pelaksanaan</p>
            <v-select :disabled="isWatch" class="form-input" :items="listSessionSchedule" v-model="sessionSchedule" solo
              @change="checkScheduleType" :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>

            <div v-for="(item, idx) in form.session_schedules" :key="idx">
              <div v-if="sessionSchedule === 2" class="session-numb">
                <p style="font-weight: bold">Sesi {{ idx + 1 }}</p>
                <div @click="form.session_schedules.splice(idx, 1)" style="cursor: pointer" v-if="idx != 0">
                  <strip />
                </div>
              </div>
              <div class="content-form">
                <p>Hari & Tanggal Pelaksanaan</p>
                <div class="container-date pt-2" v-if="sessionSchedule === 1">
                  <div class="container-date-left">
                    <v-row no-gutters>
                      <v-col cols="3" class="pt-2"> Mulai </v-col>
                      <v-col cols="8">
                        <v-text-field :disabled="isWatch" outlined type="date"
                          :rules="[!!item.date_start || 'Wajib diisi!']" v-model="item.date_start" class="form-input"
                          dense></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="container-date-rigth">
                    <v-row no-gutters>
                      <v-col cols="3" class="pt-2"> Selesai </v-col>
                      <v-col cols="8">
                        <v-text-field :disabled="isWatch" outlined type="date"
                          :rules="[!!item.date_end || 'Wajib diisi!']" v-model="item.date_end" class="form-input"
                          dense></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-text-field v-else :disabled="isWatch" outlined type="date"
                  :rules="[!!item.date_start || 'Wajib diisi!']" v-model="item.date_start" class="form-input"
                  dense></v-text-field>
              </div>
              <div class="content-form">
                <p>Jam Pelaksanaan</p>
                <div class="container-date pt-2">
                  <div class="container-date-left">
                    <v-row no-gutters>
                      <v-col cols="3" class="pt-2"> Mulai </v-col>
                      <v-col cols="8">
                        <v-text-field :disabled="isWatch" outlined type="time"
                          :rules="[!!item.start_time || 'Wajib diisi!']" v-model="item.start_time" class="form-input"
                          dense></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="container-date-rigth">
                    <v-row no-gutters>
                      <v-col cols="3" class="pt-2"> Selesai </v-col>
                      <v-col cols="8">
                        <v-text-field :disabled="isWatch" outlined type="time"
                          :rules="[!!item.end_time || 'Wajib diisi!']" v-model="item.end_time" class="form-input"
                          dense></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>
            <div class="add-button" @click="addSessionSchedlue" v-if="sessionSchedule === 2 && !isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Sesi</div>
              <div class="line"></div>
            </div>
          

        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Materi Silabus
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Informasi Materi Silabus?</p>
            <div class="spacing-switch">
              <v-switch v-model="form.isSyllabuses" color="primary" hide-details style="margin-top: -5px" ></v-switch>
            </div>
          </div>
          <div class="content-form mt-5" v-if="form.isSyllabuses">
            <p>Daftar Materi Event</p>
            <div class="list-event" v-for="(item, idx) in form.syllabuses" :key="idx">
              <div class="icon-plus" @click="addSubSession(idx)">
                <add-icon />
              </div>
              <b>Sesi {{ idx + 1 }}</b>
              <div v-for="(item2, idx2) in item" :key="idx2" class="session-nb-3">
                <v-text-field :disabled="isWatch" outlined v-model="form.syllabuses[idx][idx2]"
                  class="form-input mt-2 no-space" dense clearable></v-text-field>
                <div @click="onRemoveEvent(idx, idx2)" style="cursor: pointer">
                  <trash :width="38" :height="38" />
                </div>
              </div>
            </div>
            <div class="add-button" @click="addSubSession(null)" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Sesi</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Harga Event
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="content-form">
            <p>Tipe Tiket</p>
            <v-select :disabled="isWatch" class="form-input" :items="typeTiket" v-model="form.is_event_paid" solo
              :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div v-if="form.is_event_paid">
            <div class="content-form">
              <p>Harga Tiket</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.price" class="form-input" placeholder="Harga"
                type="number" :min="1" @input="() => {
                  if (form.price < 0) {
                    form.price = 0;
                  }
                }
                  " dense clearable></v-text-field>
            </div>

            <div class="content-form">
              <p>Potongan harga</p>
              <v-text-field :disabled="isWatch" outlined v-model="form.discount_price" class="form-input" :min="0"
                prefix="IDR" type="number" @input="() => {
                  if (form.discount_price < 0) {
                    form.discount_price = 0;
                  }
                }
                  " dense clearable></v-text-field>
            </div>
            <div class="content-form">
              <p>Durasi Earlybird</p>
              <v-row no-gutters class="pt-2">
                <v-col cols="6">
                  <v-row no-gutters>
                    <v-col cols="4" class="pt-2"> Mulai </v-col>
                    <v-col cols="8">
                      <v-text-field :disabled="isWatch" outlined type="datetime-local"
                        v-model="form.start_discount_date" class="form-input" dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5 ml-5">
                  <v-row no-gutters>
                    <v-col cols="3" class="pt-2"> Selesai </v-col>
                    <v-col cols="9">
                      <v-text-field :disabled="isWatch" outlined type="datetime-local" v-model="form.end_discount_date"
                        class="form-input" dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="content-form">
            <p>Batas Pembelian Tiket</p>
            <v-text-field :disabled="isWatch" outlined type="datetime-local"
              :rules="[!!form.registration_close_date || 'Wajib diisi!']" v-model="form.registration_close_date"
              class="form-input" dense></v-text-field>
          </div>
        </div>
      </div>
      <div class="cart-form">
        <v-card-title class="card-form-head-2" style="border: none">
          <h1>
            Benefit Event
          </h1>
        </v-card-title>
        <div class="mt-5">
          <div class="list-event" v-for="(item, idx) in form.benefits" :key="idx" style="margin-left: 0px;">
            <div class="session-nb-3">
            <v-text-field :disabled="isWatch" outlined v-model="form.benefits[idx]" class="form-input mt-2 no-space"
              dense clearable></v-text-field>
            <div @click="form.benefits.splice(idx, 1)" style="cursor: pointer">
              <trash :width="38" :height="38" />
            </div>
          </div>
          </div>
          <div class="add-button" @click="form.benefits.push('')" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Benefit</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button name="Simpan" width="260px" @click="onSubmit" :disabled="!validData" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import AddIcon from "@/components/icons/Add.vue";
import Trash from "@/components/icons/Trash.vue";
import Strip from "@/components/icons/Strip.vue";
import Button from "@/components/Button.vue";
import { VueEditor } from "vue2-editor";
import Popup from "@/components/others/Popup.vue";
import Arrow from '@/components/icons/Arrow.vue';
import ImageModal from "@/components/ImageModal.vue";

import moment from "moment";

export default {
  name: "FormBlog",
  components: {
    Button,
    Loading,
    AddIcon,
    Strip,
    Trash,
    VueEditor,
    Popup,
    Arrow,
    ImageModal
  },
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      imgModel: {
        desktop: {
          thumbnail: null,
          product_detail: null,
          purchase_detail: null,
        },
        mobile: {
          thumbnail: null,
          product_detail: null,
          purchase_detail: null,
        }
      },
      methods: [
        {
          name: "Daring (Online via Zoom)",
          value: "Online",
        },
        {
          name: "Luring (Offline in office)",
          value: "Offline",
        },
      ],
      levelList: [
        {
          name: "Semua Level",
          value: "Semua Level",
        },
        {
          name: "Dasar",
          value: "Dasar",
        },
        {
          name: "Menengah",
          value: "Menengah",
        },
        {
          name: "Profesional",
          value: "Profesional",
        },
      ],
      typeLimitEvent: [
        {
          name: "Tidak Terbatas",
          value: false,
        },
        {
          name: "Terbatas",
          value: true,
        },
      ],
      listSessionSchedule: [
        {
          name: "Hanya 1 Sesi",
          value: 0,
        },
        {
          name: "Lebih dari 1 Sesi Beruntun",
          value: 1,
        },
        {
          name: "Lebih dari 1 Sesi Acak",
          value: 2,
        },
      ],
      lmsConfirmList: [
        {
          name: "Support",
          value: true,
        },
        {
          name: "Manual",
          value: false,
        },
      ],
      typeTiket: [
        {
          name: "Berbayar",
          value: true,
        },
        {
          name: "Gratis",
          value: false,
        },
      ],

      limitOfEvent: false,
      sessionSchedule: 0,
      instructors: [],
      formInstructor: {
        name: "",
        image_url: "",
        position: "",
        institutional_origin: "",
        description: "",
        cv_url: "",
        linkedin_url: "",
      },
      form: {
        name: "",
        caption: "",
        description: "",
        category_id: 0,
        price: 0,
        batch: 1,
        slug_name: "",
        level: "",
        is_lms_compatible: false,
        total_session: 1,
        day_session: "",
        method: "Online",
        isSyllabuses: false,
        syllabuses: [],
        learning_url: "",
        benefits: [""],
        instructors: [""],
        participant_quota: 0,
        session_schedules: [
          { date_start: "", date_end: "", start_time: "", end_time: "" },
        ],
        img_urls: {
          desktop: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
          mobile: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
        },
        is_event_paid: false,
        registration_close_date: "",
        discount_price: 0,
        start_discount_date: "",
        end_discount_date: "",
        is_active: true,
        session_schedule_type: "",
        location_name: "",
        location_address: "",
        location_map: "",
      },
    };
  },

  watch: {
    limitOfEvent(e) {
      if (e) {
        this.form.participant_quota = 1;
      } else {
        this.form.participant_quota = 0;
      }
    }
  },
  computed: {
    validData() {
      if (this.form.name && this.form.description) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async addNewInsturctor() {
      this.form.instructors.push("");
    },
    addSessionSchedlue() {
      this.form.session_schedules.push({
        date_start: "",
        date_end: "",
        start_time: "",
        end_time: "",
      });
    },
    onRemoveIntructor(idx) {
      this.form.instructors.splice(idx, 1);
    },
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataEvent();
    },

    async getIntructure() {
      const resp = await this.$crm_http.get("/v1/instructor");
      this.instructors = resp.data.data.instructors;
    },

    async getDataEvent() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/event/" + this.id);
        const data = resp.data.data.event;
        if (data.participant_quota) {
          this.limitOfEvent = true
        }
        this.form = {
          ...data,
          session_schedules: data.session_schedules ? data.session_schedules : [{
            date_start: "",
            date_end: "",
            start_time: "",
            end_time: ""
          }],
          img_urls: Object.keys(data.img_urls).length
            ? data.img_urls
            : {
              desktop: {
                thumbnail: "",
                product_detail: "",
                purchase_detail: "",
              },
              mobile: {
                thumbnail: "",
                product_detail: "",
                purchase_detail: "",
              },
            },
          is_event_paid: data.price ? true : false,
          instructors: data.instructors.map((item) => item.id),
          registration_close_date: moment(data.registration_close_date).format("YYYY-MM-DDThh:mm"),
          start_discount_date: moment(data.start_discount_date).format("YYYY-MM-DDThh:mm"),
          end_discount_date: moment(data.end_discount_date).format("YYYY-MM-DDThh:mm")
        };
        this.sessionSchedule = this.listSessionSchedule.find((item) => item.name === data.session_schedule_type)?.value
        if (this.form.syllabuses.length) {
          this.form.isSyllabuses = true
        }
        setTimeout(() => {
          this.form.participant_quota = data.participant_quota
          this.isLoading = false;
        }, 1000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async uploadImage(imageFile) {
      const imageForm = new FormData();
      imageForm.append("attachment", imageFile);
      try {
        const response = await this.$crm_http.post("/v1/upload-file", imageForm);
        return response.data.data.upload_image_response.url_w_query;
      } catch (error) {
        console.error("Failed to upload image:", error);
        return null;
      }
    },
    async handleImageUpload() {
      for (const [device, images] of Object.entries(this.imgModel)) {
        for (const [key, imageFile] of Object.entries(images)) {
          if (imageFile) {
            const imageUrl = await this.uploadImage(imageFile);
            if (imageUrl) {
              this.form.img_urls[device][key] = imageUrl;
            }
          }
        }
      }
    },

    async onSubmit() {
      if (!this.form.is_event_paid) {
        this.form.price = 0
        this.form.discount_price = 0
      }
      this.isLoading = true;
      await this.handleImageUpload()
      // important declare
      if (!this.form.start_discount_date) this.form.start_discount_date = moment(new Date()).format("YYYY-MM-DD")
      if (!this.form.end_discount_date) this.form.end_discount_date = moment(new Date()).format("YYYY-MM-DD")
      try {
        this.form = {
          ...this.form,
          session_schedule_type: this.listSessionSchedule.find((item) => item.value === this.sessionSchedule).name,
          category_id: Number(this.$route.params.id_category),
          price: Number(this.form.price),
          batch: Number(this.form.batch),
          discount_price: Number(this.form.discount_price),
          participant_quota: Number(this.form.participant_quota),
          total_session: this.form.session_schedules.length ? this.form.session_schedules.length : 1
        };
        if (this.isEdit) {
          await this.$crm_http.put("/v1/event/" + this.form.id, this.form);
        } else {
          await this.$crm_http.post("/v1/event", this.form);
        }
        setTimeout(() => {
          this.$router.push(`/event-category/${this.$route.params.id_category}/event`);
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    checkScheduleType() {
      if (this.sessionSchedule === 0) {
        this.form.session_schedules[0].date_end = ''
        this.form.session_schedules.splice(1)
      } else if (this.sessionSchedule === 1) {
        this.form.session_schedules.splice(1)
      } else {
        this.form.session_schedules = this.form.session_schedules.map(item => ({
          ...item,
          date_end: ""
        }))
      }
    },
    createNewInstructor() {
      sessionStorage.setItem('redirect', this.$route.fullPath)
      this.$router.push("/instructor/new")
    },
    addSubSession(i) {
      if (i !== null) {
        this.form.syllabuses[i].push("");
        return;
      }
      this.form.syllabuses.push([""]);
      this.form.total_session = this.form.syllabuses.length;
    },
    onRemoveEvent(i, i2) {
      this.form.syllabuses[i].splice(i2, 1);
      if (this.form.syllabuses[i].length === 0) {
        this.form.syllabuses.splice(i, 1);
        this.form.total_session = this.form.syllabuses.length;
      }
    },
  },
  mounted() {
    this.getIntructure();
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}


.card-form-head {
  display: flex;
  margin: 40px 0;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.card-form-head-2 {
  display: flex;
  margin-left: -35px;
  margin-right: -20px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid gray;
}

.card-form-head-2 h1 {
  font-weight: 700;
  font-size: 18px;
  color: #36373f;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.card-form-head h1 {
  font-weight: 700;
  color: #36373f;
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

.card-form-head-bdy {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.card-form-head-bdy p {
  color: #575966;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.blue-text {
  font-weight: bold;
  color: #162ca2;
  cursor: pointer;
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #162ca2;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.form-ctn {
  padding: 0 40px;
}

.cart-form {
  position: relative;
  margin: 40px 0;
  border-radius: 30px;
  padding: 30px 40px;
  background-color: #ffffff;
}

.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.no-space {
  margin-bottom: -20px;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 14px;
  margin: 3px 0;
  font-weight: 400;
}

.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}

.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}

.icon-plus {
  position: absolute;
  bottom: 0px;
  left: -56px;
  cursor: pointer;
}

.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}

.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}

.label-info {
  color: gray;
  font-weight: normal;
  font-size: 15px;
}

.info-head {
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 30px;
}

.spacing-switch {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}

.container-date {
  display: flex;
  gap: 15px;
}

.container-date-left {
  width: 50%;
}

.container-date-rigth {
  width: 50%;
}

.session-numb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.session-nb-3 {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
</style>
