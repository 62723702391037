<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
          ">
        <!-- Image  -->
        <div>
          <img src="/img/other/delete-popup.png" alt="Delete Data" style="width: 200px" />
        </div>
        <!-- Detail -->
        <div style="
              display: flex;
              flex-direction: column;
              padding: 0px 40px;
              letter-spacing: 0.3px;
              gap: 6px;
            ">
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus project portfolio ini?
          </div>
        </div>
        <!-- Button -->
        <div style="
              display: flex;
              flex-direction: row;
              margin-top: 30px;
              gap: 16px;
            ">
          <!-- Cancel -->
          <Button name="Batalkan" @click="() => (isModal = false)" width="120px" height="40px" style="
                font-size: 14px;
                font-weight: 600;
                color: #7b7e8c;
                background-color: #ffffff;
                border: 1px solid #7b7e8c;
              " />
          <!-- Delete -->
          <Button name="Ya, Hapus" @click="onDelete" width="120px" height="40px" style="
                font-size: 14px;
                font-weight: 600;
                background-color: #b80f0a;
                color: white;
              " />
        </div>
      </div>
    </NewModal>
    <div class="menus-top">
      <span class="blue-text">Content Management System</span>
      <Arrow :width="20" :height="20" direct="left" strokeColor="#7B7E8C" class="icon" />
      <span style="color: #7b7e8c">Portfolio</span>
    </div>
    <div class="top-container-box mt-5">
      <h1>Project Portfolio</h1>
      <div>
        <Button icon="+" outline name="Buat Portofolio Baru"
          style="background-color: #3b54d9; color: #ffffff; width: 270px"
          @click="() => $router.push('/project-portfolio/new')" />
      </div>
    </div>
    <div class="mt-10 table" style="background-color: white">
      <v-toolbar flat>
        <v-row gap="4">
          <v-col cols="3">
            <div class="action-head">
              <div class="f-search">
                <div class="f-search-ic">
                  <img src="/img/icons/search.png" alt="Search Ic" />
                </div>
                <div style="width: 90%">
                  <v-text-field
                    v-model="filter.program"
                    type="text"
                    class="f-input"
                    placeholder="Cari Pekerjaan"
                    dense
                    flat
                    solo
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <date-picker
              class="my-datepicker"
              v-model="filter.date"
              placeholder="Date time"
              valueType="format"
              range
            ></date-picker>
          </v-col>
    
          <v-col cols="2">
            <Button
              name="TERAPKAN"
              width="100%"
              height="45px"
              @click="() => getProjectPortfolios()"
            />
          </v-col>
        </v-row>
      </v-toolbar>
      <div class="container-tabel">
        <table class="tabel borderless-table">
          <thead >
            <tr v-if="headers.length">
              <th
                v-for="(result, key) in headers"
                :key="key"
                class="table-head border-tabel"
                :style="`width: ${result.width} !important`"
              >
                {{ result.text }}
              </th>
            </tr>
          </thead>
          <tbody v-if="projectPortfolios.length">
            <tr
              v-for="(result, key) in projectPortfolios"
              :key="key"
              :style="{
                backgroundColor: key % 2 === 1 ? '#f5f6f9' : 'transparent',
              }"
            >
              <td class="table-body tbody">{{ result.program }}</td>
              <td class="table-body tbody">{{ result.title }}</td>
              <td class="table-body tbody">{{ $helpers.getYear(result.start_date) }}</td>
              <td class="table-body tbody">{{ result.device.map(item => item.name).join(", ")}}</td>
              <td
                :style="{
                  backgroundColor: key % 2 === 1 ? '#f5f6f9' : '',
                }"
                class="table-floting"
              >
                <div class="warp-btn-action">
                  <button
                    @click.prevent="watchItem(result)"
                    class="btn-stoke-blue"
                  >
                    <span>LIHAT</span>
                  </button>
                  <button class="btn-stoke-blue" @click="editItem(result)">
                    <span>UBAH</span>
                  </button>
                  <button
                    @click.prevent="deleteItem(result)"
                    class="btn-stoke-blue"
                  >
                    <span>Hapus</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Display when no data is available -->
        <NoDataAlert v-if="!projectPortfolios.length" :totalData="totalData" />
      </div>

      <div class="pagination-container">
        <div class="pagination-container-btn">
          <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getProjectPortfolios"
          ></v-pagination>
        </v-container>
        </div>
        <div class="pagination-page-container">
          <h4> Total {{ currentPage }} dari {{ this.total }} halaman</h4>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";

import Button from "@/components/Button.vue";
import NewModal from "@/components/NewModal.vue";
import Loading from "@/components/ilustration/Loading.vue";
import NoDataAlert from "@/components/NoDataAlert.vue";
import Popup from "@/components/others/Popup.vue";
import Arrow from '@/components/icons/Arrow.vue';

export default {
  name: "ProjectPortfolio",
  components: {
    Button,
    Loading,
    NoDataAlert,
    NewModal,
    Popup,
    Arrow,
    DatePicker
  },
  data() {
    return {
      isModal: false,
      currentPage: 1,
      total: 0,
      perPage: 10,
      isLoading: false,
      id: 0,
      totalData: 0,
      message: "",
      isPopup: false,
      category: "",
      filter: {
        program: "",
        date: [],
        start_date: "",
        end_date: "",
        device: ""
      },
      headers: [
        { text: "Project Portofolio", width: "100px"},
        { text: "Klien", width: "100px"},
        { text: "Timeline ", width: "100px"},
        { text: "Device", width: "280px"},
        { text: "AKSI"},
      ],
      projectPortfolios: [],
      showFilterModal: false,
      filterModal: [
        {
          title: "Kategori",
          name: "category",
          items: [
            {
              name: "Collaboration",
              value: "COLLABORATION",
            },
            {
              name: "Training",
              value: "TRAINING",
            },
            {
              name: "Talent Service",
              value: "TALENT_SERVICE",
            },
            {
              name: "Services",
              value: "SERVICES",
            },
          ],
        },
        {
          title: "Urutkan",
          name: "sort_by",
          items: [
            {
              name: "Terlama",
              value: "asc",
            },
            {
              name: "Terbaru",
              value: "desc",
            },
          ],
        },
      ],
    };
  },
  methods: {
    applyFilters() {
      this.getProjectPortfolios();
      this.showFilterModal = false;
    },
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    updateFilters(value) {
      this.filter[value.name] = value.value;
    },
    async getProjectPortfolios() {
      this.projectPortfolios = [];
      this.filter.start_date = this.filter.date[0] || "";
      this.filter.end_date = this.filter.date[1] || "";
      await this.$crm_http
        .get(
          `/v1/project-portfolio?page=${this.currentPage}&per_page=${this.perPage
          }&program=${this.filter.program}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&device=${this.filter.device}`
        )
        .then((response) => {
          this.projectPortfolios = response.data.data.project_portfolios.map((item) => ({
            ...item,
            category: this.capitalizeText(item.category),
            created_at: this.$helpers.getDateFull(item.created_at),
          }))
            
            const t = parseInt(response.data.data.total);
            this.total = Math.ceil(t / 10);
            this.totalData = response.data.data.total;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    pageNo(item) {
      if (item) {
        return item.no;
      }
      return;
    },
    capitalizeText(text) {
      if (!text) return "";
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    },
    watchItem(item) {
      this.$router.push("/project-portfolio/watch/" + item.id);
    },
    editItem(item) {
      this.$router.push("/project-portfolio/edit/" + item.id);
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete("/v1/project-portfolio/" + this.id);
        this.isModal = false;
        this.getProjectPortfolios();
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    clearFilter() {
      this.filter = {
        program: "",
        date: "",
        device: []
      };
      this.getProjectPortfolios();
    },
  },
  mounted() {
    this.getProjectPortfolios();
  },
};
</script>

<style scoped>
.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}

.blue-text {
  font-weight: bold;
  color: #162ca2;
  cursor: pointer;
}

.action-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-weight: bold;
  font-size: 19px;
}

.f-search {
  border: 1px solid #d3d6e1;
  width: 350px;
  height: 45px;
  gap: 10px;
  display: flex;
  border-radius: 14px;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: 10px;
}

.f-input {
  width: 86%;
  background: transparent;
  border: none;
}

.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}

.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.count-data {
  position: relative;
  top: -60px;
  width: 30%;
}

.no-pagination {
  margin-top: 20px;
  margin-left: 5px;
}
.my-datepicker {
  width: 100%;
}

.my-datepicker ::v-deep .mx-input {
  height: 45px !important;
  border-radius: 12px !important;
}
.container-tabel {
  background-color: white;
  margin: 20px;
  overflow-x: auto;
}
.tabel {
  position: relative;
  border: none;
  width: 100%;
  border-collapse: collapse;
}
.table-head {
  background-color: white;
  white-space: nowrap;
  width: max-content;
  text-align: left;
  padding: 10px;
}
.border-tabel {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #e5e7e9;
}
.table-body {
  min-width: 200px;
  height: 141px;
  white-space: nowrap;
  width: auto;
  padding: 10px;
  text-align: left;
}

.table-floting {
  white-space: nowrap;
  width: auto;
  min-width: 200px;
  text-align: left;
  position: sticky;
  right: 0;
  background-color: white;
}
.warp-btn-action {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.btn-action {
  background-color: white;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575966;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #acafbf;
}
.btn-action-2 {
  background-color: white;
  color: #2d46cf;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #2d46cf;
}

.tbody {
  margin: 0;
  font-weight: 400;
  border-collapse: collapse;
  font-size: 14px;
}
.tbody-color-red {
  color: #b80f0a;
}
.tbody-color-gray {
  color: #7b7e8c;
}
.btn-stoke-blue {
  border: 1px solid #acafbf;
  border-radius: 12px;
  color: #162ca2;
  font-weight: 600;
  cursor: pointer;
  gap: 10px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 13px;
  background-color: white;
  margin: 5px;
}

.btn-stoke-blue span {
  font-family: "";
  font-weight: normal !important;
  font-size: 13px;
  min-width: max-content;
}
</style>