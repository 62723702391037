var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-10"},[_c('div',{staticClass:"ctn-head"},[_c('h2',{staticStyle:{"font-weight":"700","font-size":"28px","color":"#36373f"}},[_vm._v(" "+_vm._s(_vm.category ? "RIWAYAT" : "")+" "+_vm._s(this.category_name.toUpperCase())+" ")]),(!_vm.category)?_c('button',{staticClass:"btn-stoke-blue",on:{"click":function($event){_vm.$router.push(
          `/crm-event/history/${_vm.category_name
            .toLowerCase()
            .replace(/\s+/g, '-')}/${_vm.id}`
        )}}},[_c('IconReverse'),_c('span',[_vm._v("Riwayat")])],1):_vm._e()]),_c('div',{staticClass:"container-box mt-10",staticStyle:{"padding":"35px 25px"}},[_c('v-row',{attrs:{"gap":"5"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"action-head"},[_c('div',{staticClass:"f-search"},[_c('div',{staticClass:"f-search-ic"},[_c('img',{attrs:{"src":"/img/icons/search.png","alt":"Search Ic"}})]),_c('div',{staticStyle:{"width":"100%"}},[_c('v-text-field',{staticClass:"f-input",attrs:{"type":"text","placeholder":"Cari Nama Pembeli","dense":"","flat":"","solo":""},model:{value:(_vm.filter.position),callback:function ($$v) {_vm.$set(_vm.filter, "position", $$v)},expression:"filter.position"}})],1)])])]),_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{staticClass:"my-datepicker",attrs:{"placeholder":"Date time","valueType":"format","range":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{staticStyle:{"border-radius":"10px !important","background-color":"white"},attrs:{"outlined":"","color":"#ACAFBF","placeholder":"Tipe Pekerjaan","items":_vm.workTypeList,"item-text":'name',"item-value":'value',"hide-details":""},model:{value:(_vm.filter.work_type),callback:function ($$v) {_vm.$set(_vm.filter, "work_type", $$v)},expression:"filter.work_type"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('Button',{attrs:{"name":"TERAPKAN","width":"100%","height":"54px"},on:{"click":() => _vm.getDataEvent()}})],1)],1),_c('div',{staticClass:"list-career"},_vm._l((_vm.careers),function(data,idx){return _c('div',{key:idx,staticClass:"list-career-ctn",on:{"click":function($event){_vm.category
            ? _vm.$router.push(
                `/crm-event/history/${_vm.category_name
                  .toLowerCase()
                  .replace(/\s+/g, '-')}/${data.id}/history`
              )
            : _vm.$router.push(
                `/crm-event/${_vm.category_name
                  .toLowerCase()
                  .replace(/\s+/g, '-')}/${data.id}`
              )}}},[_c('h3',{staticClass:"mb-3"},[_vm._v(_vm._s(data.name))]),_c('p',{staticClass:"text-limit"},[_vm._v(" "+_vm._s(data.schedule)+" ")]),_c('div',{staticStyle:{"height":"44px"}},[_c('p',{staticClass:"text-limit",staticStyle:{"font-weight":"400","-webkit-line-clamp":"2"}},[_vm._v(" "+_vm._s(data.learning_method === "Online" ? "Via Zoom" : "On Site")+" ")])]),_c('div',{staticClass:"box-total",staticStyle:{"padding":"20px"}},[_c('p',[_c('b',[_vm._v(_vm._s(data.total_registered))])]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v("Pendaftar")])]),_c('p',{staticClass:"mt-4"},[_vm._v(" Batas Pendaftaran "),_c('b',{staticStyle:{"font-family":"'Poppins'"}},[_vm._v(_vm._s(_vm.moment(data.registration_close_date)))])])])}),0),_c('div',{staticClass:"pagination-container"},[(_vm.total)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.total},on:{"input":_vm.getDataEvent},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"pagination-page-container",staticStyle:{"width":"20%"}},[_c('h5',[_vm._v("Halaman")]),_c('h5',{staticClass:"pagination-page"},[_vm._v(_vm._s(_vm.currentPage))]),_c('h5',[_vm._v(" dari "+_vm._s(_vm.total))])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }