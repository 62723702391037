<template>
  <div class="body-form">
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <div style="width: 90%; margin: 30px 0;">
      <div class="menus-top">
        <span class="blue-text" @click="$router.go(-1)">Event</span>
        <Arrow :width="20" :height="20" direct="left" strokeColor="#7B7E8C" class="icon" />
        <span style="color: #7b7e8c">Detail Event</span>
      </div>
      <h2 class="detail-title">Detail Event</h2>
      <div class="event-card">
        <h3 class="section-title">Informasi Umum Event</h3>
        <div class="event-info">
          <div class="info-row">
            <div class="info-label">Jenis Event</div>
            <div class="info-value">{{ event.category.name }}</div>
          </div>
          <div class="info-row">
            <div class="info-label">Nama Event</div>
            <div class="info-value">{{ event.name }}</div>
          </div>
          <div class="info-row" style="flex-direction: row; gap: 40px">
            <div class="info-group">
              <div class="info-label">Batch Series</div>
              <div class="info-value">Batch {{ event.batch }}</div>
            </div>
            <div class="info-group">
              <div class="info-label">Level Pelatihan</div>
              <div class="info-value">{{ event.level }}</div>
            </div>
            <div class="info-group">
              <div class="info-label">Metode Pelatihan</div>
              <div class="info-value">{{ $helpers.toMethode(event.method) }}</div>
            </div>
            <div class="info-group">
              <div class="info-label">Alamat/ URL</div>
              <div class="info-value">
                <a :href="event.location_map ?? event.learning_url" target="_blank">
                  {{ event.location_map ?? event.learning_url }}
                </a>
              </div>
            </div>
          </div>
          <div class="info-row" style="flex-direction: row; gap: 40px">
            <div class="info-group">
              <div class="info-label">Tipe Kuota Peserta</div>
              <div class="info-value">{{ event.participant_quota ? 'Terbatas' : 'Tidak Terbatas' }}</div>
            </div>
            <div class="info-group" v-if="event.participant_quota">
              <div class="info-label">Kuota Peserta</div>
              <div class="info-value">{{ event.participant_quota }} Peserta</div>
            </div>
          </div>
          <div class="info-row">
            <div class="info-label">Deskripsi Singkat Thumbnail</div>
            <div class="info-value" v-html="event.description"></div>
          </div>
        </div>
      </div>
      <div class="event-card">
        <h3 class="section-title">Informasi Umum Event (Versi Dekstop)</h3>
        <div class="event-info">
          <div class="info-row">
            <div class="info-label">Gambar Thumbnail</div>
            <div class="info-value"><img :src="event.img_urls.desktop.thumbnail" alt="tumb"></div>
          </div>
          <div class="info-row">
            <div class="info-label">Gambar Detail Program</div>
            <div class="info-value"><img :src="event.img_urls.desktop.product_detail" alt="deatil"></div>
          </div>
          <div class="info-row">
            <div class="info-label">Gambar Pendaftaran</div>
            <div class="info-value"><img :src="event.img_urls.desktop.purchase_detail" alt="purcase"></div>
          </div>
        </div>
      </div>
      <div class="event-card">
        <h3 class="section-title">Informasi Umum Event (Versi Mobile)</h3>
        <div class="event-info">
          <div class="info-row">
            <div class="info-label">Gambar Thumbnail</div>
            <div class="info-value"><img :src="event.img_urls.mobile.thumbnail" alt="tumb"></div>
          </div>
          <div class="info-row">
            <div class="info-label">Gambar Detail Program</div>
            <div class="info-value"><img :src="event.img_urls.mobile.product_detail" alt="deatil"></div>
          </div>
          <div class="info-row">
            <div class="info-label">Gambar Pendaftaran</div>
            <div class="info-value"><img :src="event.img_urls.mobile.purchase_detail" alt="purcase"></div>
          </div>
        </div>
      </div>
      <div class="event-card">
        <h3 class="section-title">Mekanisme Dan Silabus Event</h3>
        <div class="info-row">
          <div class="info-label">Tipe Jadwal Tayang</div>
          <div class="info-value">{{ event.session_schedule_type }}</div>
        </div>
        <div class="session-card mt-5" v-for="n in event.total_session" :key="n">
          <div class="session-header">
            <span class="session-number">Sesi {{ n }}</span>
            <span class="session-date">{{ $helpers.format_date(event.session_schedules[n-1].date_start, 'dddd, DD MMMM YYYY') }} | {{ event.session_schedules[n-1].start_time }} - {{ event.session_schedules[n-1].end_time }} WIB</span>
          </div>
          <div v-if="event.syllabuses[n-1]">
            <div class="session-module">
              <strong>Modul</strong>
            </div>
            <ul>
              <li v-for="(data, idx) in event.syllabuses[n-1]" :key="idx">{{ data }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="event-card">
        <h3 class="section-title">Harga Event</h3>
        <div class="info-row">
          <div class="info-label">Tipe Tiket</div>
          <div class="info-value">{{ event.price ? 'Berbayar' : 'Gratis' }}</div>
        </div>
        <div class="info-row" style="flex-direction: row; gap: 40px">
          <div class="info-group">
            <div class="info-label">Harga Normal</div>
            <div class="info-value">{{ event.price ? $helpers.formatCurrency(event.price) : 'GRATIS' }}</div>
          </div>
          <div class="info-group" v-if="event.discount_price">
            <div class="info-label">Promo Harga</div>
            <div class="info-value">{{ $helpers.formatCurrency(event.discount_price) }}</div>
          </div>
          <div class="info-group" v-if="event.discount_price">
            <div class="info-label">Harga Akhir</div>
            <div class="info-value">{{ $helpers.formatCurrency(event.price - event.discount_price) }}</div>
          </div>
          <div class="info-group" v-if="event.discount_price">
            <div class="info-label">Promo earliy bird berakhir tanggal</div>
            <div class="info-value">{{ $helpers.format_date(event.end_discount_date, 'dddd, DD MMMM YYYY') }}</div>
          </div>
        </div>
      </div>
      <div class="event-card">
        <h3 class="section-title">Benefit</h3>
        <div class="event-info">
          <ul>
            <li v-for="(data, idx) in event.benefits" :key="idx">{{ data }}</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Popup from "@/components/others/Popup.vue";
import Arrow from '@/components/icons/Arrow.vue';

export default {
  name: 'WatchEvent',
  components: {
    Popup,
    Arrow
  },
  data() {
    return {
      message: "",
      isPopup: false,
      event: {
        syllabuses: [],
        instructors: [],
        session_schedules: [],
        benefits: [],
        category: {},
        voucher: {},
        img_urls: {
          desktop: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: "",
          },
          mobile: {
            thumbnail: "",
            product_detail: "",
            purchase_detail: ""
          }
        }
      }
    };
  },
  methods: {
    async getDataEvent() {
      try {
        const resp = await this.$web_http.get('/v1/event/' + this.$route.params.id)
        const data = resp.data.data.event
        this.event = {
          ...data,
          session_schedules: data.session_schedules ? data.session_schedules : []
        }
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
  },
  mounted() {
    this.getDataEvent()
  }
}
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}

.blue-text {
  font-weight: bolder;
  font-size: 17px;
  color: #162ca2;
  cursor: pointer;
}

.detail-title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.event-card {
  background-color: #ffffff;
  border-radius: 16px;
  padding: 34px 24px;
  margin: 40px 0;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.event-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 25px 20px;
  border-radius: 14px;
  border: 1px solid #D3D6E1;
}

.info-row {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.info-group {
  gap: 16px;
  margin-bottom: 12px;
}

.info-label {
  font-size: 14px;
  font-weight: 400;
  color: #555;
}

.info-value {
  font-size: 16px;
  font-weight: bold !important;
  color: #333;
}

.info-value img {
  transform: scale(0.8);
}

.info-value a {
  color: #007bff;
  text-decoration: none;
}

.info-value a:hover {
  text-decoration: underline;
}

.event-card .info-row:last-child {
  margin-top: 20px;
}

.event-card .info-label:last-child,
.event-card .info-value:last-child {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #555;
}

.session-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  padding: 24px;
}

.session-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.session-number {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.session-date {
  font-size: 14px;
  font-weight: 400;
  color: #555;
}

.session-module {
  margin-bottom: 16px;
}

.session-module strong {
  font-size: 14px;
  font-weight: 600;
  color: #555;
  display: block;
  margin-bottom: 8px;
}

.session-module h3 {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.session-description {
  font-size: 14px;
  font-weight: 400;
  color: #555;
  line-height: 1.6;
}
</style>