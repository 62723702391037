<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Modal v-if="isModalInfo" @close="() => (isModalInfo = false)" width="60%">
            <div class="inner-container-modal-detail">
                <div class="container-title-modal-detail">
                    <h1>Panduan Variabel Template Email</h1>
                </div>
                <div style="text-align: left; margin-top: 10px;">
                    <div class="section">
                        <h2>1️⃣ Apa Itu Variabel Template?</h2>
                        <p>Variabel ini berfungsi untuk menggantikan placeholder dengan data spesifik saat email
                            dikirim. Misalnya, <code v-html="'{{ .1 }}'"></code> akan diisi dengan nama penerima.</p>
                    </div>

                    <div class="section">
                        <h2>2️⃣ Cara Menggunakan:</h2>
                        <ul>
                            <li>Ketikkan teks email seperti biasa.</li>
                            <li>Gunakan format <code v-html="'{{ .n }}'"></code> (contoh: <span
                                    v-html="'{{ .1 }}'"></span>, <span v-html="'{{ .2 }}'"></span>) pada bagian yang
                                ingin diisi secara dinamis.</li>
                            <li>Setiap angka mewakili data yang akan Anda masukkan.</li>
                        </ul>
                    </div>

                    <div class="section">
                        <h2>3️⃣ Contoh:</h2>
                        <p><strong>Template:</strong></p>
                        <div class="code-block">
                            Hello <span v-html="'{{ .1 }}'"></span>,<br>
                            Selamat kamu telah memenangkan <span v-html="'{{ .2 }}'"></span>.
                        </div>
                        <p><strong>Hasil:</strong></p>
                        <div class="code-block">
                            Hello John Doe,<br>
                            Selamat kamu telah memenangkan Hadiah Utama.
                        </div>
                    </div>

                    <div class="section">
                        <h2>4️⃣ Tips:</h2>
                        <div class="tips">
                            <p>✅ Pastikan nomor variabel sesuai dengan data yang akan Anda isi.</p>
                            <p>✅ Variabel tidak boleh kosong untuk memastikan email terlihat profesional.</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div class="content-form">
        <p>Judul</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.name" class="form-input" placeholder="Tulis judul"
          dense clearable></v-text-field>
      </div>
      <div class="content-form">
        <p>Subject</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.subject" class="form-input" placeholder="Tulis subject"
          dense clearable></v-text-field>
      </div>
      <div class="content-form">
        <p>Description</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.description" class="form-input" placeholder="Tulis subject"
          dense clearable></v-text-field>
      </div>
      <div class="content-form">
        <p>Tipe</p>
        <v-select class="form-select" :items="list_type" :item-text="'name'" :item-value="'value'" v-model="form.category"
          outlined>
        </v-select>
      </div>
      <div class="content-form" v-if="form.category === 'TEXT'">
        <p class="banner-berkas">Body</p>
        <div style="display: flex; justify-content: space-between;">
          <p>Konten</p>
          <span class="note-file cursor-pointer" @click="isModalInfo = true">Click for guide</span>

        </div>
        <vue-editor v-model="form.content" :editorToolbar="$helpers.customToolBar()" class="form-input"
          v-if="!isWatch"></vue-editor>
        <div v-else class="watch-ctn">
          <div v-html="form.content"></div>
        </div>
      </div>
      <div v-if="form.category === 'IMAGE'">
        <p class="banner-berkas">Berkas Gambar</p>
        <v-file-input
            v-model="img_web_second"
            accept="image/*"
            placeholder="Unggah Berkas Gambar"
            class="form-upload"
            prepend-icon=""
            outlined
          >
            <template v-slot:prepend-inner>
              <ImageModal
                :src="form.content"
                width="76.31"
                height="37"
                radius="3"
              />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">
            *Ukuran file maks 1 MB
          </p>
      </div>
      <div class="content-form" v-if="form.category === 'IMAGE'">
        <p>Redirect URL</p>
        <v-text-field :disabled="isWatch" outlined v-model="form.redirect_url" class="form-input"
          placeholder="Tulis link redirect" dense clearable></v-text-field>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button name="PUBLISH" width="260px" @click="onSumbmit" :disabled="!validData" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Loading from "@/components/ilustration/Loading.vue";
import Button from "@/components/Button.vue";
import ImageModal from "@/components/ImageModal.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "FormBlog",
  components: {
    VueEditor,
    Button,
    Loading,
    ImageModal,
    Modal
  },
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isLoading: false,
      img_web_second: null,
      isWatch: false,
      is_sigle: true,
      isEdit: false,
      isModalInfo: false,
      list_type: [
        {
          name: "Image",
          value: "IMAGE"
        },
        {
          name: "Text",
          value: "TEXT"
        },
      ],
      form: {
        name: "",
        subject: "",
        description: "",
        content: "",
        category: "IMAGE",
        redirect_url: ""
      },
      tableHeaders: [],
      tableData: [],
    };
  },
  computed: {
    validData() {
      return this.form.name && this.form.subject
    }
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return
    },
    async onSumbmit() {
      this.isLoading = true;
      try {
        if (this.img_web_second) {
            const imageForm = new FormData();
            imageForm.append("attachment", this.img_web_second);
            const imageRes = await this.$crm_http.post("/v1/upload-file", imageForm);
            this.form.content = imageRes.data.data.upload_image_response.url_w_query;
            imageForm.delete("attachment");
        }
        await this.$crm_http.post("/v1/email-template", {
          ...this.form,
        });
        this.isLoading = false;
        this.$router.push("/blast-template");
      } catch (e) {
        alert(e);
        this.isLoading = false;
      }
    },
    // onChange(event) {
    //   this.file = event.target.files ? event.target.files[0] : null;
    //   const reader = new FileReader();

    //   reader.onload = (e) => {
    //     const data = new Uint8Array(e.target.result);
    //     const workbook = XLSX.read(data, { type: 'array' });

    //     const sheetName = workbook.SheetNames[0];
    //     const worksheet = workbook.Sheets[sheetName];
    //     const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

    //     this.tableHeaders = jsonData[0];
    //     this.tableData = jsonData.slice(1);
    //     this.tableData.forEach((item) => {
    //       this.form.senders.push(item[0])
    //       this.form.attachment.push(item.slice(1).join(","))
    //     });
    //   };
    //   reader.readAsArrayBuffer(this.file);
    // },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding-bottom: 30px;
  min-height: 100vh;
  width: 100vw;
}

.cart-form {
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 14px !important;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 16px;
  margin: 3px;
  font-weight: bold;
}

.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

::v-deep .form-select .v-input__slot {
  height: 56 !important;
  margin-bottom: 20px;
  background-color: #ffffff;
}

::v-deep .form-select .v-text-field__details {
  display: none;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.session-nb-3 {
  display: flex;
  gap: 10px;
}


.inner-container-modal-detail {
    padding: 16px;
}

.inner-container-modal-detail p {
    margin: 0;
    text-align: left;
}

.container-title-modal-detail {
    display: flex;
    gap: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ACAFBF;
}


.section {
    margin-bottom: 20px;
}

.section h2 {
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
}

.section p {
    font-size: 14px;
    color: #555;
    line-height: 1.6;
}

.code-block {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    font-family: monospace;
    font-size: 14px;
    color: #333;
    overflow-x: auto;
}

.tips {
    background-color: #eef9f4;
    border-left: 4px solid #4caf50;
    padding: 10px;
    border-radius: 4px;
}

.tips p {
    font-size: 14px;
    color: #333;
    margin: 0;
}

ul {
    padding-left: 20px;
    margin: 0;
}

ul li {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
}

.note-file {
  font-weight: 400;
  font-size: 12px;
  color: #b80f0a;
  margin-bottom: 20px !important;
}
::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
  display: none;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>