<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup :message="message" :isError="true" v-if="isPopup" @close="() => isPopup = false" />
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
        {{ name }}
      </h1>
      <div class="form-ctn">
        <div class="content-form">
          <p>Judul Kelas</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.class.name"
            :rules="[!!form.class.name || 'Wajib diisi!']" class="form-input" placeholder="Judul Kelas" dense
            clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Prefix Code</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.class.prefix_code"
            :rules="[!!form.class.prefix_code || 'Wajib diisi!']" class="form-input" placeholder="Prefix Code" dense
            clearable></v-text-field>
        </div>
        <div class="content-form mb-5">
          <p>Tampilan Inti</p>
          <div class="spacing-switch">
            <v-switch v-model="form.class.is_main" color="primary" hide-details style="margin-top: -5px"></v-switch>
            <div v-if="form.class.is_main">
              <Chip :active="form.class.is_main" />
            </div>
          </div>
        </div>
        <div class="content-form mb-5">
          <p>Rekomendasi</p>
          <div class="spacing-switch">
            <v-switch v-model="form.class.is_recommend" color="primary" hide-details
              style="margin-top: -5px"></v-switch>
            <div v-if="form.class.is_recommend">
              <Chip :active="form.class.is_recommend" />
            </div>
          </div>
        </div>
        <div class="content-form">
          <p>Caption</p>
          <v-textarea placeholder="Caption" :disabled="isWatch" outlined rows="3" v-model="form.class.meta_description"
            clearable class="form-input"></v-textarea>
        </div>

        <div v-if="categoryId === 6">
          <div class="content-form">
            <p>Kolaborasi</p>
            <span class="alert-info">Rekomendasi dimensi 1 : 1, ukuran file maksimal 1MB</span>
            <v-text-field :disabled="isWatch" outlined v-model="form.class.collaboration_feat" class="form-input"
              placeholder="Link Image Thumbnail" dense clearable></v-text-field>
          </div>
          <img v-if="form.class.collaboration_feat" :src="form.class.collaboration_feat" alt="gambar tidak valid"
            style="width: 100%; border-radius: 14px" />
          <div class="content-form" v-if="categoryId === 6">
            <p>Kategori Program</p>
            <v-select class="form-input" :items="program_category" placeholder="Select Kategori Program"
              v-model="form.class.type" solo :disabled="isWatch" :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
          <div class="content-form" v-if="categoryId === 6">
            <p>Kategori Event</p>
            <v-select class="form-input" :items="category_event" placeholder="Select Kategori Event"
              v-model="form.class.type_class" solo :disabled="isWatch" :item-text="'name'" :item-value="'value'">
            </v-select>
          </div>
        </div>
      </div>
      <div class="spacing-ctn"></div>
      <div class="form-ctn">
        <p class="berkas-title">Berkas Gambar</p>
        <div class="content-form">
          <p>Thumbnail</p>
          <v-file-input v-model="img_thumb" accept="image/*" placeholder="Unggah Berkas Gambar" class="form-upload"
            prepend-icon="" outlined :disabled="isWatch">
            <template v-slot:prepend-inner v-if="img_thumb == null">
              <ImageModal :src="form.class.thumbnail_url" width="76.31" height="37" radius="3" />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <span class="alert-info">Rekomendasi dimensi 2 : 1, ukuran file maksimal 1MB</span>
        </div>
        <div class="content-form">
          <p>Web Desktop</p>
          <v-file-input v-model="img_dekstop" accept="image/*" placeholder="Unggah Berkas Gambar" class="form-upload"
            prepend-icon="" outlined :disabled="isWatch">
            <template v-slot:prepend-inner v-if="img_dekstop == null">
              <ImageModal :src="form.class.image_url" width="76.31" height="37" radius="3" />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <span class="alert-info">Rekomendasi dimensi 1600 x 420 px, ukuran file maksimal 1MB</span>

        </div>

        <div class="content-form" style="margin-top: 20px">
          <p>Web Mobile Browser</p>
          <v-file-input v-model="img_mobile" accept="image/*" placeholder="Unggah Berkas Gambar" class="form-upload"
            prepend-icon="" outlined :disabled="isWatch">
            <template v-slot:prepend-inner v-if="img_mobile == null">
              <ImageModal :src="form.class.small_image_url" width="76.31" height="37" radius="3" />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <span class="alert-info">Rekomendasi dimensi 328 x 220 px, ukuran file maksimal 1MB</span>

        </div>
      </div>
      <div class="spacing-ctn"></div>
      <div class="form-ctn">
        <p class="berkas-title">Info Kelas</p>

        <div class="content-form">
          <p>Deskripsi Program</p>
          <v-textarea placeholder="Deskripsi" :disabled="isWatch" outlined rows="5" v-model="form.class.description"
            :rules="[!!form.class.description || 'Wajib diisi!']" clearable class="form-input"></v-textarea>
        </div>
        <div class="content-form">
          <p>Type Class</p>
          <v-select class="form-input" :items="class_category" placeholder="Select type class" v-model="categoryId" solo
            disabled :rules="[!!categoryId || 'Wajib diisi!']" :item-text="'name'" :item-value="'id'">
          </v-select>
        </div>
        <div class="content-form" v-if="categoryId === 1">
          <p>Sub Type Class</p>
          <v-select :disabled="isWatch" class="form-input" :items="class_sub_category"
            placeholder="Select sub type class" v-model="form.class.class_sub_category_id" solo :item-text="'name'"
            :item-value="'id'">
          </v-select>
        </div>
        <div class="content-form">
          <p>Level</p>
          <v-select :disabled="isWatch" class="form-input" :items="levels" v-model="form.class.level" solo
            :rules="[!!form.class.level || 'Wajib diisi!']">
          </v-select>
        </div>
        <div class="content-form">
          <p>Metode</p>
          <v-select :disabled="isWatch" class="form-input" :items="method" v-model="form.class.method" solo
            :rules="[!!form.class.method || 'Wajib diisi!']" :item-text="'name'" :item-value="'value'">
          </v-select>
        </div>
        <div class="content-form" v-if="categoryId !== 6">
          <p>Sesi Pertemuan (Otomatis dari jumlah silabus)</p>
          <v-text-field disabled outlined v-model="form.class_detail.session_total"
            :rules="[!!form.class_detail.session_total || 'Wajib diisi!']" class="form-input" dense type="number"
            clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Hari pertemuan</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.class_detail.days"
            :rules="[!!form.class_detail.days || 'Wajib diisi!']" class="form-input" placeholder="Senin, Rabu, & Jumat"
            dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Jam Pertemuan</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="2" class="pt-2"> Mulai </v-col>
                <v-col cols="8">
                  <v-text-field :disabled="isWatch" outlined type="time" v-model="form.class.start_time"
                    class="form-input" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="3" class="pt-2"> Selesai </v-col>
                <v-col cols="8">
                  <v-text-field :disabled="isWatch" outlined type="time" v-model="form.class.end_time"
                    class="form-input" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="content-form">
          <p>Kenapa Memilih HiColleagues</p>
          <v-select :disabled="isWatch" v-model="form.class_detail.why_info_list" :items="whyInfoList" multiple chips
            persistent-hint outlined item-text="title" return-object class="form-select"></v-select>
        </div>
        <div class="content-form">
          <p>Metode Pembelajaran</p>
          <v-select :disabled="isWatch" v-model="form.class_detail.learning_info_list" :items="learningInfoList"
            multiple chips persistent-hint outlined item-text="title" return-object class="form-select"></v-select>
        </div>
        <div class="content-form">
          <p>Metode Pembelajaran</p>
          <vue-editor v-model="form.class_detail.learning_objective" :editorToolbar="$helpers.customToolBar()"
            class="form-input" v-if="!isWatch"></vue-editor>
          <div v-else class="watch-ctn">
            <div v-html="form.class_detail.learning_objective"></div>
          </div>
        </div>
        <div class="content-form mt-6">
          <p>Metode Penyampaian</p>
          <vue-editor v-model="form.class_detail.learning_method" :editorToolbar="$helpers.customToolBar()"
            class="form-input" v-if="!isWatch"></vue-editor>
          <div v-else class="watch-ctn">
            <div v-html="form.class_detail.learning_method"></div>
          </div>
        </div>
      </div>
      <div class="form-ctn mt-6" v-if="categoryId === 4">
        <div class="content-form">
          <p>Syarat & Ketentuan Mengikuti Kelas</p>
          <div class="list-event" v-for="(item2, idx) in form.class_detail.requirements" :key="idx">
            <div class="icon-plus-bc" @click="form.class_detail.requirements.splice(idx, 1)">
              <trash-icon :width="25" :height="25" />
            </div>
            <b>Ke {{ idx + 1 }}</b>
            <v-text-field :disabled="isWatch" outlined placeholder="Syarat Ketentuan" v-model="item2.name"
              class="form-input no-space" dense></v-text-field>
          </div>
          <div class="add-button" @click="addListRequirements" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Requirements</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn mt-5"></div>
      <div class="form-ctn">
        <p class="berkas-title">Support class</p>
        <div class="content-form">
          <p>insturctor</p>
          <v-autocomplete placeholder="Find instructor" :disabled="isWatch" v-model="form.class.instructor_id"
            :items="instructors" :item-text="'name'" :item-value="'id'"></v-autocomplete>
        </div>
        <div class="content-form">
          <p>Link Materi (Global)</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.class.material" class="form-input"
            placeholder="Link materi" dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Link pembelajaran</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.class.learning_link" class="form-input"
            placeholder="Link pembelajaran" dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Link Group Chat</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.class.group_chat_link" class="form-input"
            placeholder="Link Group Chat" dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Link konsultasi</p>
          <v-text-field :disabled="isWatch" outlined v-model="form.class.consultancy_link" class="form-input"
            placeholder="Link konsultasi" dense clearable></v-text-field>
        </div>
        <div class="content-form" v-if="categoryId === 1 || categoryId === 4 || categoryId === 5">
          <p>Link rubik penilaian</p>
          <v-text-field :disabled="isWatch" outlined v-model="classLms.rubrik_url" class="form-input"
            placeholder="Link Rubik Penilaian" dense clearable></v-text-field>
        </div>
        <div class="content-form" v-if="categoryId !== 6">
          <p>Total Quiz</p>
          <v-text-field :disabled="isWatch" outlined v-model="classLms.total_quiz"
            :rules="[!!classLms.total_quiz || 'Wajib diisi!']" class="form-input" :min="1" type="number" @input="() => {
              if (classLms.total_quiz < 0) {
                classLms.total_quiz = 1;
              }
            }
              " dense clearable></v-text-field>
        </div>
        <div class="content-form">
          <p>Jadwal Konsultasi</p>
          <v-row no-gutters class="pt-2">
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="3" class="pt-2"> Mulai </v-col>
                <v-col cols="8">
                  <v-text-field :disabled="isWatch" outlined type="datetime-local"
                    v-model="form.class.start_consultation_date" class="form-input" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col cols="3" class="pt-2"> Selesai </v-col>
                <v-col cols="8">
                  <v-text-field :disabled="isWatch" outlined type="datetime-local"
                    v-model="form.class.end_consultation_date" class="form-input" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div class="content-form mb-6" v-if="categoryId !== 6">
          <p>Instruksi Final Projek</p>
          <vue-editor v-model="classLms.instruction_final_project" :editorToolbar="$helpers.customToolBar()"
            class="form-input" v-if="!isWatch"></vue-editor>
          <div v-else class="watch-ctn">
            <div v-html="classLms.instruction_final_project"></div>
          </div>
        </div>
      </div>

      <div class="spacing-ctn" v-if="categoryId === 5 || categoryId === 6"></div>
      <div class="form-ctn" v-if="categoryId === 5 || categoryId === 6">
        <div class="content-form">
          <p>Silabus Bootcamp</p>
          <div class="list-event mt-2" v-for="(item, idx) in form.class_detail.summary_syllabus" :key="idx">
            <div class="icon-plus-bc" @click="removeBootcampSylabusses(idx)" v-if="idx > 0">
              <trash-icon :width="25" :height="25" />
            </div>
            <b>Bagian {{ idx + 1 }}</b>
            <v-text-field :disabled="isWatch" outlined v-model="form.class_detail.summary_syllabus[idx].name"
              placeholder="Title" class="form-input mt-2 no-space" dense></v-text-field>
            <vue-editor v-model="form.class_detail.summary_syllabus[idx].value"
              :editorToolbar="$helpers.customToolBar()" class="form-input" v-if="!isWatch"></vue-editor>
          </div>
          <div class="add-button" @click="addBootcampSylabusses" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Sesi</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn" v-if="categoryId !== 6"></div>
      <div class="form-ctn" v-if="categoryId !== 6">
        <div class="content-form">
          <p>Daftar Kopetensi</p>
          <div class="list-event" v-for="(item2, idx) in form.class_detail.list_competency" :key="idx">
            <div class="icon-plus-bc" @click="form.class_detail.list_competency.splice(idx, 1)">
              <trash-icon :width="25" :height="25" />
            </div>
            <b>Ke {{ idx + 1 }}</b>
            <v-text-field :disabled="isWatch" outlined v-model="item2.title" class="form-input mt-2 no-space"
              placeholder="Skkni" dense></v-text-field>
            <div class="list-event" v-for="(item3, idx3) in item2.list" :key="idx3">
              <div class="icon-plus-bc" @click="form.class_detail.list_competency[idx3].list.splice(idx, 1)">
                <trash-icon :width="25" :height="25" />
              </div>
              <v-text-field :disabled="isWatch" outlined v-model="item3.code" class="form-input mt-2 no-space"
                placeholder="Nomor / Code" dense></v-text-field>
              <v-text-field :disabled="isWatch" outlined placeholder="Deskripsi" v-model="item3.description"
                class="form-input no-space" dense></v-text-field>
            </div>
            <div class="add-button" @click="addListKopetensiChild(idx)" v-if="!isWatch">
              <div class="line"></div>
              <div class="button">+ Tambah Kopetensi</div>
              <div class="line"></div>
            </div>
          </div>
          <div class="add-button" @click="addListKopetensi" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Kopetensi</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn" v-if="categoryId !== 6"></div>
      <div class="form-ctn" v-if="categoryId !== 6">
        <div class="content-form">
          <p>Materi Silabus</p>
          <div class="list-event" v-for="(item, idx) in syllabuses" :key="idx">
            <div class="icon-plus" @click="addSubSession(idx)">
              <add-icon />
            </div>
            <b>Sesi {{ idx + 1 }}</b>
            <div v-for="(item2, idx2) in item" :key="idx2">
              <v-text-field :disabled="isWatch" outlined v-model="syllabuses[idx][idx2]"
                class="form-input mt-2 no-space" dense :clearable="idx != 0 || idx2 > 0"
                @click:clear="onRemoveEvent(idx, idx2)"></v-text-field>
            </div>
          </div>
          <div class="add-button" @click="addSubSession(null)" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Sesi</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn" v-if="categoryId !== 6"></div>
      <div class="form-ctn" v-if="categoryId !== 6">
        <div class="content-form">
          <p>Link Materi PerSesi</p>
          <div class="list-event" v-for="(item2, idx) in classLms.materials_link" :key="idx">
            <div class="icon-plus-bc" @click="classLms.materials_link.splice(idx, 1)" v-if="idx > 0">
              <trash-icon :width="25" :height="25" />
            </div>
            <b>Ke {{ idx + 1 }}</b>
            <v-text-field :disabled="isWatch" outlined v-model="item2.iteration" class="form-input mt-2 no-space"
              placeholder="Nomor / Code" dense></v-text-field>
            <v-text-field :disabled="isWatch" outlined placeholder="Deskripsi" v-model="item2.description"
              class="form-input no-space" dense></v-text-field>
          </div>
          <div class="add-button" @click="addListMateri" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Link Materi</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn" v-if="categoryId === 4"></div>
      <div class="form-ctn" v-if="categoryId === 4">
        <div class="content-form">
          <p>Instruksi TPM</p>
          <div class="list-event mt-2" v-for="(item, idx) in classLms.instruction_tpm" :key="idx">
            <div class="icon-plus-bc" @click="classLms.instruction_tpm.splice(idx, 1)" v-if="idx > 0">
              <trash-icon :width="25" :height="25" />
            </div>
            <v-text-field :disabled="isWatch" outlined label="Sesi Ke" v-model="classLms.instruction_tpm[idx].iteration"
              class="form-input no-space" dense></v-text-field>
            <vue-editor v-model="classLms.instruction_tpm[idx].description" :editorToolbar="$helpers.customToolBar()"
              class="form-input" v-if="!isWatch"></vue-editor>
          </div>
          <div class="add-button" @click="addInstructionTpm" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah instruksi</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn mt-5" v-if="categoryId === 5 || categoryId === 6"></div>
      <div class="form-ctn" v-if="categoryId === 5 || categoryId === 6">
        <div class="content-form">
          <p>Benefit</p>
          <div class="list-event" v-for="(item, idx) in form.class_detail.benefits_req" :key="idx"
            style="margin-left: 0">
            <v-text-field :disabled="isWatch" outlined v-model="form.class_detail.benefits_req[idx]"
              class="form-input mt-2 no-space" dense :clearable="idx != 0"
              @click:clear="onRemoveBenefitEvent(idx)"></v-text-field>
          </div>
          <div class="add-button" @click="addBenefit(null)" v-if="!isWatch">
            <div class="line"></div>
            <div class="button">+ Tambah Sesi</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div class="spacing-ctn mt-5"></div>
      <div class="form-ctn">
        <p class="berkas-title">Informasi Lainnya</p>
        <div class="border-price">
          <p>Kelas Publik</p>
          <div class="content-form">
            <p>Harga</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.class_detail.price"
              :rules="[!!form.class_detail.price || 'Wajib diisi!']" class="form-input" prefix="IDR" :min="1000"
              type="number" @input="() => {
                if (form.class_detail.price < 0) {
                  form.class_detail.price = 1000;
                }
              }
                " dense clearable></v-text-field>
          </div>
          <div class="content-form" v-if="form.class_detail.is_discount">
            <p>Potongan harga</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.class_detail.discount_price"
              :rules="[!!form.class_detail.discount_price || 'Wajib diisi!']" class="form-input" :min="1000"
              prefix="IDR" type="number" @input="() => {
                if (form.class_detail.discount_price < 0) {
                  form.class_detail.discount_price = 1000;
                }
              }
                " dense clearable></v-text-field>
          </div>
        </div>
        <div class="border-price mt-4" v-if="categoryId === 1">
          <p>Kelas Privat</p>
          <div class="content-form">
            <p>Harga</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.class_detail.private_price"
              :rules="[!!form.class_detail.private_price || 'Wajib diisi!']" class="form-input" prefix="IDR" :min="1000"
              type="number" @input="() => {
                if (form.class_detail.private_price < 0) {
                  form.class_detail.private_price = 1000;
                }
              }
                " dense clearable></v-text-field>
          </div>
          <div class="content-form" v-if="form.class_detail.is_discount">
            <p>Potongan harga</p>
            <v-text-field :disabled="isWatch" outlined v-model="form.class_detail.private_discount_price"
              :rules="[!!form.class_detail.private_discount_price || 'Wajib diisi!']" class="form-input" :min="1000"
              prefix="IDR" type="number" @input="() => {
                if (form.class_detail.private_discount_price < 0) {
                  form.class_detail.private_discount_price = 1000;
                }
              }
                " dense clearable></v-text-field>
          </div>
        </div>

        <div class="content-form mt-5">
          <p>Apakah Diskon?</p>
          <v-select :disabled="isWatch" class="form-input" :items="list_disc" v-model="form.class_detail.is_discount"
            solo :item-text="'name'" :item-value="'value'">
          </v-select>
        </div>
        <div class="content-form" v-if="form.class_detail.is_discount">
            <p>Durasi Earlybird</p>
            <v-row no-gutters class="pt-2">
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="4" class="pt-2"> Mulai </v-col>
                  <v-col cols="8">
                    <v-text-field :disabled="isWatch" outlined type="datetime-local" :rules="[
                      !!form.class_detail.start_discount_date || 'Wajib diisi!',
                    ]" v-model="form.class_detail.start_discount_date" class="form-input" placeholder="00:00"
                      dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="5 ml-5">
                <v-row no-gutters>
                  <v-col cols="3" class="pt-2"> Selesai </v-col>
                  <v-col cols="9">
                    <v-text-field :disabled="isWatch" outlined type="datetime-local" :rules="[
                      !!form.class_detail.end_discount_date || 'Wajib diisi!'
                    ]" v-model="form.class_detail.end_discount_date" class="form-input" placeholder="00:00"
                      dense></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        <div class="content-form">
          <p>Pendaftaran di tutup pada</p>
          <v-text-field :disabled="isWatch" outlined type="date" :rules="[
            !!form.class.registration_close_date || 'Wajib diisi!',
          ]" v-model="form.class.registration_close_date" class="form-input" dense></v-text-field>
        </div>
        <div class="content-form">
          <p>Target Audience</p>
          <div style="display: flex; gap: 10px; flex-wrap: wrap; margin: 10px 0">
            <v-text-field :disabled="isWatch" v-for="(data, idx) in form.class_detail.target_audience" :key="idx"
              outlined v-model="form.class_detail.target_audience[idx].level" class="form-input no-space" dense
              style="max-width: 290px"></v-text-field>
            <Button name="+" width="40px" height="35px" title="Add Tag" v-if="!isWatch"
              @click="() => form.class_detail.target_audience.push({ level: '' })" />
            <Button name="-" width="40px" height="35px" title="Remove Tag" @click="() =>
              form.class_detail.target_audience.splice(
                form.class_detail.target_audience.length - 1,
                1
              )
              " outline v-if="form.class_detail.target_audience.length && !isWatch" />
          </div>
        </div>
      </div>
      <div class="submit-box" v-if="!isWatch">
        <Button name="PUBLISH" width="260px" @click="onSubmit" />
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Loading from "@/components/ilustration/Loading.vue";
import AddIcon from "@/components/icons/Add.vue";
import Button from "@/components/Button.vue";
import TrashIcon from "@/components/icons/Trash.vue";
import moment from "moment";
import Chip from "@/components/Chip.vue";
import Popup from '@/components/others/Popup.vue';
import ImageModal from "@/components/ImageModal.vue";

export default {
  name: "ClassForm",
  components: {
    Loading,
    VueEditor,
    AddIcon,
    TrashIcon,
    Popup,
    Button,
    Chip,
    ImageModal
  },
  data() {
    return {
      id: null,
      modal: false,
      name: "",
      isPopup: false,
      isLoading: false,
      isWatch: false,
      isEdit: false,
      img_thumb: null,
      img_dekstop: null,
      img_mobile: null,
      method: [
        {
          name: "Daring (Online via zoom)",
          value: "Online",
        },
        {
          name: "Luring (di tempat pelatihan)",
          value: "Offline",
        },
        {
          name: "Online/Offline",
          value: "Online/Offline",
        },
      ],
      list_active: [
        {
          name: "Aktif",
          value: true,
        },
        {
          name: "Non aktif",
          value: false,
        },
      ],
      list_disc: [
        {
          name: "Diskon",
          value: true,
        },
        {
          name: "Tidak Diskon",
          value: false,
        },
      ],
      instructors: [],
      class_category: [],
      class_sub_category: [
        {
          id: 1,
          name: "KELAS PROGRAMMING",
        },
        {
          id: 2,
          name: "KELAS DATA",
        },
        {
          id: 3,
          name: "KELAS LAINNYA",
        },
      ],
      levels: [
        "Basic",
        "Basic to Intermediate",
        "Intermediate",
        "Intermediate to Expert",
        "Expert",
      ],
      syllabuses: [[""]],
      categoryId: Number(this.$route.params.id_category),
      classLms: {
        instruction_final_project: "",
        total_quiz: 0,
        rubrik_url: "",
        instruction_tpm: [],
        materials_link: [],
      },
      program_category: [
        {
          name: "General Training",
          value: "General"
        },
        {
          name: "Certified Training",
          value: "Certified"
        },
      ],
      category_event: [
        {
          name: "Workshop",
          value: "Workshop"
        },
        {
          name: "Webinar",
          value: "Webinar"
        },
        {
          name: "Mini Bootcamp",
          value: "Mini Bootcamp"
        },
        {
          name: "Bootcamp",
          value: "Bootcamp"
        },
        {
          name: "Training",
          value: "Training"
        },
      ],
      form: {
        class: {
          name: "",
          image_url: "",
          description: "",
          meta_description: "",
          level: "Basic",
          class_sub_category_id: 1,
          slug_name: "",
          thumbnail_url: "",
          small_image_url: "",
          learning_link: "",
          consultancy_link: "",
          consultancy_schedule: "",
          group_chat_link: "",
          instructor_id: 0,
          material: "",
          method: "Online",
          total_audience: 1,
          start_time: "",
          end_time: "",
          prefix_code: "",
          is_recommend: false,
          // support
          start_consultation_date: "",
          end_consultation_date: "",
          collaboration_feat: "",
          type_class: "",
          type: "",
          registration_close_date: ""
        },
        class_detail: {
          class_id: 0,
          batch_id: 0,
          price: 1000,
          discount_price: 0,
          private_price: 1000,
          private_discount_price: 0,
          session_total: 1,
          days: "",
          hours: "",
          start_discount_date: "",
          end_discount_date: "",
          private_start_discount_date: "",
          private_end_discount_date: "",
          is_discount: false,
          learning_objective: "",
          benefits_req: [""],
          learning_info_list: [],
          why_info_list: [],
          requirements: [
            { name: "Peserta memiliki gawai untuk pelaksanaan kelas yang terdiri dari smartphone atau laptop" },
            { name: "Peserta harus mengakses kelas melalui LMS HiColleagues" },
            { name: "Peserta wajib datang di seluruh pertemuan dan mengikuti setiap rangkaian pelatihan, termasuk evaluasi berupa test maupun unjuk keterampilan" },
            { name: "Memiliki akses internet yang baik untuk mendukung proses pembelajaran" },
            { name: "Peserta tidak buta huruf" },
            { name: "Pria dan wanita dengan rentang usia 18 - 57 tahun" },
            { name: "Minimal pendidikan dari peserta adalah SMA sederajat" },
          ],
          summary_syllabus: [
            {
              name: "",
              value: "",
            },
          ],
          skkni:
            "SKKNI Nomor 183 Tahun 2016 Golongan Pokok Aktivitas Administrasi Kantor, Aktivitas Penunjang Kantor dan Aktivitas Penunjang Usaha Lainnya Bidang Administrasi Profesional",
          learning_method: "Metode ajar yang digunakan adalah ceramah interaktif melalui webinar, penugasan, dan bahan bacaan dengan penugasan praktek",
          special_purpose: "",
          list_competency: [
            {
              title: "",
              list: []
            }
          ],
          syllabuses: [],
          target_audience: [],
        },
      },
      learningInfoList: [
        {
          title: 'Join Community Group',
          desc: 'Tersedia grup Telegram untuk memfasilitasi kolaborasi, konsultasi, dan pertukaran informasi antara peserta, trainer, dan admin grup.',
          icon: '/img/publictraining/ic_jcg.png',
        },
        {
          title: 'Live Class via Zoom',
          desc: 'Peserta akan mengikuti sesi pembelajaran secara daring menggunakan Zoom.',
          icon: '/img/publictraining/ic_lcvz.png',
        },
        {
          title: 'Access to LMS (Learning Management System)',
          desc: 'Peserta mendapatkan akses LMS untuk mempermudah proses pembelajaran selama bootcamp.',
          icon: '/img/publictraining/ic_atlms.png',
        },
        {
          title: 'Practical Learning',
          desc: 'Peserta belajar melalui materi dan langsung menerapkannya dalam pengerjaan proyek yang relevan.',
          icon: '/img/publictraining/ic_pl.png',
        },
        {
          title: 'Sharing Session',
          desc: 'Peserta dapat berdiskusi dan mendengarkan pengalaman langsung dari Trainer ketika sesi pembelajaran berlangsung.',
          icon: '/img/publictraining/ic_ss.png',
        },
        {
          title: 'Live Consultation',
          desc: 'Peserta berkesempatan mendapatkan bimbingan dan mentoring peibadi langsung dari Trainer.',
          icon: '/img/publictraining/ic_lc.png',
        },
        {
          title: 'Final Project & Examp',
          desc: 'Pada akhir pembelajaran, peserta wajib untuk mengerjakan proyek akhir dan ujian akhir untuk mengukur pemahaman peserta.',
          icon: '/img/publictraining/ic_fpe.png',
        },
      ],
      whyInfoList: [
        {
          title: 'Intensive Program',
          desc: 'Durasi program 1 bulan secara efisien mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri.',
          image: '/img/bootcamp/ic_intensive_program.png',
        },
        {
          title: 'Competent Trainer',
          desc: 'Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan.',
          image: '/img/bootcamp/ic_support_3.png',
        },
        {
          title: 'Learning Management System',
          desc: 'Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat.',
          image: '/img/bootcamp/ic_support_1.png',
        },
        {
          title: 'E-Certificate',
          desc: 'Sertifikat sebagai bukti validasi peserta telah menyelesaikan program & diakui oleh industri sebagai nilai pendukung dalam berkarir.',
          image: '/img/bootcamp/ic_sertifikat_kompetensi.png',
        },
        {
          title: 'Job Connector',
          desc: 'Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta program.',
          image: '/img/bootcamp/ic_support_2.png',
        },
        {
          title: 'Installment Option Available',
          desc: 'Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta.',
          image: '/img/bootcamp/ic_installment.png',
        },
      ]
    };
  },

  computed: {
    validData() {
      return (
        this.form.class.name &&
        this.form.class.image_url &&
        this.form.class.description &&
        this.form.class.level &&
        this.form.class.slug_name &&
        this.form.class.thumbnail_url &&
        this.form.class_detail.learning_objective
      );
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataClass();
      this.getClassLms();
    },
    addInstructionTpm() {
      this.classLms.instruction_tpm.push({
        iteration: this.classLms.instruction_tpm.length + 1,
        description: "",
      });
    },
    async getIntructure() {
      const resp = await this.$web_http.get("/v1/instructor");
      this.instructors = resp.data.data.instructors;
    },
    async getClassCategory() {
      try {
        const resp = await this.$crm_http.get("/v1/class-category");
        this.class_category = resp.data.data.class_categories;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getSubClassCategory() {
      this.class_sub_category = []
      try {
        const resp = await this.$web_http.get(
          "/v1/class-sub-category/" + this.categoryId
        );
        const data = resp.data.data
        if (data && data.length) {
          this.class_sub_category = data
        }
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getDataClass() {
      const resp = await this.$crm_http.get("/v1/class/" + this.id);
      const data = resp.data.data;
      this.form = {
        class: {
          ...data.class,
          instructor_id: data.class.instructor.ID,
          class_category_id: this.categoryId,
          class_sub_category_id: data.class.class_sub_category.ID,
          start_time: data.class.class_detail.hours.split(" - ")[0],
          end_time: data.class.class_detail.hours
            .split(" - ")[1]
            .replace(" WIB", ""),
          start_consultation_date: this.$helpers.getUniqDate(
            data.class.consultancy_schedule.split(" - ")[0], "YYYY-MM-DDThh:mm:ss"),
          end_consultation_date: this.$helpers.getUniqDate(
            data.class.consultancy_schedule.split(" - ")[1], "YYYY-MM-DDThh:mm:ss"),
          registration_close_date: moment(data.class.registration_close_date).format("YYYY-MM-DD")
        },
        class_detail: {
          ...data.class.class_detail,
          start_discount_date: this.$helpers.getUniqDate(data.class.class_detail.start_discount_date, "YYYY-MM-DDThh:mm"),
          end_discount_date: this.$helpers.getUniqDate(data.class.class_detail.end_discount_date, "YYYY-MM-DDThh:mm"),
          private_start_discount_date: this.$helpers.getUniqDate(data.class.class_detail.private_start_discount_date, "YYYY-MM-DDThh:mm"),
          private_end_discount_date: this.$helpers.getUniqDate(data.class.class_detail.private_end_discount_date, "YYYY-MM-DDThh:mm"),
          benefits_req: data.class.class_detail.benefits
            ? data.class.class_detail.benefits
            : [],
          summary_syllabus: data.class.class_detail.summary_syllabus
            ? data.class.class_detail.summary_syllabus
            : [],
        },
      };

      this.syllabuses = this.form.class_detail.syllabuses.map((item) => {
        const strg = [];
        const ct1 = item.description;
        const ct2 = ct1.split("<li>");
        for (let i = 1; i < ct2.length; i++) {
          let fltr = ct2[i].replace("</li>", "");
          fltr = fltr.replace(",", "");
          fltr = fltr.replace("</ul>", "");
          strg.push(fltr);
        }
        return strg;
      });
    },
    async getClassLms() {
      try {
        const resp = await this.$lms_http.get("/v1/class/" + this.id);
        this.classLms = resp.data.data.class;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    removeBootcampSylabusses(idx) {
      this.form.class_detail.summary_syllabus.splice(idx, 1);
    },
    addBootcampSylabusses() {
      this.form.class_detail.summary_syllabus.push({
        name: "",
        value: "",
      });
    },
    addBenefit() {
      this.form.class_detail.benefits_req.push([""]);
    },
    addListKopetensi() {
      this.form.class_detail.list_competency.push({
        title: "",
        list: []
      });
    },
    addListKopetensiChild(idx) {
      this.form.class_detail.list_competency[idx].list.push({
        code: "",
        description: ""
      })
    },
    addListRequirements() {
      this.form.class_detail.requirements.push({
        name: ""
      })
    },
    addListMateri() {
      this.classLms.materials_link.push({
        iteration: this.classLms.materials_link.length + 1,
        description: "",
      });
    },
    onRemoveBenefitEvent(i) {
      this.form.class_detail.benefits_req.splice(i, 1);
    },
    addSubSession(i) {
      if (i !== null) {
        this.syllabuses[i].push("");
        return;
      }
      this.syllabuses.push([""]);
      this.form.class_detail.session_total = this.syllabuses.length;
    },
    onRemoveEvent(i, i2) {
      this.syllabuses[i].splice(i2, 1);
      if (this.syllabuses[i].length === 0) {
        this.syllabuses.splice(i, 1);
        this.form.class_detail.session_total = this.syllabuses.length;
      }
    },
    async onSubmit() {

      this.form.class_detail.syllabuses = [];
      let nama = this.form.class.name.toLowerCase();
      nama = nama.replace(/[^A-Z0-9]/gi, "");
      this.isLoading = true;
      this.syllabuses.forEach((item, idx) => {
        this.form.class_detail.syllabuses.push({
          description: `<ul>${item
            .map((item2) => `<li>${item2}</li>`)
            .join("")}</ul>`,
          session: "Pertemuan " + (idx + 1),
        });
      });
      if (this.img_thumb) {
        const imageForm = new FormData();
        imageForm.append("attachment", this.img_thumb);
        const webRes = await this.$crm_http.post(
          "/v1/upload-file",
          imageForm
        );
        this.form.class.thumbnail_url =
          webRes.data.data.upload_image_response.url_w_query
        imageForm.delete("attachment");
      }
      if (this.img_dekstop) {
        const imageForm = new FormData();
        imageForm.append("attachment", this.img_dekstop);
        const webRes = await this.$crm_http.post(
          "/v1/upload-file",
          imageForm
        );
        this.form.class.image_url =
          webRes.data.data.upload_image_response.url_w_query
        imageForm.delete("attachment");
      }
      if (this.img_mobile) {
        const imageForm = new FormData();
        imageForm.append("attachment", this.img_mobile);
        const webRes = await this.$crm_http.post(
          "/v1/upload-file",
          imageForm
        );
        this.form.class.small_image_url =
          webRes.data.data.upload_image_response.url_w_query
        imageForm.delete("attachment");
      }
      this.form = {
        class: {
          ...this.form.class,
          class_category_id: Number(this.categoryId),
          class_sub_category_id: Number(this.form.class.class_sub_category_id),
          total_audience: Number(this.form.class.total_audience),
          instructor_id: Number(this.form.class.instructor_id),
          consultancy_schedule: this.$helpers.getUniqDate(this.form.class.start_consultation_date, "YYYY-MM-DDThh:mm") + " - " + this.$helpers.getUniqDate(this.form.class.end_consultation_date, "YYYY-MM-DDThh:mm"),
          slug_name: nama.replace(" ", "-"),
        },
        class_detail: {
          ...this.form.class_detail,
          price: Number(this.form.class_detail.price),
          discount_price: Number(this.form.class_detail.discount_price),
          private_price: Number(this.form.class_detail.private_price),
          private_discount_price: Number(this.form.class_detail.private_discount_price),
          benefits: this.form.class_detail.benefits_req,
          hours:
            this.form.class.start_time +
            " - " +
            this.form.class.end_time +
            " WIB",
        },
      };

      const classLmsReq = {
        class_id: 0,
        name: this.form.class.name,
        level: this.form.class.level,
        image_url: this.form.class.image_url,
        category: Number(this.categoryId),
        slug_name: this.form.class.slug_name,
        learning_link: this.form.class.learning_link,
        group_chat_link: this.form.class.group_chat_link,
        material_link: this.form.class.material,
        learning_method: this.form.class.method,
        final_project_link: "",
        days: this.form.class_detail.days,
        hours: `${this.form.class.start_time} - ${this.form.class.end_time} WIB`,
        syllabuses: this.form.class_detail.syllabuses,
        instruction_final_project: this.classLms.instruction_final_project,
        materials_link: this.classLms.materials_link,
        total_quiz: Number(this.classLms.total_quiz),
        total_tpm: this.classLms.instruction_tpm.length,
        rubrik_url: this.classLms.rubrik_url,
        instruction_tpm: this.classLms.instruction_tpm,
        prefix_code: this.form.class.prefix_code,
        skkni: this.form.class_detail.skkni,
        list_competency: this.form.class_detail.list_competency,
      };

      // important declare
      if (!this.form.class_detail.start_discount_date)
        this.form.class_detail.start_discount_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      if (!this.form.class_detail.end_discount_date)
        this.form.class_detail.end_discount_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      if (!this.form.class_detail.private_start_discount_date)
        this.form.class_detail.private_start_discount_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      if (!this.form.class_detail.private_end_discount_date)
        this.form.class_detail.private_end_discount_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      if (!this.form.class.registration_close_date)
        this.form.class.registration_close_date = moment(new Date()).format(
          "YYYY-MM-DD"
        );
      if (this.id) {
        // edit class
        try {
          await this.$crm_http.put(
            "/v1/class/" + this.form.class.id,
            this.form
          );
          if (this.categoryId === 1 || this.categoryId === 4 || this.categoryId === 5) {
            await this.$lms_http.put(
              "/v1/class/" + this.form.class.id,
              classLmsReq
            );
          }
        } catch (err) {
          this.isLoading = false;
          this.message = err.response.data.data
          this.isPopup = true
          return
        }
      } else {
        // create class
        try {
          const resp = await this.$crm_http.post("/v1/class", this.form);
          classLmsReq.class_id = resp.data.data;
          if (
            this.categoryId === 1 ||
            this.categoryId === 4 ||
            this.categoryId === 5
          ) {
            await this.$lms_http.post("/v1/class", classLmsReq);
          }
        } catch (err) {
          this.isLoading = false;
          this.message = err.response.data.data
          this.isPopup = true
          return
        }
      }
      this.isLoading = false;
      this.$router.push(`/class-category/${this.categoryId}/class`);
    },
  },
  mounted() {
    this.getClassCategory();
    // this.getSubClassCategory();
    this.getIntructure();
    this.checkingType();
  },
};
</script>
<style scoped>
::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
  display: none;
}

.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}

.cart-form {
  position: relative;
  background-color: white;
  width: 50%;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.border-price {
  border-radius: 18px;
  padding: 20px;
  border: 1px solid rgb(221, 217, 217);
}

.back-icon {
  position: absolute;
  top: 0;
  right: -50px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.content-form {
  margin-top: -5px;
}

.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}

.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.spacing-switch {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 15px 0;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}

.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}

.form-ctn {
  padding: 0 40px;
}

.spacing-ctn {
  margin-bottom: 30px;
  width: 100%;

  background-color: #f5f6fa;
  height: 10px;
}

.berkas-title {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}

.list-event {
  margin-top: 15px;
  position: relative;
  margin-left: 60px;
}

.icon-plus {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
}

.icon-plus-bc {
  position: absolute;
  bottom: 20px;
  left: -56px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f6fa;
}

.add-button {
  margin: 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.add-button .line {
  width: 40%;
  background-color: #d3d6e1;
  height: 2px;
}

.add-button .button {
  min-width: max-content;
  padding: 7px 20px;
  cursor: pointer;
  border-radius: 16px;
  border: 1px solid #d3d6e1;
}

.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}


::v-deep .form-select .v-input__slot {
  height: 54px !important;
  margin-bottom: 20px;
  background-color: #ffffff;
}

::v-deep .form-select .v-text-field__details {
  display: none;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
