<template>
  <div style="margin: 41px 40px">
    <Modal
      width="40%"
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
    >
      <h1 class="modal-title">Konfirmasi Deactive Data</h1>
      <p style="margin: 20px 0px" class="detail-value">
        Apakah kamu yakin ingin menonaktifkan data transaksi pelanggan ini?
      </p>
      <div class="btn-container-2">
        <button
          @click.prevent="getmodal(false)"
          class="color-text-gray btn-batalkan"
        >
          BATALKAN
        </button>
        <button
          @click.prevent="UpdateData()"
          style="margin-left: 10px"
          class="color-text-gray btn-simpan-2"
        >
          SIMPAN DATA
        </button>
      </div>
    </Modal>
    <div class="page">
      <h5 class="text-page" style="margin-right: 10px">Finance</h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page" style="margin: 10px">(B2C) Event</h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page" style="margin-left: 10px">
        {{
          this.categorys
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        }}
      </h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page long-text" style="margin-left: 10px">
        {{ this.data.event_name }}
      </h5>
      <Arrow :fill="'#4456b4'" :direct="'left'" />
      <h5 class="text-page color-text-gray" style="margin-left: 10px">
        Ubah Data User
      </h5>
    </div>
    <div class="body">
      <h2 class="title">Ubah Data Transaksi User</h2>

      <form>
        <h2 class="title-2">Info Pembelian</h2>

        <div
          :class="{
            'grid-form': true,
            'grid-form-buttom': data.status === 'PAID',
          }"
        >
          <label class="label">
            No. Invoice
            <input
              class="input-search"
              type="text"
              name="search1"
              id="search1"
              :placeholder="data.no_invoice"
              disabled
          /></label>
          <label class="label">
            Waktu Pembelian
            <input
              class="input-search"
              type="text"
              name="search1"
              id="search1"
              :placeholder="dateRange.startDate"
              disabled
          />
          </label>
          <label class="label">
            Program
            <input
              class="input-search"
              type="text"
              name="search2"
              id="search2"
              disabled
              :placeholder="data.event_name"
          /></label>
          <label class="label">
            Tipe Kelas
            <input
              class="input-search"
              type="text"
              name="search3"
              id="search3"
              disabled
              :placeholder="data.class_type"
          /></label>
          <label class="label">
            Voucher
            <input
              class="input-search"
              type="text"
              name="search4"
              id="search4"
              disabled
              :placeholder="data.voucher"
          /></label>
          <label class="label">
            Nominal Transaksi
            <div style="" class="data-box">
              <h5 style="color: #36373f" class="label-2">
                IDR.{{ data.normal_price }} - IDR.{{ data.discount }} - IDR.{{
                  data.current_price
                }}
                =<span style="color: red">
                  IDR.{{
                    data.normal_price - data.discount - data.current_price
                  }}</span
                >
              </h5>
            </div>
          </label>
        </div>

        <h2 class="title-2">Info Data Diri</h2>

        <div class="grid-form-2">
          <label class="label"> Title </label>
          <div>
            <label class="label-2">
              Tuan
              <input
                :checked="data.gender === 'Tuan'"
                type="radio"
                value="Tuan"
                v-model="data.gender"
              />
            </label>
            <label class="label-2 ml">
              Nyonya
              <input
                :checked="data.gender === 'Nyonya'"
                type="radio"
                value="Nyonya"
                v-model="data.gender"
              />
            </label>
            <label class="label-2 ml">
              Nona
              <input
                :checked="data.gender === 'Nona'"
                type="radio"
                value="Nona"
                v-model="data.gender"
              />
            </label>
          </div>

          <label class="label">
            Nama Lengkap
            <input class="input-search" type="text" v-model="data.user_name"
          /></label>

          <label class="label">
            No. Handphone
            <div style="margin-top: 10px" class="d-flex">
              <div class="country-code">
                <div class="flag"></div>
                <span class="code">+62</span>
              </div>
              <input
                class="input-search"
                type="text"
                v-model="data.phone"
              /></div
          ></label>

          <label class="label">
            Email
            <input class="input-search" type="text" v-model="data.email"
          /></label>

          <label class="label">
            Tanggal Lahir
            <date-range-picker
              :ranges="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :singleDatePicker="true"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy HH:mm:ss' }"
              v-model="dateRange2"
              :autoApply="true"
              :control-container-class="true"
              @start-selection="logEvent('event: startSelection', $event)"
              @finish-selection="logEvent('event: finishSelection', $event)"
            >
              <template v-slot:input="{ startDate }">
                <div class="custom-control-container">
                  {{
                    startDate
                      .toLocaleDateString("id-ID", {
                        day: "2-digit",
                        month: "2-digit",
                      })
                      .replace(/\//g, "/")
                  }}/
                  {{ String(startDate.getFullYear()).slice(-2) }}
                </div>
              </template>
            </date-range-picker>
          </label>
        </div>

        <label style="margin: 36px 0px" class="label">
          Ubah Status
          <select class="input-search" v-model="data.status">
            <option value="DRAFT">Draft</option>
            <option value="WAITING_FOR_PAYMENT">Menunggu Pembayaran</option>
            <option value="PAID">Pembayaran Lunas</option>
            <option value="CANCEL">Cancel</option>
            <option value="EXPIRED">Expired</option>
          </select>
        </label>

        <label
          v-if="data.status === 'CANCEL'"
          style="margin-top: 36px"
          class="label"
        >
          Alasan
          <textarea
            style="height: 171px"
            class="input-search"
            v-model="data.notes"
            placeholder="Tulis di sini...."
          />
        </label>

        <div class="btn-container">
          <button
            @click.prevent="goBack"
            class="ml-auto color-text-gray btn-batalkan"
          >
            BATALKAN
          </button>
          <button
            style="margin-left: 10px"
            @click.prevent="getmodal(true)"
            :class="{
              'color-text-gray': true,
              'btn-simpan-2': true,
            }"
          >
            SIMPAN DATA
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Arrow from "../../../../../components/icons/Arrow.vue";
import DateRangePicker from "vue2-daterange-picker";
import Modal from "@/components/Modal.vue";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";


export default {
  props: ["id", "categorys"],
  data() {
    return {
      isModalDetail: false,

      dateRange: {
        startDate: "2019-12-26",
      },
      dateRange2: {
        startDate: "2019-12-26",
      },
      data: null,
    };
  },
  components: { Arrow, DateRangePicker, Modal },
  methods: {
    async fetchTransactionEvent() {
      try {
        const response = await this.$crm_http.get(`/v1/transaction/${this.id}`)
        this.data = response.data.data;
        this.dateRange.startDate = response.data.data.datetime_purchased;
        this.dateRange2.startDate = response.data.data.birthday;
        this.isChecked = response.data.data.status;
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },

    getmodal(e) {
      this.isModalDetail = e;
    },
    goBack() {
      window.history.back();
    },
    async UpdateData() {
      try {
        if (this.data.status === "CANCEL" && this.data.notes === "") {
          return alert("gagal notes kosong");
        }
        await this.$crm_http.put(`/v1/transaction/${this.id}`, {
              gender: this.data.gender,
              full_name: this.data.user_name,
              phone: this.data.phone,
              email: this.data.email,
              birthday: moment(this.dateRange2.startDate).format("YYYY-MM-DD"),
              status: this.data.status,
              notes: this.data.status !== "CANCEL" ? "" : this.data.notes,
            })
        this.fetchTransactionEvent();
        this.getmodal(false);
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      }
    },
  },
  mounted() {
    this.fetchTransactionEvent();
  },
};
</script>

<style scoped>
.data-box {
  width: 100%;
  height: 62px;
  border: 1px solid #d3d6e1;
  text-align: left;
  border-radius: 10px;
  padding: 16px 20px;
  font-weight: 400;
  font-size: 14px;
  color: #575966;
  display: flex;
  align-items: center;
  height: 100%;
}
.detail-value {
  font-weight: 600;
  color: #535353;
  font-size: 16px;
}
.modal-title {
  font-size: 24px;
  font-weight: 700;
}
.country-code {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  background-color: #f4f6fa;
  width: 105px;
  margin-right: 12px;
}
.flag {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border-radius: 9999px;
  background: linear-gradient(to bottom, red 50%, white 50%);
  margin-right: 8px;
}
.code {
  font-size: 16px;
  font-weight: 500;
  color: #4a4a4a;
}
.long-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
}
.body {
  background: #ffffff;
  padding: 42px 40px;
  border-radius: 10px;
  height: fit-content;
}
.page {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.text-page {
  font-weight: 500;
  font-size: 16px;
  color: #162ca2;
}
.grid-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px 1rem;
  width: 100%;
}
.grid-form-2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 36px 1rem;
  width: 100%;
}
.grid-form-buttom {
  padding-bottom: 36px;
  border-bottom: 1.5px solid #acafbf;
}
.color-text-gray {
  color: #acafbf;
}
.title {
  font-weight: 700 !important;
  font-size: 32px;
  color: #36373f;
}
.title-2 {
  font-weight: 500 !important;
  font-size: 18px;
  color: #7b7e8c;
  margin-top: 36px;
  margin-bottom: 10px;
}
.label {
  color: #36373f;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.label-2 {
  color: #36373f;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  gap: 10px;
}
.ml {
  margin-left: 22px;
}
.input-search {
  width: 100%;
  border: 1px solid #d3d6e1;
  height: 62px;
  border-radius: 10px;
  padding: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #575966;
}
.custom-control-container {
  font-weight: 400;
  font-size: 14px;
  color: #575966;
  width: 100%;
  border: 1px solid #d3d6e1;
  height: 62px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn-container {
  display: flex;
  margin-top: 62px;
}
.btn-container-2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.btn-batalkan {
  background-color: #ffffff;
  color: #7b7e8c;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #d3d6e1;
}
.btn-simpan {
  background-color: #acafbf;
  color: #ffffff;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #acafbf;
}
.btn-simpan-2 {
  background-color: #2d46cf;
  color: #ffffff;
  width: 156px;
  height: 44px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #acafbf;
}

.switch-container {
  display: flex;
  align-items: center;
  margin-top: 36px;
  gap: 24px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2d46cf;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
</style>
