<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="back-icon" @click="$router.push('/blog')">
      <round-arrow-back />
    </div>
    <div class="cart-form">
      <h1 style="text-align: center; margin-bottom: 40px">{{ name }}</h1>
      <div class="content-form">
        <p>Judul Artikel</p>
        <v-text-field
          :disabled="isWatch"
          outlined
          v-model="form.title"
          class="form-input"
          placeholder="Tulis judul artikel"
          dense
          :suffix="`${form.title.length}/90`"
          clearable
        ></v-text-field>
      </div>
      <div class="content-form">
        <p>
          Estimasi Baca
          <span style="color: rgb(191, 190, 190); font-weight: normal"
            >(dalam menit)</span
          >
        </p>
        <v-text-field
          :disabled="isWatch"
          outlined
          oninput="if(this.value < 0) this.value = 1;"
          v-model="form.reading_minute"
          class="form-input"
          dense
          type="number"
          clearable
        ></v-text-field>
      </div>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="form.date_release"
        persistent
        width="290px"
      >
        <template v-slot:activator="{on, attrs}">
          <v-text-field
            :disabled="isWatch"
            v-model="form.date_release"
            label="Date Release"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="form.date_release" scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(form.date_release)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
      <div class="content-form">
        <p>Meta Description</p>
        <v-textarea :disabled="isWatch" outlined v-model="form.meta_description" :rules="[!!form.meta_description || 'Wajib diisi!']" class="form-input" placeholder="Meta Description" dense clearable></v-textarea>
      </div>
      <div class="content-form">
        <p>Konten</p>
        <vue-editor
          v-model="form.body"
          :editorToolbar="$helpers.customToolBar()"
          class="form-input"
          v-if="!isWatch"
        ></vue-editor>
        <div v-else class="watch-ctn">
          <div v-html="form.body"></div>
        </div>
      </div>
      <!-- <div class="content-form mt-4">
          <p>Key Search</p>
          <div
            v-for="(item, idx) in form.key"
            :key="idx"
            class="session-nb-3"
          >
            <div
              @click="form.key.push('')"
              style="cursor: pointer"
              v-if="form.key.length - 1 === idx"
            >
              <add-icon />
            </div>
            <v-text-field
              :disabled="isWatch"
              outlined
              v-model="form.key[idx]"
              class="form-input no-space"
              dense
              clearable
            ></v-text-field>
            <div
              @click="form.key.splice(idx, 1)"
              v-if="idx != 0"
              style="cursor: pointer"
            >
              <trash :width="38" :height="38" />
            </div>
          </div>
        </div> -->
      <p class="banner-berkas">Berkas Gambar</p>
      <div class="content-form" v-if="!isWatch">
        <p>Gambar Main</p>
 
        <v-file-input
            v-model="img_web"
            accept="image/*"
            placeholder="Unggah Berkas Gambar"
            class="form-upload"
            prepend-icon=""
            outlined
          >
            <template v-slot:prepend-inner >
              <ImageModal
                :src="form.image_url"
                width="76.31"
                height="37"
                radius="3"
              />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">
            *Ukuran file maks 1 MB
          </p>
      </div>

      <div class="content-form" v-if="!isWatch">
        <p>Thumbnail image</p>
        <v-file-input
            v-model="img_web_second"
            accept="image/*"
            placeholder="Unggah Berkas Gambar"
            class="form-upload"
            prepend-icon=""
            outlined
          >
            <template v-slot:prepend-inner>
              <ImageModal
                :src="form.thumbnail_url"
                width="76.31"
                height="37"
                radius="3"
              />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">
            *Ukuran file maks 1 MB
          </p>
      </div>

      <div class="submit-box" v-if="!isWatch">
        <Button name="PUBLISH" width="260px" @click="onSumbmit" :disabled="!validData" />
      </div>
    </div>
  </div>
</template>

<script>
import {VueEditor} from "vue2-editor";
import Loading from "@/components/ilustration/Loading.vue";
import Button from "@/components/Button.vue";
import RoundArrowBack from "@/components/icons/RoundArrowBack.vue";
// import AddIcon from "@/components/icons/Add.vue";
// import Trash from "@/components/icons/Trash.vue";
import Popup from "@/components/others/Popup.vue";
import ImageModal from "@/components/ImageModal.vue";

export default {
  name: "FormBlog",
  components: {
    RoundArrowBack,
    VueEditor,
    Button,
    // AddIcon,
    // Trash,
    Loading,
    Popup,
    ImageModal
  },
  data() {
    return {
      id: null,
      img_web: null,
      img_web_second: null,
      modal: false,
      name: "",
      isLoading: false,
      isWatch: false,
      isEdit: false,
      message: "",
      isPopup: false,
      form: {
        title: "",
        slug_name: "",
        image_url: "",
        thumbnail_url: "",
        body: "",
        meta_description: "",
        key: [''],
        reading_minute: "1",
        date_release: "",
      },
    };
  },
  computed: {
    validData() {
      return this.form.title && this.form.date_release && this.form.body && this.form.reading_minute
    }
  },
  methods: {
    checkingType() {
        this.name = this.$route.meta.name
        this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
        this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
        this.id = this.$route.params.id;
        if (!this.id) return
        this.getDataBlog();
    },
    async getDataBlog() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/blog/" + this.id, {
          ...this.form,
          reading_minute: Number(this.form.reading_minute),
        });
        this.form = {...resp.data.data.blog, key: resp.data.data.blog.key.length ? resp.data.data.blog.key : ['']};
        this.form.date_release = this.form.date_release.split("T")[0];
        this.isLoading = false;
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    async onSumbmit() {
      this.form.slug_name = this.form.title.toLocaleLowerCase().replace(/[ \W]/g, "-");
      this.isLoading = true;
      try {
        if (this.img_web) {
            const imageForm = new FormData();
            imageForm.append("attachment", this.img_web);
            const webRes = await this.$crm_http.post(
              "/v1/upload-file",
              imageForm
            );
            this.form.image_url =
              webRes.data.data.upload_image_response.url_w_query;
            imageForm.delete("attachment");
          }
          if (this.img_web_second) {
            const imageForm = new FormData();
            imageForm.append("attachment", this.img_web_second);
            const mobileRes = await this.$crm_http.post(
              "/v1/upload-file",
              imageForm
            );
            this.form.thumbnail_url =
              mobileRes.data.data.upload_image_response.url_w_query;
            imageForm.delete("attachment");
          }

        if (this.isEdit) {
          await this.$crm_http.put("/v1/blog/" + this.form.id, {
            ...this.form,
            reading_minute: Number(this.form.reading_minute),
          });
        } else {
          await this.$crm_http.post("/v1/blog", {
            ...this.form,
            reading_minute: Number(this.form.reading_minute),
          });
        }
        setTimeout(() => {
          this.$router.push("/blog");
        }, 2000);
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafaf9;
  padding-bottom: 30px;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  width: 50%;
  border-radius: 30px;
  padding: 20px 40px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 14px !important;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 16px;
  margin: 3px;
  font-weight: bold;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.session-nb-3 {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
.note-file {
  font-weight: 400;
  font-size: 12px;
  color: #b80f0a;
  margin-bottom: 20px !important;
}
::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
  display: none;
}

</style>
