<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <NewModal v-if="isModal" @close="isModal = false" width="26%">
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
        "
      >
        <!-- Image  -->
        <div>
          <img
            src="/img/other/delete-popup.png"
            alt="Delete Data"
            style="width: 200px"
          />
        </div>
        <!-- Detail -->
        <div
          style="
            display: flex;
            flex-direction: column;
            padding: 0px 40px;
            letter-spacing: 0.3px;
            gap: 6px;
          "
        >
          <!-- Title -->
          <div style="color: #36373f; font-size: 21px; font-weight: 700">
            Konfirmasi Hapus
          </div>
          <!-- Description -->
          <div style="color: #535353; font-size: 13px">
            Apakah kamu yakin ingin menghapus event ini?
          </div>
        </div>
        <!-- Button -->
        <div
          style="
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            gap: 16px;
          "
        >
          <!-- Cancel -->
          <Button
            name="Batalkan"
            @click="() => (isModal = false)"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              color: #7b7e8c;
              background-color: #ffffff;
              border: 1px solid #7b7e8c;
            "
          />
          <!-- Delete -->
          <Button
            name="Ya, Hapus"
            @click="onDelete"
            width="120px"
            height="40px"
            style="
              font-size: 14px;
              font-weight: 600;
              background-color: #b80f0a;
              color: white;
            "
          />
        </div>
      </div>
    </NewModal>
    <Modal v-if="isModal2" @close="() => (isModal2 = false)" width="40%">
      <h2 class="text-center">Edit Kategori Event</h2>
      <div class="content-form mt-10">
        <p>Kategori Event</p>
        <v-text-field
          outlined
          v-model="form.name"
          class="form-input"
          placeholder="Masukkan Nama Kategori Event"
          dense
          clearable
          :rules="[!!form.name || 'Wajib diisi!']"
        ></v-text-field>
      </div>
      <div class="content-form">
        <p>Deskripsi</p>
        <v-textarea
          placeholder="Tulis deskripsi ...."
          outlined
          rows="3"
          v-model="form.description"
          :rules="[!!form.description || 'Wajib diisi!']"
          clearable
          class="form-input"
        ></v-textarea>
      </div>
      <div style="display: flex; justify-content: center">
        <Button
          :disabled="validForm"
          name="SIMPAN PERUBAHAN"
          width="230px"
          @click="submitCategory"
        />
      </div>
    </Modal>
    <div class="menus-top">
      <span class="blue-text" @click="$router.push('/event-category')"
        >Kategori Event</span
      >
      <Arrow
        :width="20"
        :height="20"
        direct="left"
        strokeColor="#7B7E8C"
        class="icon"
      />
      <span style="color: #7b7e8c">{{ category.name }}</span>
    </div>

    <div class="head-info">
      <div class="head-info-ttl">
        <div style="display: flex; gap: 10px; align-items: center">
          <h1>{{ category.name }}</h1>
          <div style="cursor: pointer" @click="editCategory">
            <pencil :height="30" />
          </div>
        </div>
        <p>{{ category.description }}</p>
      </div>
      <div style="display: flex; gap: 20px;">
        <Button
        name="Riwayat"
        outline
        style="width: 180px"
        @click="() => $router.push(`/event-category/${category_id}/event/history`)"
      />
      <Button
        icon="+"
        name="Create Event"
        outline
        style="background-color: #3b54d9; color: #ffffff; width: 180px"
        @click="() => $router.push(`/event-category/${category_id}/event/new`)"
      />
    </div>
    </div>
    <div class="mt-10 table" style="background-color: white">
      <v-toolbar flat>
        <v-row gap="4">
          <v-col cols="3">
            <div class="action-head">
              <div class="f-search">
                <div class="f-search-ic">
                  <img src="/img/icons/search.png" alt="Search Ic" />
                </div>
                <div style="width: 90%">
                  <v-text-field
                    v-model="filter.name"
                    type="text"
                    class="f-input"
                    placeholder="Cari Pekerjaan"
                    dense
                    flat
                    solo
                  ></v-text-field>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <date-picker
              class="my-datepicker"
              v-model="filter.date"
              placeholder="Date time"
              valueType="format"
              range
            ></date-picker>
          </v-col>
    
          <v-col cols="2">
            <Button
              name="TERAPKAN"
              width="100%"
              height="45px"
              @click="() => getEvents()"
            />
          </v-col>
        </v-row>
      </v-toolbar>
    <div class="container-tabel">
        <table class="tabel borderless-table">
          <thead >
            <tr v-if="headers.length">
              <th
                v-for="(result, key) in headers"
                :key="key"
                class="table-head border-tabel"
                :style="`width: ${result.width}px`"
              >
                {{ result.text }}
              </th>
            </tr>
          </thead>
          <tbody v-if="events.length">
            <tr
              v-for="(result, key) in events"
              :key="key"
              :style="{
                backgroundColor: key % 2 === 1 ? '#f5f6f9' : 'transparent',
              }"
            >
            <td class="table-body tbody">{{ result.id }}</td>
              <td class="table-body tbody">{{ result.name }}</td>
              <td class="table-body tbody">{{ $helpers.getDate(result.registration_close_date) }}</td>
              <td class="table-body tbody">{{ result.session_schedule_type }}</td>
              <td class="table-body tbody">{{ result.method}}</td>
              <td class="table-body tbody">{{ result.is_lms_compatible ? 'Tersedia' : 'Tidak Tersedia' }}</td>
              <td
                :style="{
                  backgroundColor: key % 2 === 1 ? '#f5f6f9' : '',
                }"
                class="table-floting"
              >
                <div class="warp-btn-action">
                  <button
                    @click.prevent="watchItem(result)"
                    class="btn-stoke-blue"
                  >
                    <span>LIHAT</span>
                  </button>
                  <button class="btn-stoke-blue" @click="editItem(result)">
                    <span>UBAH</span>
                  </button>
                  <button
                    @click.prevent="deleteItem(result)"
                    class="btn-stoke-blue"
                  >
                    <span>Hapus</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <NoDataAlert v-if="!events.length" :totalData="totalData" />
    </div>


    <v-row justify="center" v-if="total">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getEvents"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
    <p class="count-data" v-if="totalData">
      <b>{{ shown }} dari {{ totalData }}</b>
      event yang tertampil
    </p>
  </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import Modal from "@/components/Modal.vue";
import Pencil from "@/components/icons/Pencil.vue";
import Arrow from "@/components/icons/Arrow.vue";
import moment from "moment";
import Loading from "@/components/ilustration/Loading.vue";
import getAuthentification from "@/utils/badrequest.js";
import NoDataAlert from "@/components/NoDataAlert.vue";
import NewModal from "@/components/NewModal.vue";
import Popup from "@/components/others/Popup.vue";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Button,
    Modal,
    Loading,
    Pencil,
    Arrow,
    NoDataAlert,
    NewModal,
    Popup,
    DatePicker
  },
  data() {
    return {
      isModal: false,
      isModal2: false,
      currentPage: 1,
      isLoading: false,
      total: 0,
      totalData: 0,
      shown: 0,
      perPage: 10,
      id: 0,
      category_id: 0,
      message: "",
      isPopup: false,
      form: {
        name: "",
        description: "",
      },
      category: {
        name: "",
        description: "",
      }, // important
      headers: [
        {text: "ID", value: "id", width: "80"},
        { text: "Nama Event", value: "name",width: "350" },
        { text: "TANGGAL DITUTUP", value: "registration_close_date", width: "200" },
        { text: "Tipe Sesi", value: "session_schedule_type", width: "250" },
        { text: "Pelaksanaan", value: "method", width: "140" },
        { text: "Avaliabel LMS", value: "is_lms_compatible", width: "200" },
        { text: "Aksi", value: "actions", sortable: false, width: "250" },
      ],
      listSort: [
        { name: "Terlama", value: "asc" },
        { name: "Terbaru", value: "" },
      ],
      events: [],
      filter: {
        name: "",
        date: [],
        start_date: "",
        end_date: ""
      },
    };
  },
  computed: {
    validForm() {
      if (this.form.name && this.form.description) return false;
      return true;
    },
  },
  
  methods: {
    async getCategory() {
      try {
        const resp = await this.$crm_http.get(
          `/v1/event-category/${this.category_id}`
        );
        this.category = resp.data.data.event_category;
        this.getEvents();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async getEvents() {
      this.filter.start_date = this.filter.date[0] || "";
      this.filter.end_date = this.filter.date[1] || "";
      await this.$crm_http
        .get(
          `/v1/event?is_active=true&name=${this.filter.name}&category=${this.category.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&page=${this.currentPage}&per_page=${this.perPage}`
        )
        .then((response) => {
          if (!response.data.data.events) {
            this.events = [];
            return;
          }
          this.events = response.data.data.events.map((e) => ({
            ...e,
            participant_quota: e.participant_quota
              ? e.participant_quota
              : "Tidak Terbatas",
            price: e.price ? "Berbayar" : "Gratis",
            date:
              this.$helpers.getDate(e.start_date) +
              " - " +
              this.$helpers.getDate(e.end_date),
            time: e.start_time + " - " + e.end_time + " WIB",
          }));
          const t = parseInt(response.data.data.total);
          this.total = t / this.perPage + (t % this.perPage === 0 ? 0 : 1);
          this.totalData = response.data.data.total;
          this.shown = response.data.data.shown;
        })
        .catch((err) => {
          this.message = err.response.data.data
          this.isPopup = true
        });
    },
    watchItem(item) {
      this.$router.push(
        `/event-category/${this.category_id}/event/watch/${item.id}`
      );
    },
    editItem(item) {
      this.$router.push(
        `/event-category/${this.category_id}/event/edit/${item.id}`
      );
    },
    deleteItem(item) {
      this.isModal = true;
      this.id = item.id;
    },
    editCategory() {
      this.form = this.category;
      this.isModal2 = true;
    },
    async submitCategory() {
      try {
        await this.$crm_http.put(
          "/v1/event-category/" + this.category_id,
          this.form
        );
        this.isModal2 = false;
        this.getCategory();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      }
    },
    async onDelete() {
      this.isLoading = true;
      try {
        await this.$crm_http.delete("/v1/event/" + this.id);
        this.isModal = false;
        this.isLoading = false;
        this.getEvents();
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
        this.isLoading = false;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.category_id = this.$route.params.id_category;
    this.getCategory();
  },
};
</script>

<style scoped>
.action-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-weight: bold;
  font-size: 19px;
}
.head-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.head-info-ttl {
  width: 80%;
  margin-bottom: 30px;
}
.count-data {
  position: relative;
  top: -60px;
}
.f-search {
  border: 1px solid #d3d6e1;
  width: 100%;
  height: 48px;
  gap: 10px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.f-search-ic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-right: 18px;
}
.f-input {
  width: 86%;
  background: transparent;
  border: none;
}
.f-input:focus {
  background: transparent;
  border: none;
  outline-width: 0;
}
.menus-top {
  padding-top: 10px;
  display: flex;
  font-size: 16px;
  gap: 8px;
  align-items: center;
  margin-bottom: 10px;
}
.blue-text {
  font-weight: bold;
  color: #162ca2;
  cursor: pointer;
}
.content-form {
  margin-top: -5px;
}
.content-form p {
  text-align: left;
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}

.my-datepicker {
  width: 100%;
}

.my-datepicker ::v-deep .mx-input {
  height: 45px !important;
  border-radius: 12px !important;
}
.container-tabel {
  background-color: white;
  margin: 20px;
  overflow-x: auto;
}
.tabel {
  position: relative;
  border: none;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.table-head {
  background-color: white;
  overflow: hidden;    
  text-overflow: ellipsis; 
  white-space: nowrap; 
  text-align: left;
  padding: 10px;
}
.border-tabel {
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-bottom: 2px solid #e5e7e9;
}
.table-body {
  min-width: 200px;
  height: 141px;
  white-space: nowrap;
  width: auto;
  padding: 10px;
  text-align: left;
}

.table-floting {
  white-space: nowrap;
  width: auto;
  min-width: 200px;
  text-align: left;
  position: sticky;
  right: 0;
  background-color: white;
}
.warp-btn-action {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.btn-action {
  background-color: white;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #575966;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #acafbf;
}
.btn-action-2 {
  background-color: white;
  color: #2d46cf;
  width: 79px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #2d46cf;
}

.tbody {
  margin: 0;
  font-weight: 400;
  border-collapse: collapse;
  font-size: 14px;
}
.tbody-color-red {
  color: #b80f0a;
}
.tbody-color-gray {
  color: #7b7e8c;
}
.btn-stoke-blue {
  border: 1px solid #acafbf;
  border-radius: 12px;
  color: #162ca2;
  font-weight: 600;
  cursor: pointer;
  gap: 10px;
  margin-top: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 13px;
  background-color: white;
  margin: 5px;
}

.btn-stoke-blue span {
  font-family: "";
  font-weight: normal !important;
  font-size: 13px;
  min-width: max-content;
}
</style>
