<template>
  <div class="ma-10">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="50%"
    >
      <div class="container-modal">
        <div class="container-title-modal">
          <h2 class="title-modal">Detail User Aktif</h2>
          <InfoIcon :width="20" :height="20" />
        </div>
        <div class="modal-body">
          <div class="data-list">
            <div class="detail-data" style="flex: 1">
              <p class="detail-label">Image</p>
              <div style="height: 40px; width: 40px; overflow: hidden">
                <img
                  style="width: 100%; height: 100%"
                  :src="
                    userActiveDetail.image
                      ? userActiveDetail.image
                      : 'https://t9003038375.p.clickup-attachments.com/t9003038375/afef200c-37cb-4e5e-aead-822ec4c0c7e7/rb_4707.png?view=open'
                  "
                  alt=""
                />
              </div>
            </div>
            <div class="detail-data" style="flex: 1">
              <p class="detail-label">Title</p>
              <p class="detail-value">{{ userActiveDetail.gender }}</p>
            </div>
            <div class="detail-data" style="flex: 3">
              <p class="detail-label">Nama Lengkap</p>
              <p class="detail-value">{{ userActiveDetail.full_name }}</p>
            </div>
          </div>

          <div class="data-list">
            <div class="detail-data">
              <p class="detail-label">No.Handphone</p>
              <p class="detail-value">
                (+62) {{ userActiveDetail.phone_number }}
              </p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Email</p>
              <p class="detail-value">{{ userActiveDetail.email }}</p>
            </div>
          </div>

          <div class="data-list">
            <div class="detail-data">
              <p class="detail-label">Tanggal Lahir</p>
              <p class="detail-value">
                {{ formattedBirthdate(userActiveDetail.birthdate) }}
              </p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Roles</p>
              <p class="detail-value">{{ userActiveDetail.role }}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <div class="top-container-box">
      <h1>User aktif</h1>
      <div>
        <Button
          icon="+"
          outline
          name="Tambah User Baru"
          style="background-color: #3b54d9; color: #ffffff; width: 270px"
          @click="() => $router.push('/user/new')"
        />
      </div>
    </div>

    <!-- table -->
    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1 mt-10 pa-5"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat class="mb-5">
          <v-col class="d-flex align-center">
            <div class="ctn">
              <div class="f-search">
                <v-text-field
                  v-model="filter.search"
                  placeholder="Cari akun..."
                  clearable
                  dense
                  flat
                  solo
                  prepend-inner-icon="mdi-magnify"
                  style="
                    border-radius: 14px;
                    max-width: 248px;
                    margin-right: 20px;
                  "
                ></v-text-field>
              </div>

              <date-picker
                class="my-datepicker"
                placeholder="Date time"
                valueType="format"
                v-model="filter.date"
                range
              ></date-picker>

              <div class="btn-filter-horizontal">
                <Button
                  name="TERAPKAN FILTER"
                  width="200px"
                  @click="applyFilters"
                />
              </div>

              <div v-if="isFilterActive" class="btn-filter-horizontal">
                <Button
                  class="reset-filter"
                  @click="clearFilter()"
                  name="RESET"
                />
              </div>
            </div>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.birthdate="{ item }">
        <p>{{ $helpers.getDate(item.birthdate) }}</p>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="actions">
          <p small @click="editUser(item)" style="cursor: pointer">EDIT</p>
          <p small @click="viewUser(item)" class="text" style="cursor: pointer">
            LIHAT
          </p>
        </div>
      </template>
    </v-data-table>

    <!-- Display when no data is available -->
    <NoDataAlert :totalData="total" />

    <v-row justify="center" v-if="total">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getUsers"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import NoDataAlert from "@/components/NoDataAlert.vue";
import Button from "@/components/Button.vue";
import Loading from "@/components/ilustration/Loading.vue";
import Modal from "@/components/Modal.vue";
import Popup from "@/components/others/Popup.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import DatePicker from "vue2-datepicker";

export default {
  data() {
    return {
      isLoading: false,
      filter: {
        search: "",
        date: "",
        start_date: "",
        end_date: "",
      },

      isModal: false,
      isModalDetail: false,
      isFilterActive: false,
      message: "",
      isPopup: false,
      // showResetButton: false,
      currentPage: 1,
      total: 0,
      // userTypes: [],
      headers: [
        {
          text: "NO",
          value: "id",
          //   width: "1"
        },
        { text: "Title", value: "gender" },
        { text: "Nama ", value: "full_name" },
        { text: "No. Telp ", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Tgl. Lahir", value: "birthdate" },
        { text: "Roles", value: "role", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ],
      users: [],
      // filteredUsers: []
    };
  },
  components: {
    NoDataAlert,
    Button,
    Loading,
    Modal,
    Popup,
    InfoIcon,
    DatePicker,
  },
  //   created() {
  //   this.filteredUsers = this.users;
  // },
  methods: {
    async getUsers() {
      this.filter.start_date = this.filter.date[0] || "";
      this.filter.end_date = this.filter.date[1] || "";
      try {
        const resp = await this.$crm_http.get(`/v1/user?is_active=true&per_page=10&page=${this.currentPage}&keyword=${this.filter.search}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`);
        this.users = resp.data.data.users;
        this.total = parseInt(resp.data.data.pagination.total_page);
      } catch (err) {
        console.log(err);
        
      }
    },
    editUser(item) {
      this.$router.push("/user/edit/" + item.id);
    },
    viewUser(user) {
      this.userActiveDetail = user;
      this.isModalDetail = true;
    },
    applyFilters() {
      this.currentPage = 1;
      this.getUsers();
      this.isFilterActive = true;
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        search: "",
        date: "",
        start_date: "",
        end_date: "",
      };
      this.getUsers();
    },
    formattedBirthdate(inputDate) {
      const date = new Date(inputDate);
      return `${String(date.getDate()).padStart(2, "0")}/${String(
        date.getMonth() + 1
      ).padStart(2, "0")}/${date.getFullYear()}`;
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
<style scoped>
.my-datepicker {
  width: auto;
}
.my-datepicker ::v-deep .mx-input {
  height: 40px !important;
  border-radius: 10px;
}

.container-modal {
  padding: 20px 30px;
}

.container-title-modal {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
}

.title-modal {
  font-weight: 700;
  color: #36373f;
  font-size: 32px;
  line-height: 48px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  row-gap: 25px;
}

.data-list {
  display: flex;
  gap: 20px;
  width: 100%;
}

.detail-data {
  flex: 1;
  text-align: left;
  border: 1px solid #d6d6d6;
  padding: 16px 22px;
  border-radius: 10px;
}

.detail-label {
  color: #7b7e8c;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

.detail-value {
  color: #36373f;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
}

.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.top-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  background-color: white;
  padding: 20px 40px;
  border-radius: 30px;
}

.ctn {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.f-search {
  border: 1px solid #d3d6e1;
  height: 40px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.f-date {
  border: 1px solid #d3d6e1;
  height: 42px;
  display: flex;
  border-radius: 10px;
  background-color: white;
}

.actions {
  display: flex;
  gap: 15px;
  margin-top: 15px;
  font-weight: bold;
}

.text {
  color: blue;
}
.reset-filter {
  cursor: pointer;
  color: #acafbf !important;
  background-color: white !important;
  border: 1px solid #acafbf;
}
</style>
