import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Blog from "../views/MasterData/blog/Blog.vue";
import Career from "../views/MasterData/career/Career.vue";
import Class from "../views/MasterData/class/Class.vue";
import ClassCategory from "../views/MasterData/class/ClassCategory.vue";
import Attendance from "../views/MasterData/class/Attendance.vue";
import Choice from "../views/MasterData/class/Choices.vue";
import ChoiceDetail from "../views/MasterData/class/ChoiceDetail.vue";
import FormClass from "../views/MasterData/class/Form.vue";
import ClassDetail from "../views/MasterData/class-detail/ClassDetail.vue";
import Voucher from "../views/MasterData/voucher/Voucher.vue";
import FormVoucher from "../views/MasterData/voucher/Form.vue";
import BadRequest from "../views/ErrorPage/BadRequest.vue";
import PageNotFound from "../views/ErrorPage/PageNotFound.vue";
import FormBlog from "../views/MasterData/blog/Form.vue";
import FormCareer from "../views/MasterData/career/Form.vue";
import Assesment from "../views/LMS/Assesment.vue";
import UserSchedule from "../views/LMS/user-schedule/UserSchedule.vue";
import UserScheduleForm from "../views/LMS/user-schedule/Form.vue";
import Certificate from "../views/LMS/certificate/Certificate.vue";
import FormCertificate from "../views/LMS/certificate/Form.vue";
import AssessmentReviewClass from "../views/LMS/assessment-review/AssessetReviewClass.vue";
import AssessetReview from "../views/LMS/assessment-review/AssessetReview.vue";
import AssessmentReviewDetail from "../views/LMS/assessment-review/AssessmentReviewDetail.vue";
import CorporateTraining from "../views/MasterData/corporate-training/CorporateTraining.vue";
import FormCorporateTraining from "../views/MasterData/corporate-training/Form.vue";
import Banner from "../views/MasterData/banner/Banner.vue";
import FormBanner from "../views/MasterData/banner/Form.vue";
import HitBanner from "../views/MasterData/hit-banner/HitBanner.vue";
import FormHitBanner from "../views/MasterData/hit-banner/Form.vue";
import Survey from "../views/MasterData/survey/Survey.vue";
import FormSurvey from "../views/MasterData/survey/Form.vue";
import AttandenceDetail from "../views/MasterData/class/AttendanceDetail.vue";
import ReflectiveJournal from "../views/MasterData/reflective-journal/ReflectiveJournal.vue";
import ReflectiveJournalForm from "../views/MasterData/reflective-journal/Form.vue";
import Event from "../views/MasterData/event/Event.vue";
import EventRiwayat from "../views/MasterData/event/EventRiwayat.vue";
import EventCategory from "../views/MasterData/event/EventCategory.vue";
import EventRiwayatCategory from "../views/MasterData/event/EventRiwayatCategory.vue";
import FormEvent from "../views/MasterData/event/Form.vue";
import WatchEvent from "../views/MasterData/event/Watch.vue";
import CareerListHistory from "../views/MasterData/career-application/History.vue";
import CareerList from "../views/MasterData/career-application/CareerList.vue";
import CareerApplication from "../views/MasterData/career-application/CareerApplication.vue";
import FormCareerApplication from "../views/MasterData/career-application/Form.vue";
import Instructor from "../views/MasterData/instructor/Instructor.vue";
import FormInstructor from "../views/MasterData/instructor/Form.vue";
import Schedule from "../views/MasterData/schedule/Schedule.vue";
import FormSchedule from "../views/MasterData/schedule/Form.vue";
import Logo from "../views/MasterData/logo/Logo.vue";
import FormLogo from "../views/MasterData/logo/Form.vue";
import BlastEmail from "../views/MasterData/blast-email/BlastEmail.vue";
import BlastEmailForm from "../views/MasterData/blast-email/Form.vue";
import BlastReport from "../views/MasterData/blast-report/BlastReport.vue";
import BlastTemplate from "../views/MasterData/blast-template/BlastTemplate.vue";
import BlastTemplateForm from "../views/MasterData/blast-template/Form.vue";

import BlastHistory from "../views/MasterData/blast-history/BlastHistory.vue";
import BlastHistoryForm from "../views/MasterData/blast-history/Form.vue";

import Partner from "../views/MasterData/partner/Partner.vue";
import FormPartner from "../views/MasterData/partner/Form.vue";

import CorporateRegistration from "../views/Main/CorporateRegistration/CorporateRegistration.vue";
import FormCorporateRegistration from "../views/Main/CorporateRegistration/Form.vue";

import KampusMerdeka from "../views/MasterData/kampus-merdeka/KampusMerdeka.vue";
import FormKampusMerdeka from "../views/MasterData/kampus-merdeka/Form.vue";

import ReportingLog from "../views/MasterData/reporting-log/ReportingLog.vue";
import Reconciliation from "../views/MasterData/reconciliation/Reconciliation.vue";
import FormReconciliation from "../views/MasterData/reconciliation/Form.vue";
import DraftLog from "../views/MasterData/reporting-log/DraftReport.vue";
import CompleteLog from "../views/MasterData/reporting-log/CompleteReport.vue";
import DetailReporting from "../views/MasterData/reporting-log/DetailReporting.vue";

import Faq from "../views/MasterData/faq/Faq.vue";
import FormFaq from "../views/MasterData/faq/Form.vue";

import PrivacyPolicy from "../views/MasterData/privacy-policy/PrivacyPolicy.vue";
import FormPrivacyPolicy from "../views/MasterData/privacy-policy/Form.vue";

import Testimoni from "../views/MasterData/testimoni/Testimoni.vue";
import FormTestimoni from "../views/MasterData/testimoni/Form.vue";

import TransactionEventForm from "../views/Transaction/b2c/event/Form.vue";

import Statistic from "../views/UserManagement/statistic/Statistic.vue";
import User from "../views/UserManagement/user/User.vue";
import PostUserForm from "../views/UserManagement/user/Form.vue";
import EditUserForm from "../views/UserManagement/user/FormEdit.vue";

import UserNonActive from "../views/UserManagement/user-non-active/UserNonActive.vue";
import FormUserNonActive from "../views/UserManagement/user-non-active/Form.vue";

import ProjectPortfolio from "../views/MasterData/project-portfolio/ProjectPortfolio.vue";
import FormProjectPortfolio from "../views/MasterData/project-portfolio/Form.vue";
import AlumniStories from "../views/MasterData/alumni-stories/AlumniStories.vue";
import FormAlumniStories from "../views/MasterData/alumni-stories/Form.vue";

import CareerEvent from "../views/CRM/b2c/event/career-event/CareerEvent.vue";
import CareerEventCard from "../views/CRM/b2c/event/career-event-card/CareerEventCard.vue";
import CareerEventChart from "../views/CRM/b2c/event/career-event-chart/CareerEventChart.vue";

import CRMB2bCollaboration from "../views/CRM/b2b/Collaboration.vue";
import CRMB2bTraining from "../views/CRM/b2b/Training.vue";
import CRMB2bTalentService from "../views/CRM/b2b/TalentService.vue";
import CRMB2bService from "../views/CRM/b2b/Service.vue";

import FormTemplate from "@/pages/FormTemplate.vue";
import MainTemplate from "@/pages/MainTemplate.vue";
import Login from "../views/Login/Login.vue";


Vue.use(VueRouter);

const routes = [
  { path: "*", component: PageNotFound },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "MainTemplate",
    component: MainTemplate,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    children: [
      {
        path: "/home/:token",
        name: "HomeAuth",
        component: Home,
      },
      {
        path: "/home",
        name: "Home",
        component: Home,
      },
      {
        path: "/logo",
        name: "Logo",
        component: Logo,
      },
      {
        path: "/partner",
        name: "Partner",
        component: Partner,
      },
      {
        path: "/reporting-log",
        name: "ReportingLog",
        component: ReportingLog,
      },
      {
        path: "/blast-history",
        name: "BlastHistory",
        component: BlastHistory,
      },
      {
        path: "/blast-template",
        name: "BlastTemplate",
        component: BlastTemplate,
      },
      {
        path: "/reporting-log/draft",
        name: "ReportingDraftLog",
        component: DraftLog,
      },
      {
        path: "/reporting-log/:id",
        name: "ReportingCompleteLog",
        component: CompleteLog,
      },
      {
        path: "/reconciliation",
        name: "Reconciliation",
        component: Reconciliation,
      },
      {
        path: "/class-category/:id_category/class",
        name: "Class",
        component: Class,
      },

      {
        path: "/class-category",
        name: "ClassCategory",
        component: ClassCategory,
      },
      {
        path: "/class-detail",
        name: "ClassDetail",
        component: ClassDetail,
      },
      {
        path: "/attendance/:id_category/class/:id_class",
        name: "Attendance",
        component: Attendance,
      },
      {
        path: "/attendance/:id_class/seq/:sequence",
        name: "AttandenceDetail",
        component: AttandenceDetail,
      },
      {
        path: "/choice/:id_category/class/:id_class",
        name: "Choice",
        component: Choice,
      },
      {
        path: "/survey",
        name: "Survey",
        component: Survey,
      },
      {
        path: "/assessment-review",
        name: "AssessetReviewClass",
        component: AssessmentReviewClass,
      },
      {
        path: "/assessment-review/:class_id",
        name: "AssessetReview",
        component: AssessetReview,
      },
      {
        path: "/assessment-review/:class_id",
        name: "AssessetReview",
        component: AssessetReview,
      },
      {
        path: "/assessment-review/:id/detail/:class_id",
        name: "AssessmentReviewDetail",
        component: AssessmentReviewDetail,
      },
      {
        path: "/voucher",
        name: "Voucher",
        component: Voucher,
      },
      {
        path: "/blog",
        name: "Blog",
        component: Blog,
      },
      {
        path: "/project-portfolio",
        name: "Project Portofolio",
        component: ProjectPortfolio,
      },
      {
        path: "/alumni-stories",
        name: "Alumni Stories",
        component: AlumniStories,
      },
      {
        path: "/career",
        name: "Career",
        component: Career,
      },
      {
        path: "/career-application",
        name: "CareerApplication",
        component: CareerList,
      },
      {
        path: "/career-application/:id",
        name: "CareerApplication",
        component: CareerApplication,
        props: true,
      },
      {
        path: "/crm-b2b-collaboration",
        name: "CRMB2bCollaboration",
        component: CRMB2bCollaboration,
      },
      {
        path: "/crm-b2b-training",
        name: "CRMB2bTraining",
        component: CRMB2bTraining,
      },
      {
        path: "/crm-b2b-talent-service",
        name: "CRMB2bTalentService",
        component: CRMB2bTalentService,
      },
      {
        path: "/crm-b2b-service",
        name: "CRMB2bService",
        component: CRMB2bService,
      },
      {
        path: "/crm-event",
        name: "CareerEvent",
        component: CareerEvent,
      },
      {
        path: "/crm-event/:id",
        name: "CareerEventId",
        component: CareerEventCard,
        props: true,
      },
      {
        path: "/crm-event/history/:category/:id",
        name: "CareerEventHistory",
        component: CareerEventCard,
        props: true,
      },
      {
        path: "/crm-event/:category/:ids",
        name: "CareerEvent",
        component: CareerEventChart,
        props: true,
      },
      {
        path: "/crm-event/history/:category/:ids/:history",
        name: "CareerEvent",
        component: CareerEventChart,
        props: true,
      },
      {
        path: "/career-application-history",
        name: "CareerApplicationHistory",
        component: CareerListHistory,
      },

      {
        path: "/bad-request",
        name: "BadRequest",
        component: BadRequest,
      },
      {
        path: "/assesment",
        name: "Assesment",
        component: Assesment,
      },
      {
        path: "/user-schedule",
        name: "UserSchedule",
        component: UserSchedule,
      },
      {
        path: "/certificate",
        name: "Certificate",
        component: Certificate,
      },
      {
        path: "/main-banner",
        name: "Banner",
        component: Banner,
      },
      {
        path: "/hit-banner",
        name: "HitBanner",
        component: HitBanner,
      },
      {
        path: "/corporate-registration",
        name: "CorporateRegistration ",
        component: CorporateRegistration,
      },
      {
        path: "/event-category",
        name: "EventCategory",
        component: EventCategory,
      },
      {
        path: "/event-category/riwayat",
        name: "EventCategoryHistory",
        component: EventRiwayatCategory,
      },
      {
        path: "/event-category/:id_category/event",
        name: "Event",
        component: Event,
      },
      {
        path: "/event-category/:id_category/event/history",
        name: "EventRiwayat",
        component: EventRiwayat,
      },
      {
        path: "/instructor",
        name: "Instructor",
        component: Instructor,
      },
      {
        path: "/schedule",
        name: "Schedule",
        component: Schedule,
      },
      {
        path: "/kampus-merdeka",
        name: "KampusMerdeka",
        component: KampusMerdeka,
      },
      {
        path: "/faq",
        name: "FAQ",
        component: Faq,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/blast-email",
        name: "BlastEmail",
        component: BlastEmail,
      },
      {
        path: "/blast-report",
        name: "BlastReport",
        component: BlastReport,
      },

      {
        path: "/reflective-journal",
        name: "ReflectiveJournal",
        component: ReflectiveJournal,
      },
      {
        path: "/testimoni",
        name: "Testimoni",
        component: Testimoni,
      },
      {
        path: "/corporate-training",
        name: "CorporateTraining",
        component: CorporateTraining,
      },
      {
        path: "/statistic",
        name: "Statistic",
        component: Statistic,
      },
      {
        path: "/user",
        name: "UserActive",
        component: User,
      },
      // form 
      {
        path: "/user/new",
        name: "FormUserActiveNew",
        component: PostUserForm,
        meta: {
          name: "Buat User Baru",
        },
      },
      {
        path: "/user/edit/:id",
        name: "EditUserForm",
        component: EditUserForm,
      },
      {
        path: "/user-tidak-aktif",
        name: "UserNonActive",
        component: UserNonActive,
      },

      {
        path: "/user-non-active/form/:id",
        name: "UserNonActive",
        component: FormUserNonActive,
      },
      {
        path: "project-portfolio/new",
        name: "FormProjectPortfolioNew",
        component: FormProjectPortfolio,
        meta: {
          name: "Buat Project Portfolio",
        },
      },
      {
        path: "project-portfolio/watch/:id",
        name: "FormProjectPortfolioWatch",
        component: FormProjectPortfolio,
        meta: {
          name: "Lihat Project Portfolio",
        },
      },
      {
        path: "project-portfolio/edit/:id",
        name: "FormProjectPortfolioEdit",
        component: FormProjectPortfolio,
        meta: {
          name: "Edit Project Portfolio",
        },
      },
      {
        path: "event-category/:id_category/event/new",
        name: "FormEventNew",
        component: FormEvent,
        meta: {
          name: "Buat Event",
        },
      },
      {
        path: "event-category/:id_category/event/watch/:id",
        name: "FormEventWatch",
        component: WatchEvent,
        meta: {
          name: "Lihat Event",
        },
      },
      {
        path: "event-category/:id_category/event/edit/:id",
        name: "FormEventEdit",
        component: FormEvent,
        meta: {
          name: "Edit Event",
        },
      },
    ],
  },
  {
    path: "/transaction",
    name: "TransactionTemplate",
    component: MainTemplate,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    children: [
      {
        path: "statistic",
        name: "FinanceStatistic",
        component: () => import("@/views/Transaction/statistic/Statistic.vue"),
      },
      {
        path: "log/transaction_log",
        name: "UserNonAktiv",
        component: () =>
          import("@/views/Transaction/Log/transaction_log/TransactionLog"),
      },
      {
        path: "b2g/prakerja",
        name: "Prakerja",
        component: () =>
          import("@/views/Transaction/b2g/prakerja/Prakerja.vue"),
      },
      {
        path: "b2b/event",
        name: "Event",
        component: () => import("@/views/Transaction/b2c/event/Main.vue"),
      },

      {
        path: "b2b/event/:categorys/riwayat",
        name: "Event",
        component: () =>
          import("@/views/Transaction/b2c/event/riwayat/Main.vue"),
        props: true,
      },
      {
        path: "b2b/event/:categorys",
        name: "Event",
        component: () =>
          import("@/views/Transaction/b2c/event/categorys/Main.vue"),
        props: true,
      },
      {
        path: "b2b/event/:categorys/:id",
        name: "Event",
        component: () =>
          import("@/views/Transaction/b2c/event/tabelcategory/Main.vue"),
        props: true,
      },
      {
        path: "b2b/event/:categorys/detail/:id",
        name: "Event",
        component: () =>
          import("@/views/Transaction/b2c/event/detail/Main.vue"),
        props: true,
      },

      {
        path: "b2b/event/:id",
        name: "Event",
        component: () =>
          import("@/views/Transaction/b2c/event/DetailEvent.vue"),
        props: true,
      },
      {
        path: "b2b/event/participants/:id",
        name: "Event",
        component: () =>
          import("@/views/Transaction/b2c/event/Participants.vue"),
      },
      {
        path: "b2b/event/history/:id",
        name: "Event",
        component: () => import("@/views/Transaction/b2c/event/History.vue"),
      },
      {
        path: "b2b/event/participants/edit/:id",
        name: "Event",
        component: () => import("@/views/Transaction/b2c/event/Form.vue"),
      },
      {
        path: "b2b/bootcamp",
        name: "Bootcamp",
        component: () =>
          import("@/views/Transaction/b2c/bootcamp/Bootcamp.vue"),
      },
      {
        path: "b2b/bootcamp/:id",
        name: "Bootcamp",
        component: () =>
          import("@/views/Transaction/b2c/bootcamp/DetailBootcamp.vue"),
      },
      {
        path: "b2b/bootcamp/edit/:id",
        name: "Bootcamp",
        component: () => import("@/views/Transaction/b2c/bootcamp/Form.vue"),
      },
      {
        path: "b2b/bootcamp/detail/history",
        name: "Bootcamp",
        component: () => import("@/views/Transaction/b2c/bootcamp/History.vue"),
      },
      {
        path: "b2b/public-training",
        name: "PublicTrainig",
        component: () =>
          import("@/views/Transaction/b2c/public-training/PublicTrainingOld.vue"),
      },
      {
        path: "b2b/acceleration",
        name: "Acceleration",
        component: () =>
          import("@/views/Transaction/b2b/acceleration/Acceleration.vue"),
      },
    ],
  },

  // {
  //   path: '/transaction/b2b/transaction_log',
  //   redirect: '/Log/transaction_log',
  // },
  // {
  //   path: '/Log/transaction_log',
  //   name: 'TransactionLog',
  //   component:() => import('@/views/Transaction/log/transaction_log/TransactionLog.vue'),
  // },

  {
    path: "/",
    name: "FormTemplate",
    component: FormTemplate,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    children: [
      {
        path: "/transaction-log/form/:id",
        name: "TransactionLog",
        component: () =>
          import("@/views/Transaction/Log/transaction_log/Form.vue"),
      },
      
      {
        path: "transaction/b2c/event/:id",
        name: "FormTransactionEventWatch",
        component: () =>
          import("../views/Transaction/b2c/event/FormTransaction.vue"),
        meta: {
          name: "Lihat Transaction Event",
        },
      },
      {
        path: "transaction/b2c/bootcamp/:id",
        name: "FormTransactionBootcampWatch",
        component: () => import("../views/Transaction/b2c/bootcamp/Form.vue"),
        meta: {
          name: "Lihat Transaction Bootcamp",
        },
      },
      {
        path: "transaction/b2c/public-training/:id",
        name: "FormTransactionPublicTrainingWatch",
        component: () =>
          import("../views/Transaction/b2c/public-training/Form.vue"),
        meta: {
          name: "Lihat TransactionPublicTraining",
        },
      },
     
      {
        path: "blast-template/watch/:id",
        name: "BlastTemplateForm",
        component: BlastTemplateForm,
        meta: {
          name: "",
        },
      },
      {
        path: "blast-history/:id",
        name: "BlastHistoryForm",
        component: BlastHistoryForm,
        meta: {
          name: "Lihat Data",
        },
      },
      {
        path: "reporting-log/redemption/detail/:id",
        name: "FormDetailReport",
        component: DetailReporting,
        meta: {
          name: "Redemption Detail",
        },
      },
      {
        path: "reporting-log/completion/detail/:id",
        name: "FormDetailReport",
        component: DetailReporting,
        meta: {
          name: "Completion Detail",
        },
      },
      {
        path: "/reconciliation/completion/:voucher_code",
        name: "FormReconciliationCompletion",
        component: FormReconciliation,
        meta: {
          name: "Edit Completion",
        },
      },
      {
        path: "/reconciliation/redemption/:voucher_code",
        name: "FormReconciliationRedemption",
        component: FormReconciliation,
        meta: {
          name: "Edit Redemption",
        },
      },
      {
        path: "logo/new",
        name: "FormLogoNew",
        component: FormLogo,
        meta: {
          name: "Buat Logo",
        },
      },
      {
        path: "logo/edit/:id",
        name: "FormLogoEdit",
        component: FormLogo,
        meta: {
          name: "Edit Logo",
        },
      },
      {
        path: "blast-email/new",
        name: "BlastEmailNewForm",
        component: BlastEmailForm,
        meta: {
          name: "Buat Blast Email",
        },
      },
      {
        path: "blast-email/watch/:id",
        name: "BlastEmailEditForm",
        component: BlastEmailForm,
        meta: {
          name: "Watch Blast Email",
        },
      },
      {
        path: "corporate-registration/watch/:id",
        name: "FormCorporateRegistration",
        component: FormCorporateRegistration,
        meta: {
          name: "Lihat Corporate",
        },
      },
      {
        path: "class-category/:id_category/class/new",
        name: "FormClassNew",
        component: FormClass,
        meta: {
          name: "Buat Class",
        },
      },
      {
        path: "choice/:id_category/class/:id_class/detail",
        name: "ChoiceDetail",
        component: ChoiceDetail,
      },
      {
        path: "class-category/:id_category/class/edit/:id",
        name: "FormEventEdit",
        component: FormClass,
        meta: {
          name: "Edit Class",
        },
      },
      {
        path: "class-category/:id_category/class/watch/:id",
        name: "FormClassWatch",
        component: FormClass,
        meta: {
          name: "Lihat Class",
        },
      },
      {
        path: "voucher/new",
        name: "FormVoucherNew",
        component: FormVoucher,
        meta: {
          name: "Buat Voucher",
        },
      },
      {
        path: "voucher/edit/:id",
        name: "FormVoucherEdit",
        component: FormVoucher,
        meta: {
          name: "Edit Voucher",
        },
      },
      {
        path: "voucher/watch/:id",
        name: "FormVoucherWatch",
        component: FormVoucher,
        meta: {
          name: "Lihat Voucher",
        },
      },
      {
        path: "partner/new",
        name: "FormPartnerNew",
        component: FormPartner,
        meta: {
          name: "Buat Partner",
        },
      },
      {
        path: "partner/edit/:id",
        name: "FormPartnerEdit",
        component: FormPartner,
        meta: {
          name: "Edit Partner",
        },
      },
      {
        path: "partner/watch/:id",
        name: "FormPartnerWatch",
        component: FormPartner,
        meta: {
          name: "Lihat Partner",
        },
      },
      {
        path: "instructor/new",
        name: "FormInstructorNew",
        component: FormInstructor,
        meta: {
          name: "Buat Instructor",
        },
      },
      {
        path: "instructor/edit/:id",
        name: "FormInstructorEdit",
        component: FormInstructor,
        meta: {
          name: "Edit Instructor",
        },
      },
      {
        path: "instructor/watch/:id",
        name: "FormInstructorWatch",
        component: FormInstructor,
        meta: {
          name: "Lihat Instructor",
        },
      },
      {
        path: "schedule/new",
        name: "FormScheduleNew",
        component: FormSchedule,
        meta: {
          name: "Buat Schedule",
        },
      },
      {
        path: "schedule/edit/:id",
        name: "FormScheduleEdit",
        component: FormSchedule,
        meta: {
          name: "Edit Schedule",
        },
      },
      {
        path: "schedule/watch/:id",
        name: "FormScheduleWatch",
        component: FormSchedule,
        meta: {
          name: "Lihat Schedule",
        },
      },
      {
        path: "certificate/new",
        name: "FormCertificateNew",
        component: FormCertificate,
        meta: {
          name: "Buat Certificate",
        },
      },
      {
        path: "certificate/edit/:id",
        name: "FormCertificateEdit",
        component: FormCertificate,
        meta: {
          name: "Edit Certificate",
        },
      },
      {
        path: "certificate/watch/:id",
        name: "FormCertificateWatch",
        component: FormCertificate,
        meta: {
          name: "Lihat Certificate",
        },
      },
      {
        path: "faq/new",
        name: "FormFaqNew",
        component: FormFaq,
        meta: {
          name: "Buat Faq",
        },
      },
      {
        path: "corporate-training/edit/:id",
        name: "FormFaqEdit",
        component: FormCorporateTraining,
        meta: {
          name: "Edit Corporate Training",
        },
      },
      {
        path: "corporate-training/watch/:id",
        name: "FormCorporateTrainingWatch",
        component: FormCorporateTraining,
        meta: {
          name: "Lihat Corporate Training",
        },
      },
      {
        path: "corporate-training/new",
        name: "FormCorporateTrainingNew",
        component: FormCorporateTraining,
        meta: {
          name: "Buat Corporate Training",
        },
      },
      {
        path: "faq/edit/:id",
        name: "FormFaqEdit",
        component: FormFaq,
        meta: {
          name: "Edit Faq",
        },
      },
      {
        path: "faq/watch/:id",
        name: "FormFaqWatch",
        component: FormFaq,
        meta: {
          name: "Lihat Faq",
        },
      },
      {
        path: "privacy-policy/new",
        name: "FormPrivacyPolicyNew",
        component: FormPrivacyPolicy,
        meta: {
          name: "Buat Privacy Policy",
        },
      },
      {
        path: "privacy-policy/edit/:id",
        name: "FormPrivacyPolicyEdit",
        component: FormPrivacyPolicy,
        meta: {
          name: "Edit Privacy Policy",
        },
      },
      {
        path: "privacy-policy/watch/:id",
        name: "FormPrivacyPolicyWatch",
        component: FormPrivacyPolicy,
        meta: {
          name: "Lihat Privacy Policy",
        },
      },
      {
        path: "reflective-journal/new",
        name: "FormReflectiveJournalNew",
        component: ReflectiveJournalForm,
        meta: {
          name: "Buat Reflective Journal",
        },
      },
      {
        path: "reflective-journal/edit/:id",
        name: "FormReflectiveJournalEdit",
        component: ReflectiveJournalForm,
        meta: {
          name: "Edit Reflective Journal",
        },
      },
      {
        path: "reflective-journal/watch/:id",
        name: "FormReflectiveJournalWatch",
        component: ReflectiveJournalForm,
        meta: {
          name: "Lihat Reflective Journal",
        },
      },
      {
        path: "testimoni/new",
        name: "FormTestimoniNew",
        component: FormTestimoni,
        meta: {
          name: "Buat Testimoni",
        },
      },
      {
        path: "testimoni/edit/:id",
        name: "FormTestimoniEdit",
        component: FormTestimoni,
        meta: {
          name: "Edit Testimoni",
        },
      },
      {
        path: "testimoni/watch/:id",
        name: "FormTestimoniWatch",
        component: FormTestimoni,
        meta: {
          name: "Lihat Testimoni",
        },
      },
      {
        path: "transaction/b2b/event/new",
        name: "FormTransactionEventNew",
        component: TransactionEventForm,
        meta: {
          name: "Buat Transaksi",
        },
      },
      {
        path: "transaction/b2b/acceleration/:id",
        name: "FormTransactionAccelerationEdit",
        component: () =>
          import("../views/Transaction/b2b/acceleration/FormEdit.vue"),
        meta: {
          name: "Lihat Transaction Event",
        },
      },
      {
        path: "survey/watch/:id",
        name: "FormSurveyWatch",
        component: FormSurvey,
        meta: {
          name: "Lihat Survey",
        },
      },
      {
        path: "alumni-stories/new",
        name: "FormAlumniStoriesNew",
        component: FormAlumniStories,
        meta: {
          name: "Buat Cerita Alumni",
        },
      },
      {
        path: "alumni-stories/watch/:id",
        name: "FormAlumniStoriesWatch",
        component: FormAlumniStories,
        meta: {
          name: "Lihat Cerita Alumni",
        },
      },
      {
        path: "alumni-stories/edit/:id",
        name: "FormAlumniStoriesEdit",
        component: FormAlumniStories,
        meta: {
          name: "Edit Cerita Alumni",
        },
      },
    ],
  },
  {
    path: "/blog/new",
    name: "FormBlogCreate",
    component: FormBlog,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Buat Blog",
    },
  },
  {
    path: "/blog/edit/:id",
    name: "FormBlogEdit",
    component: FormBlog,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Edit Blog",
    },
  },
  {
    path: "/blog/watch/:id",
    name: "FormBlogWatch",
    component: FormBlog,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Detail Blog",
    },
  },
  {
    path: "/user-schedule/edit",
    name: "UserScheduleFormEdit",
    component: UserScheduleForm,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Edit User Schedule",
    },
  },
  {
    path: "/career/new",
    name: "FormCareerCreate",
    component: FormCareer,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Buat Career",
    },
  },
  {
    path: "/career/edit/:id",
    name: "FormCareerEdit",
    component: FormCareer,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Edit Career",
    },
  },
  {
    path: "/career/watch/:id",
    name: "FormCareerWatch",
    component: FormCareer,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Detail Career",
    },
  },
  {
    path: "/career-application-watch/:id",
    name: "FormCareerApplicationWatch",
    component: FormCareerApplication,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Detail Career Application",
    },
  },
  {
    path: "/banner/new",
    name: "FormBannerCreate",
    component: FormBanner,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Create Banner",
    },
  },
  {
    path: "/banner/edit/:id",
    name: "FormBannerEdit",
    component: FormBanner,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Edit Banner",
    },
  },
  {
    path: "/banner/watch/:id",
    name: "FormBannerWatch",
    component: FormBanner,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Detail Banner",
    },
  },
  {
    path: "/hit-banner/new",
    name: "FormHitBannerCreate",
    component: FormHitBanner,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Create Banner",
    },
  },
  {
    path: "/hit-banner/edit/:id",
    name: "FormHitBannerEdit",
    component: FormHitBanner,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Edit Banner",
    },
  },
  {
    path: "/hit-banner/watch/:id",
    name: "FormHitBannerWatch",
    component: FormHitBanner,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Detail Banner",
    },
  },
  {
    path: "/kampus-merdeka/new",
    name: "FormKampusMerdekaCreate",
    component: FormKampusMerdeka,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Buat Program Kampus Merdeka",
    },
  },
  {
    path: "/kampus-merdeka/edit/:id",
    name: "FormKampusMerdekaCreate",
    component: FormKampusMerdeka,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Edit Program Kampus Merdeka",
    },
  },
  {
    path: "/kampus-merdeka/watch/:id",
    name: "FormKampusMerdekaCreate",
    component: FormKampusMerdeka,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("user")) {
        next({ path: "/login", replace: true });
      } else {
        next();
      }
    },
    meta: {
      name: "Lihat Program Kampus Merdeka",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
