<template>
  <div class="body-form">
    <Loading v-if="isLoading" />
    <Popup
      :message="message"
      v-if="isPopup"
      @close="() => (isPopup = false)"
    />
    <div class="cart-form">
      <h1>{{ name }}</h1>
      <v-breadcrumbs :items="breadcrumbs" divider=">" class="breadcrumbs">
      </v-breadcrumbs>
      <div v-if="isWatch">
        <v-row style="margin: 0px; gap: 6px">
          <History></History>
          <span style="font-weight: 500; color: #5b5b5b; line-height: 22px">
            {{ timeDifference }}
          </span>
        </v-row>
        <h2>{{ form.name }}</h2>
        <v-row style="margin: 0px; gap: 8px">
          <div class="pill">{{ form.is_active ? "Aktif" : "Non Aktif" }}</div>
          <span class="pill">{{ form.type }}</span>
          <div class="pill pill-alert" v-if="form.is_schedule">
            Due Date - {{ dueDate }}
          </div>
        </v-row>
      </div>
      <div v-if="!isWatch">
        <div class="content-form">
          <p>Title</p>
          <v-text-field
            outlined
            v-model="form.name"
            class="form-input"
            placeholder="Title Banner"
            dense
            clearable
          ></v-text-field>
        </div>

        <div class="content-form">
          <p>Type</p>
          <v-select
            class="form-select"
            :items="types"
            :item-text="'name'"
            :item-value="'value'"
            v-model="form.type"
            outlined
          >
          </v-select>
        </div>
        <v-switch
          v-model="form.is_active"
          inset
          :label="
            form.is_active ? 'Banner Aktif' : 'Geser untuk mengaktifkan banner'
          "
        ></v-switch>
      </div>
      <div v-if="isWatch">
        <p style="font-weight: 700; font-size: 20px" class="mt-4">
          Berkas Gambar
        </p>
        <p style="font-weight: 500">Web Desktop</p>
        <v-row style="margin: 0px; padding: 10px 20px" class="watch-content">
          <ImageModal
            :src="form.img_link_url_web"
            width="76.31"
            height="37"
            radius="3"
          />
          <span>{{ img_web_name }}</span>
        </v-row>
        <p style="font-weight: 500" class="mt-4">Mobile User</p>
        <v-row style="margin: 0px; padding: 10px 20px" class="watch-content">
          <ImageModal
            :src="form.img_link_url_mobile"
            width="76.31"
            height="37"
            radius="3"
          />
          <span>{{ img_mobile_name }}</span>
        </v-row>
        <p style="font-weight: 500" class="mt-4">Targeting Campaign</p>
        <v-row style="margin: 0px; padding: 10px 20px" class="watch-content">
          <v-col cols="11">
            <a
              :href="form.link_url"
              style="
                text-overflow: ellipsis;
                width: 100%;
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
              "
              target="_blank"
              >{{ form.link_url }}</a
            >
          </v-col>
          <v-col cols="1">
            <button @click="copyLink"><CopyIcon></CopyIcon></button>
          </v-col>
        </v-row>
      </div>
      <div v-if="!isWatch">
        <div
          class="content-form set-time"
          :style="
            form.is_schedule
              ? 'border: 1px solid #4456B4'
              : 'border: 1px solid #ffffff'
          "
        >
          <v-row class="d-flex justify-space-between align-center">
            <p style="font-weight: 600; margin-left: 11px">Set Time</p>
            <v-switch
              v-model="form.is_schedule"
              inset
              class="small-switch"
            ></v-switch>
          </v-row>
          <v-row>
            <v-col cols="6">
              <p>Start (Date)</p>
              <v-menu
                ref="startPicker"
                v-model="startPicker"
                :close-on-content-click="false"
                :return-value.sync="form.start_datetime"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.start_datetime"
                    placeholder="12-02-2023"
                    class="form-input"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    clearable
                    :disabled="!form.is_schedule"
                  >
                    <template v-slot:append>
                      <Calendar
                        style="margin-top: 35%; cursor: pointer"
                      ></Calendar>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="form.start_datetime"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="startPicker = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startPicker.save(form.start_datetime)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <p>End (Date)</p>
              <v-menu
                ref="endPicker"
                v-model="endPicker"
                :close-on-content-click="false"
                :return-value.sync="form.end_datetime"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="form.end_datetime"
                    placeholder="12-02-2023"
                    class="form-input"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    clearable
                    :disabled="!form.is_schedule"
                  >
                    <template v-slot:append>
                      <Calendar
                        style="margin-top: 35%; cursor: pointer"
                      ></Calendar>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="form.end_datetime" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="endPicker = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endPicker.save(form.end_datetime)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div class="content-form">
          <p>Web Desktop</p>
          <v-file-input
            v-model="img_web"
            accept="image/*"
            placeholder="Unggah Berkas Gambar"
            class="form-upload"
            prepend-icon=""
            outlined
          >
            <template v-slot:prepend-inner v-if="isEdit && img_web == null">
              <ImageModal
                :src="form.img_link_url_web"
                width="76.31"
                height="37"
                radius="3"
              />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">
            *Rekomendasi dimensi 327 x 462 px. ukuran file maks 1 MB
          </p>
        </div>
        <div class="content-form">
          <p>Mobile Browser</p>
          <v-file-input
            v-model="img_mobile"
            accept="image/*"
            placeholder="Unggah Berkas Gambar"
            class="form-upload"
            prepend-icon=""
            outlined
          >
            <template v-slot:prepend-inner v-if="isEdit && img_mobile == null">
              <ImageModal
                :src="form.img_link_url_mobile"
                width="76.31"
                height="37"
                radius="3"
              />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">
            *Rekomendasi dimensi 218 x 320 px. ukuran file maks 1 MB
          </p>
        </div>
        <div class="content-form">
          <p>Targeting Campaign</p>
          <v-text-field
            :disabled="isWatch"
            outlined
            v-model="form.link_url"
            class="form-input"
            placeholder="Masukan URL Targeting Campaign"
            dense
            clearable
          ></v-text-field>
        </div>
        <div class="submit-box" v-if="!isWatch">
          <Button
            name="Batalkan"
            outline
            class="me-3"
            @click="$router.push('/main-banner')"
          />
          <Button
            :name="isEdit ? 'Simpan' : 'Simpan & Publish'"
            :width="isEdit ? '156px' : '200px'"
            @click="onSubmit"
            :disabled="!validData"
            style="background-color: #3b54d9; color: #ffffff"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/ilustration/Loading.vue";
import Button from "@/components/Button.vue";
import Calendar from "@/components/icons/Calendar.vue";
import CopyIcon from "@/components/icons/Copy.vue";
import moment from "moment";
import ImageModal from "@/components/ImageModal.vue";
import Popup from "@/components/others/Popup.vue";


export default {
  name: "FormBlog",
  data() {
    return {
      id: null,
      modal: false,
      startPicker: false,
      endPicker: false,
      name: "",
      message: "",
      isPopup: false,
      isLoading: false,
      isWatch: false,
      isEdit: false,
      tags: [],
      types: [
        { name: "Awareness", value: "awareness" },
        { name: "Sales", value: "sales" },
      ],
      statuses: [
        { name: "Aktif", value: true },
        { name: "Non Aktif", value: false },
      ],
      form: {
        name: "",
        link_url: "",
        category: "GENERAL",
        type: "",
        is_active: false,
        img_link_url_web: "",
        img_link_url_mobile: "",
        is_schedule: false,
        start_datetime: "",
        end_datetime: "",
      },
      img_web: null,
      img_mobile: null,
      img_web_name: "",
      img_mobile_name: "",
      breadcrumbs: [
        { text: "Master Data", disabled: false, href: "/main-banner" },
        { text: "Main Banner", disabled: false, href: "/main-banner" },
      ],
    };
  },
  components: {
    Button,
    Loading,
    Calendar,
    History,
    CopyIcon,
    ImageModal,
    Popup
  },
  computed: {
    validData() {
      const validTime = () => {
        if (this.form.is_schedule) {
          return this.form.start_datetime && this.form.end_datetime;
        }
        return true;
      };
      if (this.isEdit) {
        return this.form.name && this.form.link_url && validTime();
      }
      return (
        this.form.name &&
        this.form.link_url &&
        this.img_web &&
        this.img_mobile &&
        validTime()
      );
    },
    timeDifference: function () {
      const lastUpdate = moment(this.form.updated_at);

      const minutesDifference = moment().diff(lastUpdate, "minutes");
      const dayDifference = moment().diff(lastUpdate, "days");
      const hourDifference = moment().diff(lastUpdate, "hours");

      if (minutesDifference < 1)
        return "Terakhir diperbarui beberapa detik yang lalu";
      else if (hourDifference < 1) {
        return "Terakhir diperbarui " + minutesDifference + " menit yang lalu";
      } else if (hourDifference < 24) {
        return "Terakhir diperbarui " + hourDifference + " jam yang lalu";
      } else {
        return "Terakhir diperbarui " + dayDifference + " hari yang lalu";
      }
    },

    dueDate: function () {
      console.log(this.form.end_datetime);
      return moment(this.form.end_datetime).format("MMM D, yyyy");
    },
  },
  methods: {
    checkingType() {
      this.name = this.$route.meta.name;
      this.breadcrumbs.push({ text: this.$route.meta.name, disabled: false });
      this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
      this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
      this.id = this.$route.params.id;
      if (!this.id) return;
      this.getDataBanner();
    },
    async getDataBanner() {
      this.isLoading = true;
      try {
        const resp = await this.$crm_http.get("/v1/banner/" + this.id);
        this.form = resp.data.data.banner;
        if (this.form.is_schedule) {
          this.form.start_datetime = moment(this.form.start_datetime).format(
            "YYYY-MM-DD"
          );
          this.form.end_datetime = moment(this.form.end_datetime).format(
            "YYYY-MM-DD"
          );
        } else {
          this.form.start_datetime = null;
          this.form.end_datetime = null;
        }
        this.img_web_name = this.form.img_link_url_web
          .split("/")
          .pop()
          .replace("?view=open", "")
          .replaceAll("%20", " ");
        this.img_mobile_name = this.form.img_link_url_mobile
          .split("/")
          .pop()
          .replace("?view=open", "")
          .replaceAll("%20", " ");
      } catch (err) {
        this.message = err.response.data.data
        this.isPopup = true
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        if (this.isEdit) {
          if (this.img_web) {
            const imageForm = new FormData();
            imageForm.append("attachment", this.img_web);
            const webRes = await this.$crm_http.post(
              "/v1/upload-file",
              imageForm
            );
            this.form.img_link_url_web =
              webRes.data.data.upload_image_response.url_w_query;
            imageForm.delete("attachment");
          }
          if (this.img_mobile) {
            const imageForm = new FormData();
            imageForm.append("attachment", this.img_mobile);
            const mobileRes = await this.$crm_http.post(
              "/v1/upload-file",
              imageForm
            );
            this.form.img_link_url_mobile =
              mobileRes.data.data.upload_image_response.url_w_query;
            imageForm.delete("attachment");
          }

          await this.$crm_http.put("/v1/banner/" + this.form.id, this.form);
        } else {
          const imageForm = new FormData();
          imageForm.append("attachment", this.img_web);
          const webRes = await this.$crm_http.post(
            "/v1/upload-file",
            imageForm
          );
          this.form.img_link_url_web =
            webRes.data.data.upload_image_response.url_w_query;

          imageForm.delete("attachment");
          imageForm.append("attachment", this.img_mobile);
          const mobileRes = await this.$crm_http.post(
            "/v1/upload-file",
            imageForm
          );
          this.form.img_link_url_mobile =
            mobileRes.data.data.upload_image_response.url_w_query;

          await this.$crm_http.post("/v1/banner", this.form);
          imageForm.delete("attachment");
        }
        setTimeout(() => {
          this.$router.push("/main-banner");
        }, 2000);
      } catch (error) {
        this.message = "Ukuran gambar tidak boleh lebih besar dari 2 MB"
        this.isPopup = true
        this.isLoading = false;
      }
    },
    copyLink() {
      const input = document.createElement("input");
      input.style.position = "fixed";
      input.style.opacity = 0;
      input.value = this.form.link_url;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      alert("Link copied to clipboard");
    },
  },
  mounted() {
    this.checkingType();
  },
};
</script>

<style scoped>
.body-form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f9fd;
  padding: 50px 0;
  min-height: 100vh;
  width: 100vw;
}
.cart-form {
  position: relative;
  width: 50%;
  border-radius: 30px;
  padding: 30px 40px;
  border: 1px solid #d6d6d6;
  background-color: #ffffff;
}
.sub-form {
  margin-top: 20px;
  background-color: #f5f6fa;
  padding: 26px 32px;
  border-radius: 16px;
}
.back-icon {
  position: fixed;
  top: 30px;
  left: 30px;
  cursor: pointer;
  color: rgb(198, 198, 198);
}
.form-input {
  border-radius: 10px !important;
}

::v-deep .form-input .v-input__slot {
  height: 62px !important;
  margin-bottom: 20px;
  background-color: #ffffff !important;
}

::v-deep .form-input .v-text-field__details {
  display: none;
}

::v-deep .form-upload .v-input__slot {
  height: 62px !important;
  background-color: #ffffff !important;
}

::v-deep .form-upload .v-text-field__details {
  display: none;
}

::v-deep .form-select .v-input__slot {
  height: 56 !important;
  margin-bottom: 20px;
  background-color: #ffffff;
}

::v-deep .form-select .v-text-field__details {
  display: none;
}

.content-form {
  margin-top: -5px;
}
.content-form p {
  font-size: 14px;
  margin: 3px 0;
  font-weight: 400;
}

.set-time {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.banner-berkas {
  font-size: 18px;
  margin: 20px 0;
  font-weight: bold;
}
.watch-ctn {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid gray;
}
.submit-box {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.alert-info {
  font-size: 14px;
  color: #7b7e8c;
}
@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
.note-file {
  font-weight: 400;
  font-size: 12px;
  color: #b80f0a;
  margin-bottom: 20px !important;
}

.breadcrumbs {
  padding: 0px;
  margin-left: 0px;
  margin-bottom: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

::v-deep .v-breadcrumbs__divider {
  color: #162ca2 !important;
}

::v-deep .v-breadcrumbs__item {
  color: #162ca2;
}

::v-deep div.v-breadcrumbs__item {
  color: #7b7e8c !important;
}

.small-switch {
  transform: scale(0.75);
}

.pill {
  background-color: #f9f9f9;
  border-color: #d6d6d6;
  border: 1px solid;
  border-radius: 100px;
  padding: 4px 12px 4px 12px;
  color: #7b7e8c;
  text-transform: capitalize;
}

.pill-alert {
  background-color: #f7e7e6;
  border: 0px;
  color: #b80f0a;
  text-transform: none;
}

.watch-content {
  background-color: #ffffff;
  border: 0.5px solid #d6d6d6;
  border-radius: 10px;
}

.watch-content a {
  text-decoration: none !important;
  color: #2d46cf;
  font-weight: 400;
}
</style>
