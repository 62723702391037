<template>
    <div class="ma-10">
      <Loading v-if="isLoading" />
      <Popup
        :message="message"
        v-if="isPopup"
        @close="() => (isPopup = false)"
      />
      <div class="top-container-box">
        <h1>Riwayat Blast Email (General)</h1>
      </div>
    
      <v-data-table
        :headers="headers"
        :items="birthdays"
        sort-by="calories"
        class="elevation-1 mt-10 table"
        hide-default-footer
      >
        <!-- <UpdateDeleteBlog v-slot:item.actions/> -->
        <template v-slot:item.status="{ item }">
          <v-chip :color="item.status ? 'green' : '#FFBF00'" dark>
            {{ item.status ? "Terkirim" : "DI Jadwalkan" }}
          </v-chip>
        </template>
        <template v-slot:item.sent_at="{ item }">
          {{ $helpers.getDateFull(item.sent_at) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <button @click="watchItem(item)" class="me-2">
            <WatchTable height="28" width="28" />
          </button>
        </template>
      </v-data-table>
      <v-row justify="center" v-if="total >= 1">
        <v-col cols="4">
          <v-container class="max-width">
            <v-pagination
              v-model="currentPage"
              class="my-4"
              :length="total"
              @input="getBirthday"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
  
      <!-- Display when no data is available -->
      <NoDataAlert :totalData="total" />
  
      <p :class="[total >= 1 ? 'count-data' : 'no-pagination']" v-if="totalData">
        <b
          >{{ Math.floor((currentPage - 1) * this.perPage + birthdays.length) }} dari
          {{ totalData }}</b
        >
        Data yang tertampil
      </p>
    </div>
  </template>
  
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import WatchTable from "@/components/icons/WatchTable.vue";
  import NoDataAlert from "@/components/NoDataAlert.vue";
  import Popup from "@/components/others/Popup.vue";
  
  export default {
    data() {
      return {
        isModal: false,
        currentPage: 1,
        total: 0,
        search: "",
        isLoading: false,
        id: 0,
        message: "",
        isPopup: false,
        totalData: 0,
        perPage: 10,
        filter: {
          sort_by: "DESC",
          category: "",
        },
        showFilterModal: false,
        filterModal: [
          {
            title: "Kategori",
            name: "category",
            items: [
              {
                name: "Semua",
                value: "",
              },
              {
                name: "Dp Pijar",
                value: "PIJAR",
              },
            ],
          },
          {
            title: "Urutkan",
            name: "sort_by",
            items: [
              {
                name: "Terlama",
                value: "asc",
              },
              {
                name: "Terbaru",
                value: "desc",
              },
            ],
          },
        ],
        headers: [
          {
            text: "ID",
            value: "id",
          },
          { text: "Email penerima", value: "recipient_email", width: "250" },
          { text: "Nama penerima", value: "recipient_name", width: "250" },
          { text: "Subject", value: "subject", width: "350" },
          { text: "Status kirim", value: "status" },
          { text: "Dikirim pada", value: "sent_at", width: "250" },
          { text: "AKSI", value: "actions", sortable: false, width: "132" },
        ],
        birthdays: [],
      };
    },
    components: {
      Loading,
      WatchTable,
      NoDataAlert,
      Popup
    },
    methods: {
      applyFilters() {
        this.getBirthday();
        this.showFilterModal = false;
      },
      toggleFilterModal() {
        this.showFilterModal = !this.showFilterModal;
      },
      updateFilters(value) {
        this.filter[value.name] = value.value;
      },
      async getBirthday() {
        await this.$web_http.get(`/v1/email-blasting?page=${this.currentPage}&per_page=${this.perPage}`)
          .then((response) => {
            this.birthdays = response.data.data.email_blastings || [];
            const t = parseInt(response.data.data.total);
            this.total = Math.ceil(t / this.perPage);
            this.totalData = response.data.data.total;
          })
          .catch((err) => {
            this.message = err.response.data.data
            this.isPopup = true
          });
      },
      watchItem(item) {
        this.$router.push("/blast-history/" + item.id);
      },
      clearFilter() {
        this.filter = {
          category: "",
          sort_by: "desc",
        };
        this.getBirthday();
      },
      handleClearButtonClick() {
        this.filter = {
          category: "",
          sort_by: "desc",
        };
      },
    },
    mounted() {
      this.getBirthday();
    },
  };
  </script>
  
  <style scoped>
  .action-head {
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-title {
    font-weight: bold;
    font-size: 19px;
  }
  
  .f-search {
    border: 1px solid #d3d6e1;
    width: 350px;
    height: 45px;
    gap: 10px;
    display: flex;
    border-radius: 14px;
  }
  
  .f-search-ic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    margin-left: 10px;
  }
  
  .f-input {
    width: 86%;
    background: transparent;
    border: none;
  }
  
  .f-input:focus {
    background: transparent;
    border: none;
    outline-width: 0;
  }
  
  .top-container-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  
  .count-data {
    position: relative;
    top: -60px;
    width: 30%;
  }
  
  .no-pagination {
    margin-top: 20px;
    margin-left: 5px;
  }
  </style>
  