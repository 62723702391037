<template>
  <div class="forms">
    <Loading v-if="isLoading" />
    <Popup :message="message" v-if="isPopup" @close="() => (isPopup = false)" />
    <div class="body-form">
      <!-- breadcrumbs -->
      <v-breadcrumbs :items="breadcrumbs" divider=">" class="breadcrumbs">
      </v-breadcrumbs>
      <div class="cart-form">
        <div class="form-ctn">
          <br /><br />
          <div class="client">
            <div class="content-form">
              <p>Title</p>
              <v-radio-group v-model="form.title" row>
                <v-radio
                  v-for="title in titles"
                  :key="title.value"
                  :label="title.label"
                  :value="title.value"
                ></v-radio>
              </v-radio-group>
            </div>

            <div class="content-form">
              <p>Nama Lengkap</p>
              <input
                :disabled="isWatch"
                v-model="form.full_name"
                class="form-input"
                type="text"
              />
            </div>

            <div class="content-form">
              <p>No. Handphone</p>
              <v-row>
                <v-col cols="2" class="phone">
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background-color: #e7e8f0;
                      border-radius: 10px;
                      padding: 12px 20px;
                      gap: 10px;
                    "
                  >
                    <img
                      src="https://t9003038375.p.clickup-attachments.com/t9003038375/47d99b5a-2a4e-4e5b-b5ef-bf4163e4d488/indonesia.png?view=open"
                      :width="27"
                      :height="18"
                      alt="Indonesia Flag"
                    />
                    <p
                      style="
                        font-weight: 600;
                        margin: 0;
                        font-size: 14px;
                        line-height: 32px;
                        color: #575966;
                      "
                    >
                      +62
                    </p>
                  </div>
                </v-col>
                <v-col>
                  <input
                    v-model="form.phone_number"
                    class="form-input"
                    type="text"
                    name="phone_number"
                    id="phone_number"
                  />
                </v-col>
              </v-row>
            </div>

            <div class="content-form">
              <p>Email</p>
              <input
                :disabled="isWatch"
                v-model="form.email"
                class="form-input"
                type="text"
              />
            </div>

            <div class="content-form">
              <p>Tanggal Lahir</p>
              <input
                :disabled="isWatch"
                v-model="form.birthdate"
                class="form-input"
                type="date"
              />
            </div>
          </div>

          <div class="content-form">
            <p>Roles</p>
            <select
              v-model="form.role"
              class="form-input"
              placeholder="Pilih Role"
            >
              <option
                v-for="category in categories"
                :key="category.value"
                :value="category.user_type"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="content-form">
          <p>Profile</p>
          <v-file-input v-model="img_web" accept="image/*" placeholder="Unggah Berkas Gambar" class="form-upload" prepend-icon="" outlined>
            <template v-slot:prepend-inner v-if="img_web == null">
              <ImageModal :src="form.image" width="76.31" height="37" radius="3" />
            </template>
            <template v-slot:append>
              <h5 style="margin-top: 15%; color: #2d46cf">Upload</h5>
            </template>
          </v-file-input>
          <p class="note-file">*Rekomendasi dimensi 327 x 462 px. ukuran file maks 1 MB</p>
        </div>
          <div class="content-form">
            <p>Kata Sandi</p>
            <input v-model="form.password" class="form-input" type="password" />
          </div>

          <div class="content-form">
            <p>Konfirmasi Kata Sandi</p>

            <input
              v-model="form.confirmPassword"
              class="form-input"
              type="password"
            />
          </div>
          <div class="submit-box mt-8" v-if="!isWatch">
            <Button name="Cancel" outline class="me-3" @click="onCancel" />
            <Button
              name="Save"
              @click="onSubmit"
              :disabled="!validData"
              style="background-color: #3b54d9; color: #ffffff"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "@/components/ilustration/Loading.vue";
import Button from "@/components/Button.vue";
import Popup from "@/components/others/Popup.vue";
import ImageModal from '@/components/ImageModal.vue';

export default {
  name: "UserActiveForm",
  data() {
    return {
      id: null,
      name: "",
      isLoading: false,
      isWatch: false,
      message: "",
      isPopup: false,
      selectedTitle: null,
      img_web: null,
      titles: [
        {
          label: "Tuan",
          value: "Tuan",
        },
        {
          label: "Nyonya",
          value: "Nyonya",
        },
        { label: "Nona", value: "Nona" },
      ],
      roles: [
        { label: "Admin", value: 1 },
        { label: "Member", value: 2 },
        { label: "Pengunjung", value: 3 },
        { label: "Keuangan", value: 4 },
        { label: "Manajemen", value: 5 },
        { label: "Menejemen Konten", value: 6 },
      ],
      categories: [
        {
          name: "Admin",
          value: "ADMIN",
          user_type: 1,
        },
        {
          name: "User",
          value: "USER",
          user_type: 2,
        },
        {
          name: "Pengunjung",
          value: "Pengunjung",
          user_type: 3,
        },
        {
          name: "Keuangan",
          value: "Keuangan",
          user_type: 4,
        },
        {
          name: "Manajemen",
          value: "Manajemen",
          user_type: 5,
        },
        {
          name: "Menejemen Konten",
          value: "Menejemen Konten",
          user_type: 6,
        },
      ],
      breadcrumbs: [
        { text: "User Management", disabled: false, href: "/user" },
        { text: "User Aktif", disabled: false, href: "/user" },
        { text: "Buat User Baru", disabled: true, href: "/user/new" },
      ],
      showPassword: false,
      showConfirmPassword: false,
      form: {
        title: "",
        full_name: "",
        phone_number: "",
        email: "",
        image: "",
        birthdate: "",
        role: 2,
        password: "",
        confirmPassword: "",
      },
    };
  },
  components: {
    Loading,
    Button,
    Popup,
    ImageModal
  },
  computed: {
    validData() {
      return (
        this.form.full_name &&
        this.form.phone_number &&
        this.form.email &&
        this.form.birthdate
      );
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async onSubmit() {
      const payload = {
        image: this.form.image || "",
        full_name: this.form.full_name || "",
        phone_number: this.form.phone_number || "",
        email: this.form.email || "",
        birthdate: this.form.birthdate || "",
        gender: this.form.title || "",
        role: Number(this.form.role),
        password: "",
      };
      if (this.form.password) {
        if (this.form.password != this.form.confirmPassword) {
          this.isPopup = true
          this.message = "Password dan konfirmasi password tidak sama"
          return
        }
        payload.is_reset_password = true
        payload.password = this.form.password
      }
      this.isLoading = true;
      if (this.img_web) {
            const imageForm = new FormData();
            imageForm.append('attachment', this.img_web);
            const webRes = await this.$crm_http.post('/v1/upload-file', imageForm);
            payload.image = webRes.data.data.upload_image_response.url_w_query;
            imageForm.delete('attachment');
          }
      try {
        this.isLoading = true;
        await this.$crm_http.post(`/v1/user`, payload);
        this.isLoading = false; 
        this.$router.push("/user");
      } catch (err) {
        this.isLoading = false; 
        this.isPopup = true
        this.message = err.response.data.data || "Something when wrong"
      }
    },
    onCancel() {
      this.$router.push("/user");
    },
  },
};
</script>
<style scoped>
.forms {
  overflow-x: hidden;
}
.body-form {
  justify-content: center;
  padding: 30px 80px;
  min-height: 100vh;
  width: 100vw;
}

.breadcrumbs {
  text-align: center;
}
.form-ctn {
  padding: 0 40px;
}
.cart-form {
  background-color: white;
  position: relative;
  width: 70%;
}

.form-input {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  padding: 15px 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #575966;
  /* border-radius: 14px !important;
  background-color: transparent !important; */
}

.content-form {
  margin-top: 20px;
}
.content-form p {
  font-size: 16px;
  margin: 3px 0;
  font-weight: bold;
}

.submit-box {
  display: flex;
  justify-content: right;
}

button {
  margin-bottom: 30px;
}

@media screen and (max-width: 1100px) {
  .cart-form {
    width: 70%;
  }
}
</style>
