<template>
    <div class="ma-10" style="display: flex; min-height: 80vh;">
        <aside class="sidebar">
            <h2>Select Template</h2>
            <ul class="categories mt-10">
                <li v-for="(item, idx) in categories" :key="idx" :class="category === item.value ? 'active' : ''"
                    @click="changeCategory(item.value)">{{ item.name }}</li>
            </ul>
        </aside>
        <main class="content">
            <div class="header">
                <h1>{{ getTitle(category) }}</h1>
                <button class="new-template" @click="$router.push('/blast-email/new')">Create new template</button>
            </div>
            <div class="templates mt-10">
                <v-row>
                <v-col cols="4" class="template-box" v-for="(data, idx) in blasts" :key="idx">
                    <template v-if="data.category === 'IMAGE'">
                        <div class="template-content"
                            :style="`background-image: url(${data.content ?? 'https://www.hicolleagues.com/img/corporate/collaboration/training.png'})`">
                            <div class="hover-overlay" @click="$router.push(`/blast-template/watch/${data.id}`)">
                                <button class="choose-btn">Choice template</button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="template-content">
                        <iframe :srcdoc="data.content" frameborder="0" style="font-size: 12px;">
                        </iframe>
                        <div class="hover-overlay" @click="$router.push(`/blast-template/watch/${data.id}`)">
                                <button class="choose-btn">Choice template</button>
                            </div>
                    </div>
                    </template>
                    <p class="template-name">{{ data.name }}</p>
                </v-col>
                </v-row>
            </div>
            <v-row justify="center" v-if="pagination.total > 1">
      <v-col cols="4">
        <v-container class="max-width">
          <v-pagination
            v-model="pagination.page"
            class="my-4"
            :length="pagination.total"
            @input="getBlastTemplate"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
        </main>
    </div>
</template>

<script>
export default {
    name: "BlastTemplate",
    data() {
        return {
            categories: [
                {
                    name: "All templates",
                    value: ""
                },
                {
                    name: "Text Plant",
                    value: "TEXT"
                },
                {
                    name: "Image",
                    value: "IMAGE"
                },
            ],
            category: "",
            blasts: [],
            pagination: {
                page: 1,
                per_page: 6,
                total: 0,
                totalData: 0,
            }
        }
    },
    methods: {
        getTitle(category) {
            return this.categories.find((item) => item.value === category).name
        },
        async getBlastTemplate() {
            await this.$crm_http
                .get(
                    `/v1/email-template?category=${this.category}&page=${this.pagination.page}&per_page=${this.pagination.per_page}`
                )
                .then((response) => {
                    this.blasts = response.data.data.email_templates
                    const t = parseInt(response.data.data.total);
                    this.pagination.total = Math.ceil(t / this.pagination.per_page);
                    this.pagination.totalData = response.data.data.total;
                })
                .catch((err) => console.error(err.response));
        },
        changeCategory(val) {
            this.pagination.page = 1
            this.category = val
            this.getBlastTemplate()
        }
    },
    mounted() {
        this.getBlastTemplate()
    }
}
</script>
<style scoped>
.sidebar {
    width: 20%;
    background-color: #ffffff;
    padding: 15px;
    border-right: 1px solid #ddd;
}

.sidebar h2 {
    margin-bottom: 20px;
    font-size: 1.2em;
    color: #333;
}

.search-bar {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.categories {
    list-style: none;
}

.categories li {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    color: #333;
    cursor: pointer;
    transition: background 0.2s ease;
}

.categories li.active,
.categories li:hover {
    background-color: #e8f4ff;
    color: #0073ea;
}

.content {
    width: 80%;
    padding: 20px 30px;
    background-color: #fff;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 1.5em;
    color: #333;
}

.new-template {
    background-color: #0073ea;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.new-template:hover {
    background-color: #005bb5;
}

.templates {
    text-align: center;
}

.template-box {
    text-align: center;
}

.template-content {
    width: 100%;
    border: 1px solid gray;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-position: center;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.template-content:hover {
    transform: scale(1.05);
}

.hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.template-content:hover .hover-overlay {
    opacity: 1;
}

.choose-btn,
.preview-btn {
    background-color: white;
    color: #333;
    border: none;
    padding: 8px 12px;
    margin: 5px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s ease;
}

.choose-btn:hover,
.preview-btn:hover {
    background-color: #007bff;
    color: white;
}

.template-name {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
</style>