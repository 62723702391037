<template>
  <div class="ma-10">
    <!-- modal showing detail -->
    <Modal
      v-if="isModalDetail"
      @close="() => (isModalDetail = false)"
      width="40%"
    >
      <p class="modal-title">Detail Transaksi User</p>

      <div class="modal-body">
        <div class="data-list">
          <div class="detail-data">
            <p class="detail-label">No. Invoice</p>
            <p class="detail-value">{{ transactionDetail.invoice_number }}</p>
          </div>
          <div class="detail-data">
            <p class="detail-label">Tanggal Pembelian</p>
            <p class="detail-value">{{ transactionDetail.created_at }}</p>
          </div>

          <div
            class="detail-data collapse"
            @click="() => (collapse.customer = !collapse.customer)"
          >
            <p class="detail-label">Pelangan</p>
            <Arrow :down="!collapse.customer" />
          </div>
          <div class="data-list" v-if="collapse.customer">
            <div class="detail-data">
              <p class="detail-label">Nama</p>
              <p class="detail-value">{{ transactionDetail.full_name }}</p>
            </div>
            <div class="detail-data">
              <p class="detail-label">No. handphone</p>
              <p class="detail-value">
                (+62) {{ transactionDetail.phone_number }}
              </p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Email</p>
              <p class="detail-value">{{ transactionDetail.email }}</p>
            </div>
          </div>

          <div
            class="detail-data collapse"
            @click="() => (collapse.product = !collapse.product)"
          >
            <p class="detail-label">Produk</p>
            <Arrow :down="!collapse.product" />
          </div>
          <div class="data-list" v-if="collapse.product">
            <div class="detail-data">
              <p class="detail-label">ID Produk</p>
              <p class="detail-value">{{ transactionDetail.class.id }}</p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Program</p>
              <p class="detail-value" style="text-align: right; width: 250px;">{{ transactionDetail.class.name }}</p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Harga</p>
              <p class="detail-value">{{ transactionDetail.price }}</p>
            </div>
          </div>

          <div
            class="detail-data collapse"
            @click="() => (collapse.transaction = !collapse.transaction)"
          >
            <p class="detail-label">Transaksi</p>
            <Arrow :down="!collapse.transaction" />
          </div>
          <div class="data-list" v-if="collapse.transaction">
            <div class="detail-data">
              <p class="detail-label">Kode Voucher</p>
              <p class="detail-value">{{ transactionDetail.voucher_code }}</p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Diskon</p>
              <p class="detail-value discount">
                {{ transactionDetail.discount }}
              </p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Total Pembayaran</p>
              <p class="detail-value total">
                {{ transactionDetail.total_price }}
              </p>
            </div>
            <div class="detail-data">
              <p class="detail-label">Status</p>
              <p class="detail-value">{{ transactionDetail.status }}</p>
            </div>
          </div>

          <div
            class="detail-data collapse"
            @click="() => (collapse.logs = !collapse.logs)"
          >
            <p class="detail-label">Riwayat Transaksi</p>
            <Arrow :down="!collapse.logs" />
          </div>
          <div class="data-list" v-if="collapse.logs">
            <div
              class="log-item"
              v-for="(item, idx) in transactionLog"
              :key="idx"
            >
              <BlueBullet />
              <div class="log-data">
                <div
                  v-if="idx != transactionLog.length - 1"
                  class="connector"
                ></div>
                <p>{{ formatDateLog(item.created_at) }}</p>
                <p>{{ item.status | toMessageLog }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <Modal v-if="isModal" @close="() => (isModal = false)" width="40%">
      <p class="modal-title">Konfirmasi</p>
      <p>Apakah Anda yakin akan mengunduh data transaksi?</p>
        <div style="display: flex; gap: 20px; margin-top: 30px">
        <export-excel
          class="btn btn-default"
          :data="transactionDownload"
          worksheet="sheet 1"
          name="transaksi-prakerja-1.xls"
        >
          YA, UNDUH
        </export-excel>
        <Button
          name="TIDAK, KEMBALI"
          width="100%"
          outline
          @click="() => (isModal = false)"
        />
      </div>
    </Modal>
    <div class="title-page">
      <h1 class="mb-4">PRAKERJA</h1>
      <Button name="DOWNLOAD" outline @click="isModal = true" width="180px"><Download /></Button>
    </div>
    <div class="container-box">
      <div class="filter-ctn">
        <div>
          <span class="">Cari</span>
          <v-text-field
            prepend-inner-icon="mdi-magnify"
            outlined
            v-model="filter.search"
            class="form-input"
            placeholder="Cari Nama / No. Invoice"
            dense
            clearable
          ></v-text-field>
        </div>
        <div>
          <span class="">Status Pembayaran</span>
          <v-select
            placeholder="Status"
            v-model="filter.status"
            :items="statusList"
            :item-text="'name'"
            :item-value="'value'"
            class="form-input"
            outlined
            dense
          ></v-select>
        </div>
        <div>
          <span class="">Tanggal Awal Transaksi</span>
          <v-text-field
            outlined
            v-model="filter.start_date"
            class="form-input"
            type="date"
            clearable
            dense
          ></v-text-field>
        </div>
        <div>
          <span class="">Tanggal Akhir Transaksi</span>
          <v-text-field
            outlined
            type="date"
            v-model="filter.end_date"
            class="form-input"
            clearable
            dense
          ></v-text-field>
        </div>
        <div class="btn-filter-horizontal">
          <Button name="TERAPKAN FILTER" @click="onFilter" width="200px" />
        </div>
        <div class="btn-filter-horizontal">
          <div class="reset-filter" @click="clearFilter" v-if="isFilterActive">
            RESET FILTER
          </div>
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="transactionPrakerja"
      sort-by="calories"
      class="elevation-1 mt-10 table"
      hide-default-footer
    >
      <template v-slot:item.status="{ item }">
        {{ item.status | toMessageStatus }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <button @click="watchItem(item.id)" class="me-2" style="margin-left: 18px;">
            <WatchTable :height="28" :width="28" />
          </button>
        </v-row>
      </template>
    </v-data-table>

    <v-row justify="center" v-if="total">
      <v-col cols="8">
        <v-container class="max-width">
          <v-pagination
            v-model="currentPage"
            class="my-4"
            :length="total"
            @input="getTransactionPrakerja"
          ></v-pagination>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import Button from "@/components/Button.vue";
import Download from "@/components/icons/Download.vue";
import BlueBullet from "@/components/icons/BlueBullet.vue";
import Arrow from "@/components/icons/Arrow.vue";
import Modal from "@/components/Modal.vue";
// import EditTable from "@/components/icons/EditTable.vue";
import WatchTable from "@/components/icons/WatchTable.vue";
import getAuthentification from "@/utils/badrequest.js";

export default {
  data() {
    return {
      currentPage: 1,
      total: 0,
      select: "all",
      isFilterActive: false,
      isModal: false,
      isModalDetail: false,
      transactionDetail: {},
      transactionLog: [],
      transactionDownload: [],
      collapse: {
        customer: true,
        product: true,
        transaction: true,
        logs: true,
      },
      filter: {
        program: "",
        status: "",
        start_date: "",
        end_date: "",
        search: "",
      },
      trasnactionType: [
        {
          name: "Semua",
          value: "all",
        },
        {
          name: "Sudah bayar",
          value: "done",
        },
        {
          name: "Belum bayar",
          value: "progress",
        },
      ],
      programList: [
        {
          name: "Webinar",
          value: "webinar",
        },
        {
          name: "Mini Bootcamp",
          value: "mini-bootcamp",
        },
      ],
      statusList: [
        {
          name: "Draft",
          value: "DRAFT",
        },
        {
          name: "Menunggu Pembayaran",
          value: "WAITING_FOR_PAYMENT",
        },
        {
          name: "Sudah dibayar",
          value: "PAID",
        },
        {
          name: "Kadaluarsa",
          value: "EXPIRED",
        },
        {
          name: "Cancel",
          value: "CANCEL",
        },
      ],
      headers: [
        { text: "NO. INVOICE", value: "invoice_number", sortable: false },
        {
          text: "TANGGAL PEMBELIAN",
          value: "created_at",
          sortable: false,
          width: "180px",
        },
        { text: "PELANGGAN", sortable: false, value: "full_name" },
        {
          text: "PROGRAM",
          value: "class.name",
          sortable: false,
          width: "200px",
        },
        { text: "VOUCHER", value: "voucher_code", sortable: false },
        {
          text: "NOMINAL TRANSAKSI",
          value: "total_price",
          sortable: false,
          width: "180px",
        },
        { text: "STATUS", value: "status", sortable: false, width: "200px" },
        {
          text: "Action",
          value: "actions",
          width: "108px",
          fixed: true,
          sortable: false,
        },
      ],
      transactionPrakerja: [],
    };
  },
  components: {
    Button,
    Download,
    Modal,
    Arrow,
    BlueBullet,
    // EditTable,
    WatchTable,
  },
  methods: {
    async getTransactionDetail(id) {
      await this.$web_http
        .get(`/v1/transaction/${id}?category=Kelas Prakerja`)
        .then((response) => {
          const data = response.data.data.transaction;
          this.transactionDetail = {
            ...data,
            created_at: this.$helpers.formatDateWithTimeAndTimeZone(
              data.created_at
            ),
            total_price: data.total_price
              ? this.$helpers.formatCurrency(data.total_price)
              : "GRATIS",
            price: this.$helpers.formatCurrency(data.price),
            discount: data.discount
              ? this.$helpers.formatCurrency(data.discount, true)
              : "-",
            status: this.toMessageStatus(data.status),
            voucher_code: data.voucher_code ? data.voucher_code : "-",
          };
        })
        .catch(() => (this.transactionDetail = {}));
    },
    async getTransactionLog(id) {
      await this.$web_http
        .get(`/v1/transaction-log/transaction/${id}`)
        .then((response) => {
          this.transactionLog = response.data.data.transaction_logs;
        })
        .catch(() => (this.transactionDetail = {}));
    },
    async getTransactionPrakerja() {
      await this.$web_http
        .get(
          `/v1/transaction?category=PRAKERJA_CLASS&program=${this.filter.program}&status=${this.filter.status}&keyword=${this.filter.search}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&page=${this.currentPage}&per_page=10`
        )
        .then((response) => {
          this.transactionPrakerja = response.data.data.transactions.map(
            (item) => ({
              ...item,
              created_at: moment(item.created_at).format("DD MMM YYYY HH:mm"),
              total_price: item.total_price
                ? this.$helpers.formatCurrency(item.total_price)
                : "GRATIS",
            })
          );
          const t = parseInt(response.data.data.total);
          this.total = Math.floor(Math.abs(t / 10 + (t % 10 === 0 ? 0 : 1)));
        })
        .catch(() => {
          this.transactionPrakerja = []
          this.total = 0
          this.currentPage = 1
        });
    },
    async importExcelData() {
      await this.$web_http
        .get(`/v1/transaction?category=PRAKERJA_CLASS&program=${this.filter.program}&status=${this.filter.status}&keyword=${this.filter.search}&start_date=2023-01-01&end_date=2023-12-29&page=9&per_page=100`)
        .then((resp) => {
          this.transactionDownload = resp.data.data.transactions.map((item) => ({
              ...item,
              created_at: moment(item.created_at).format("DD MMM YYYY HH:mm"),
              total_price: item.total_price
                ? this.$helpers.formatCurrency(item.total_price)
                : "GRATIS",
            }))
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDateLog(date) {
      return moment(date).format("DD/MM/YYYY, hh:mm") + " WIB";
    },
    onFilter() {
      this.currentPage = 1;
      this.checkFilter();
      this.getTransactionPrakerja();
    },
    checkFilter() {
      if (
        this.filter.status ||
        this.filter.start_date ||
        this.filter.end_date ||
        this.filter.search
      ) {
        this.isFilterActive = true;
      }
    },
    clearFilter() {
      this.isFilterActive = false;
      this.filter = {
        status: "",
        start_date: "",
        end_date: "",
        search: "",
      };
      this.getTransactionPrakerja();
    },
    async watchItem(id) {
      await this.getTransactionDetail(id);
      await this.getTransactionLog(id);
      this.isModalDetail = true;
    },
    editItem(item) {
      this.$router.push("/transaction/b2c/event/" + item.id);
    },
    toMessageStatus(status) {
      for (let i = 0; i < this.statusList.length; i++) {
        if (this.statusList[i].value === status) {
          return this.statusList[i].name;
        }
      }
    },
  },
  beforeCreate() {
    getAuthentification();
  },
  mounted() {
    this.getTransactionPrakerja();
    this.importExcelData()
  },
};
</script>
<style scoped>
.form-input {
  border-radius: 14px !important;
  background-color: transparent !important;
}
.modal-body {
  padding: 25px;
}
.filter-ctn {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px 20px;
}
.modal-title {
  font-size: 25px;
  font-weight: bold;
}
.modal-body {
  padding: 25px;
}
.data-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.detail-data {
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
}
.detail-data p {
  margin: 0;
}
.detail-value {
  font-weight: 600;
}
.data-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.detail-data {
  display: flex;
  justify-content: space-between;
}
.detail-data p {
  margin: 0;
}
.detail-value {
  font-weight: 600;
}
.collapse {
  cursor: pointer;
  margin: 10px 0 10px 0;
}
.collapse .detail-label {
  font-weight: bolder;
}
.discount {
  color: #b80f0a;
}
.total {
  color: #162ca2;
}

.log-item {
  display: flex;
  gap: 10px;
}
.log-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}
.log-data p {
  margin: 0;
  text-align: left;
}
.connector {
  width: 4px;
  height: calc(100% - 14px);
  z-index: 1;
  background-color: #f1f6ff;
  left: -29px;
  top: 29px;
  position: absolute;
}
</style>
